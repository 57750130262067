import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { fetchCustomTextsLocal, saveContentLang } from "../../shared/queries";
import { /* Local, */ LocalDescription, LocalDescriptionData } from "../../types";
import Button from "../Button";
import Checkbox from "../Checkbox";
import CircleSpinner from "../CircleSpinner";
import FormSectionTitle from "../FormSectionTitle";

type ApartmentContentProps = {
  data: any;
  customText: string;
  extraSubtitle?: string;
};

const ApartmentContentCustomText = ({
  data,
  customText,
  extraSubtitle = "",
}: ApartmentContentProps) => {
  const { t } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState<
    "it" | "en" | "de" | "es" | "fr" | "pt" | "ru" | "zh"
  >("it");
  const [translationChange, setTranslationChange] =
    useState<LocalDescription | null>(null);

  const [currentTranslation, setCurrentTranslation] =
    useState<LocalDescriptionData | null>(null);

  const {
    data: customText1,
    isLoading,
    refetch,
  } = useQuery<any>([customText], () =>
    fetchCustomTextsLocal(data?.local_key, customText, data.user_key)
  );

  const languageSelector = (
    lang: "it" | "en" | "de" | "es" | "fr" | "pt" | "ru" | "zh"
  ) => {
    return (
      <div className={"border-1 rounded-2xl"}>
        <button
          className={`${
            currentLanguage === lang
              ? "text-cyan-600 bg-cyan-100"
              : "text-gray-600"
          } rounded-2xl transition font-medium px-3 py-2 focus:outline-none flex items-center
          `}
          onClick={() => {
            setCurrentLanguage(lang);
          }}
        >
          {t(`language.${lang}`)}
        </button>
      </div>
    );
  };

  const mutation = useMutation(
    (change: LocalDescriptionData) =>
      saveContentLang({
        content_key: customText,
        local_key: data.local_key ?? "",
        translation: change,
        user_key: data.user_key ?? "",
      }),
    {
      onSuccess: () => {
        setTimeout(() => {
          refetch();
        }, 500)
      },
      onError: (_error: Error) => {
        // toast.error(t(error.message) || t("general.requestError"));
      },
    }
  );

  function handleSave() {
    if (translationChange) {
      const keys = Object.keys(translationChange) as Array<
        keyof LocalDescription
      >;
      keys.forEach(async (key) => {
        await mutation.mutate(translationChange[key]);
      });
    }
  }

  useEffect(() => {
    if (customText1 && customText1.results) {
      if (!customText1.results[currentLanguage]) {
        setTranslationChange({
          ...customText1.results,
          [currentLanguage]: {
            lang: currentLanguage,
            text: "",
            DEL: true,
            video: null,
            pdf: null,
          },
        });
      } else {
        setTranslationChange(customText1.results);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customText1]);

  useEffect(() => {
    if (translationChange && translationChange[currentLanguage]) {
      setCurrentTranslation(translationChange[currentLanguage]);
    } else {
      setCurrentTranslation({
        lang: currentLanguage,
        text: "",
        DEL: true,
        video: null,
        pdf: null,
      });
    }
  }, [translationChange, currentLanguage]);
  return (
    <div>
      <FormSectionTitle
        borderless
        title={t(`apartmentContent.${customText}`)}
        subtitle={
          t(`apartmentContent.${customText}description`) + " " + extraSubtitle
        }
        dense
      />

      <div className={"flex -mx-1 mb-4"}>
        {languageSelector("it")}
        {languageSelector("en")}
        {languageSelector("es")}
        {languageSelector("fr")}
        {languageSelector("de")}
        {languageSelector("pt")}
        {languageSelector("ru")}
        {languageSelector("zh")}
      </div>

      {isLoading && (
        <div className={"flex items-center"}>
          <CircleSpinner color={"primary"}></CircleSpinner>

          <div className={"ml-2 text-gray-600"}>
            {t("apartmentContent.loadingContent")}
          </div>
        </div>
      )}

      {customText1 && customText1.results && currentTranslation && (
        <>
          <Checkbox
            className={"mb-2"}
            onChange={() => {
              setCurrentTranslation({
                ...currentTranslation,
                DEL: !currentTranslation.DEL,
                text: "",
              });
              setTranslationChange((prev: any) => {
                return {
                  ...prev,
                  [currentLanguage]: {
                    ...currentTranslation,
                    DEL: !currentTranslation.DEL,
                    text: "",
                  },
                };
              });
            }}
            disabled={mutation.isLoading}
            loading={mutation.isLoading}
            checked={!currentTranslation.DEL}
            label={t("createCustomEmail.languageActivated")}
          />
          <div
            style={{
              opacity: currentTranslation.DEL ? 0.3 : 1,
            }}
            className={"bg-white shadow-md mb-4 rounded-2xl"}
          >
            <Editor
              apiKey={"af1s9ngxeroefg0odkelxcvqpjzqffflygu2cw2tqk9jexpo"}
              value={currentTranslation?.text}
              init={{
                promotion: false,
                height: 350,
                plugins: [
                  "advlist",
                  "autolink",
                  "link",
                  "image",
                  "lists",
                  "charmap",
                  "preview",
                  "anchor",
                  "pagebreak",
                  "searchreplace",
                  "wordcount",
                  "visualblocks",
                  "visualchars",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "emoticons",
                  "help",
                ],
                toolbar:
                  "undo redo | styles | bold italic backcolor | alignleft aligncenter alignright alignjustify | " +
                  "bullist numlist outdent indent | link | " +
                  "removeformat | help",
                menubar: "file edit view insert format tools table help",
              }}
              children={<></>}
              onEditorChange={(value) => {
                if (
                  currentTranslation &&
                  currentTranslation &&
                  translationChange
                ) {
                  setCurrentTranslation({
                    ...currentTranslation,
                    text: value,
                  });
                  setTranslationChange({
                    ...translationChange,
                    [currentLanguage]: { ...currentTranslation, text: value },
                  });
                }
              }}
            />
          </div>

          <Button
            className={"mb-6"}
            label={t("general.save")}
            loading={mutation.isLoading}
            disabled={mutation.isLoading}
            onClick={() => handleSave()}
          />
        </>
      )}
    </div>
  );
};

export default ApartmentContentCustomText;
