import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FormSectionTitle from "../FormSectionTitle";
import Button from "../Button";
import { HexColorPicker } from "react-colorful";
import FormField from "../FormField";
import TextInput from "../TextInput";
import SelectInput from "../SelectInput";
import { BaseSelectItem } from "../../types";
import { useMutation, useQuery } from "react-query";
import {
  deleteLocalLogo,
  fetchAllModules,
  fetchLocalLogo,
  fetchLogoLocals,
  uploadGalleryV3,
  uploadLocalLogo,
} from "../../shared/queries";
import AsyncImage from "../AsyncImage";
import { toast } from "react-toastify";
import { moduleId } from "../../shared/utils/config";
import Modal from "../Modal";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import PageWrapper from "../PageWrapper";
import ModalActions from "../ModalAction";
import { useHistory } from "react-router-dom";
import Icon from "../Icon";
import ReactTooltip from "react-tooltip";

type Props = {
  data: any;
  isBkEngine?: boolean;
};

const ApartmentLogo = (props: Props) => {
  const { t } = useTranslation();
  const localKey = props.data.local_key;
  const localName = props.data.name;
  const isBkEngine = props?.isBkEngine;
  const history = useHistory();
  const [selectStart, setSelectStart] = useState<any>([
    {
      label: localName,
      value: localKey,
    },
  ]);
  const [currentIdx, setCurrentIdx] = useState<number>(0);
  const [logoDet, setLogoDet] = useState<{
    locals: string[];
    color: string | null;
    logo1: string | null;
    logo2: string | null;
    text?: string | null;
    textColor?: string | null;
    custom?: boolean;
  }>({
    locals: [localKey],
    color: "#1DB7E5",
    logo1: null,
    logo2: null,
    textColor: "#000000",
    text: null,
    custom: false,
  });
  const [logoModuleAct, setLogoModuleAct] = useState({
    visible: false,
    moduleName: "",
    disableButton: true,
  });
  const uploadInput = useRef<any>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: logoDetail, refetch: refetchLogoDetail } = useQuery(
    ["logoDetail"],
    () => fetchLocalLogo(localKey),
    {
      onSuccess: (data) => {
        if (data.results?.color) {
          setLogoDet({
            locals: [localKey],
            logo1: data.results.logo1_url,
            logo2: data.results.logo2_url,
            color: data.results?.color as string,
            textColor: "#000000",
            text: "",
          });
        } else {
          setLogoDet({
            locals: [localKey],
            color: "#1DB7E5",
            logo1: null,
            logo2: null,
            textColor: "#000000",
            text: null,
            custom: false,
          });
        }
      },
    }
  );
  const { data: logoLocals } = useQuery(["logoLocals"], () =>
    fetchLogoLocals()
  );

  const uploadMutation = useMutation(
    (body: {
      local_key: string;
      file?: string;
      element: string;
      idx: any;
      color?: string;
      text?: string;
    }) => uploadGalleryV3(body),
    {
      onSuccess: (data) => {
        toast.success(t("general.fileUploaded"));
        if (currentIdx === 1) {
          setLogoDet((prev) => {
            return {
              ...prev,
              logo1: data.results.public_url,
            };
          });
        }
        if (currentIdx === 2) {
          setLogoDet((prev) => {
            return {
              ...prev,
              logo2: data.results.public_url,
            };
          });
        }
      },
      onError: (error: Error) => {
        toast.error(t(error?.message ? error.message : "general.requestError"));
      },
    }
  );
  const saveLogoGuest = useMutation(
    (body: { locals: any[]; color: string; logo1: any; logo2: any }) =>
      uploadLocalLogo(body),
    {
      onSuccess: () => {
        toast.success(t("general.fileUploaded"));
      },
      onError: (error: Error) => {
        toast.error(t(error?.message ? error.message : "general.requestError"));
      },
    }
  );
  const deleteLogoGuest = useMutation(
    (body: { local_key: string }) => deleteLocalLogo(body),
    {
      onSuccess: () => {
        toast.success(t("general.requestCompleted"));
        refetchLogoDetail();
      },
      onError: (error: Error) => {
        toast.error(t(error?.message ? error.message : "general.requestError"));
      },
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataModules } = useQuery<any>([localKey, logoLocals], () => {
    if (localKey) {
      return fetchAllModules(localKey).then((data: any) => {
        const moduleFinded = data?.modules?.find(
          (module: any) => module?.id === moduleId.logo
        );
        if (moduleFinded) {
          if (moduleFinded?.active) {
            setLogoModuleAct({
              visible: false,
              moduleName: "",
              disableButton: false,
            });
          } else {
            setLogoModuleAct({
              visible: true,
              moduleName: t(`module.id${moduleFinded.id}`).toUpperCase(),
              disableButton: true,
            });
          }
        } else {
          setLogoModuleAct({
            visible: false,
            moduleName: "",
            disableButton: false,
          });
        }
        return data;
      });
    }
  });

  const handleFile = (event: any) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const reader = new FileReader();
      reader.addEventListener("loadend", () => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const maxSize = 640;
          let width = image.width;
          let height = image.height;
          if (width > image.width) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }
          canvas.height = height;
          canvas.width = width;
          const context = canvas.getContext("2d");
          if (context) {
            context.drawImage(image, 0, 0, width, height);
            const data = canvas.toDataURL();
            if (currentIdx === 1) {
              uploadMutation.mutate({
                local_key: localKey,
                file: data,
                element: "APT-LOGO",
                idx: 1,
              });
            }
            if (currentIdx === 2) {
              uploadMutation.mutate({
                local_key: localKey,
                file: data,
                element: "APT-LOGO",
                idx: 2,
              });
            }
          }
        };
        if (reader.result) {
          image.src = reader.result.toString();
        }
        return image.onload;
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (uploadMutation.isSuccess && logoDet.custom) {
      setLogoDet((prev) => {
        return {
          ...prev,
          text: null,
          textColor: "#000000",
          custom: false,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadMutation.isSuccess]);
  return (
    <>
      {isBkEngine && (
        <FormSectionTitle
          title=""
          subtitle={t("module.id22Info")}
        ></FormSectionTitle>
      )}
      <FormField
        className={"px-2 flex-1 mt-5"}
        label={t("apartmentLogo.selectColorTheme")}
      >
        <div className="md:flex gap-5">
          <HexColorPicker
            color={logoDet.color as string}
            onChange={(val) => {
              if (!logoModuleAct.disableButton) {
                setLogoDet((prev) => {
                  return {
                    ...prev,
                    color: val,
                  };
                });
              }
            }}
            style={{ height: 128 }}
          />
          <FormField label={t("apartmentLogo.color")} className="mt-5">
            <TextInput
              disabled={logoModuleAct.disableButton}
              value={logoDet.color as string}
              onChange={(val) =>
                setLogoDet((prev) => {
                  return {
                    ...prev,
                    color: val,
                  };
                })
              }
            ></TextInput>
          </FormField>
          <div className="md:flex gap-5">
            <div className=" md:mt-0 mt-5">
              <img
                className={"h-96"}
                alt="##Apartment-logo-1##"
                src={"/guest.vikey.it.png"}
                style={{ backgroundColor: logoDet.color as string }}
              />
            </div>
            <div className=" md:mt-0 mt-5">
              <img
                className={"h-96"}
                alt="##Apartment-logo-2##"
                src={"/guest.vikey.it2.png"}
                style={{ backgroundColor: logoDet.color as string }}
              />
            </div>
          </div>
        </div>
      </FormField>

      <FormField
        className={"px-2 flex-1 mt-5"}
        label={t("apartmentLogo.selectImage")}
      >
        <div className="lg:flex gap-3 w-full">
          <div className={"border flex flex-col rounded-2xl"}>
            <input
              type="file"
              hidden
              onChange={handleFile}
              ref={uploadInput}
              disabled={logoModuleAct.disableButton}
            />
            {logoDet.logo1 ? (
              <AsyncImage
                isDirect
                className={"w-full h-full"}
                style={{ backgroundColor: logoDet.color as string }}
                width={"100%"}
                height={"150px"}
                zoomable
                isLogo
                url={logoDet.logo1}
                logoBg={logoDet.color}
              />
            ) : (
              <img
                className={"w-full "}
                src={"/images/emptyimage.jpg"}
                alt="empty"
                style={{ height: "180px" }}
              />
            )}
            <div
              className={
                "px-4 flex items-center justify-between py-2 text-xs border-t border-gray-300"
              }
            >
              <div className="flex">
                <div>##Apartment-logo-1##</div>
                <div data-tip={t("apartmentLogo.logo1info")}>
                  <Icon name="information-circle" size="16px" />
                </div>
              </div>

              <div>
                <Button
                  flat
                  disabled={logoModuleAct.disableButton}
                  padding={"xs"}
                  size={"small"}
                  icon={"document-add"}
                  onClick={() => {
                    setCurrentIdx(1);
                    if (uploadInput && uploadInput.current)
                      uploadInput.current.click();
                  }}
                />
              </div>
            </div>
            <div className="text-center">
              <Button
                icon="plus-circle"
                size="small"
                color={"info"}
                label={t("apartmentLogo.custom")}
                loading={saveLogoGuest.isLoading}
                disabled={
                  logoModuleAct.disableButton || saveLogoGuest.isLoading
                }
                onClick={() => {
                  setCurrentIdx(1);
                  setLogoDet((prev) => {
                    return {
                      ...prev,
                      custom: true,
                    };
                  });
                }}
              ></Button>
            </div>
          </div>
          <div
            className={"bg-white border flex flex-col rounded-2xl lg:mt-0 mt-5"}
          >
            {logoDet.logo2 ? (
              <AsyncImage
                isDirect
                className={"w-full  h-full"}
                style={{ backgroundColor: "#fff" }}
                width={"100%"}
                height={"150px"}
                zoomable
                isLogo
                url={logoDet.logo2}
              />
            ) : (
              <img
                className={"w-full"}
                src={"/images/emptyimage.jpg"}
                alt="empty"
                style={{ height: "180px" }}
              />
            )}
            <div
              className={
                "px-4 flex items-center justify-between py-2 text-xs border-t border-gray-300"
              }
            >
              <div className="flex">
                <div>##Apartment-logo-2##</div>
                <div data-tip={t("apartmentLogo.logo2info")}>
                  <Icon name="information-circle" size="16px" />
                </div>
              </div>
              <ReactTooltip></ReactTooltip>

              <div>
                <Button
                  flat
                  disabled={logoModuleAct.disableButton}
                  padding={"xs"}
                  size={"small"}
                  icon={"document-add"}
                  onClick={() => {
                    setCurrentIdx(2);
                    if (uploadInput && uploadInput.current)
                      uploadInput.current.click();
                  }}
                />
              </div>
            </div>
            <div className="text-center">
              <Button
                icon="plus-circle"
                size="small"
                color={"info"}
                label={t("apartmentLogo.custom")}
                loading={saveLogoGuest.isLoading}
                disabled={
                  logoModuleAct.disableButton || saveLogoGuest.isLoading
                }
                onClick={() => {
                  setCurrentIdx(2);
                  setLogoDet((prev) => {
                    return {
                      ...prev,
                      custom: true,
                    };
                  });
                }}
              ></Button>
            </div>
          </div>
        </div>
      </FormField>
      <div className={`mt-5 flex justify-between ${isBkEngine && "pb-56"}`}>
        <Button
          color={"primary"}
          label={t("general.save")}
          loading={saveLogoGuest.isLoading}
          className="md:mt-0 mt-5"
          disabled={logoModuleAct.disableButton || saveLogoGuest.isLoading}
          onClick={() => {
            saveLogoGuest.mutate({
              locals: logoDet.locals,
              color: logoDet.color as string,
              logo1: logoDet.logo1,
              logo2: logoDet.logo2,
            });
          }}
        ></Button>
        <div className="flex gap-3">
          <Button
            color={"primary"}
            label={t("general.reset")}
            icon="refresh"
            loading={saveLogoGuest.isLoading}
            className="md:mt-0 mt-5"
            disabled={logoModuleAct.disableButton || saveLogoGuest.isLoading}
            onClick={() => {
              refetchLogoDetail();
            }}
          ></Button>
          <Button
            color={"negative"}
            label={t("general.delete")}
            icon="trash"
            loading={deleteLogoGuest.isLoading}
            className="md:mt-0 mt-5"
            disabled={logoModuleAct.disableButton || deleteLogoGuest.isLoading}
            onClick={() => {
              deleteLogoGuest.mutate({
                local_key: localKey,
              });
            }}
          ></Button>
        </div>
      </div>

      {!isBkEngine && (
        <>
          <FormSectionTitle
            title={t("apartmentLogo.applyToOtherLocals")}
            onEdit={() => {}}
          ></FormSectionTitle>
          <FormField
            className={"px-2 flex-1"}
            label={t("apartmentLogo.selectedLocals")}
          >
            <div className="md:flex gap-5 mb-96">
              <div className="w-full">
                <SelectInput<BaseSelectItem, true>
                  isMulti
                  value={selectStart}
                  onChange={(item) => {
                    if (!logoModuleAct.disableButton) {
                      setSelectStart(item);
                      const arrayTemp: any = [];
                      item.map((it) => arrayTemp.push(it.value));
                      setLogoDet((prev) => {
                        return {
                          ...prev,
                          locals: arrayTemp,
                        };
                      });
                    }
                  }}
                  options={logoLocals?.results?.map((local) => {
                    return {
                      label: local.name,
                      value: local.local_key,
                    };
                  })}
                />
              </div>

              <Button
                color={"primary"}
                label={t("general.save")}
                loading={saveLogoGuest.isLoading}
                className="md:mt-0 mt-5"
                disabled={
                  logoModuleAct.disableButton || saveLogoGuest.isLoading
                }
                onClick={() => {
                  saveLogoGuest.mutate({
                    locals: logoDet.locals,
                    color: logoDet.color as string,
                    logo1: logoDet.logo1,
                    logo2: logoDet.logo2,
                  });
                }}
              ></Button>
            </div>
          </FormField>
        </>
      )}
      <Modal visible={logoModuleAct.visible} className={"w-full"}>
        <ModalCard
          title={`${logoModuleAct.moduleName.toUpperCase()} (${t(
            "module.statusInactive"
          )})`}
          className={"w-auto"}
        >
          <ModalSection>
            <PageWrapper>
              <span className="text-gray-600 font-medium">
                {t("module.buyModuleFunctionality", {
                  name: logoModuleAct.moduleName.toUpperCase(),
                })}
              </span>
            </PageWrapper>
            <ModalActions
              onClose={() =>
                setLogoModuleAct((prev) => {
                  return {
                    visible: false,
                    moduleName: prev.moduleName,
                    disableButton: prev.disableButton,
                  };
                })
              }
              onSave={() => history.push(`/modules/module#${moduleId.logo}`)}
              saveLabel={t("module.purchase")}
            />
          </ModalSection>
        </ModalCard>
      </Modal>

      <Modal visible={logoDet.custom as boolean} className={"w-full"}>
        <ModalCard
          title={`${t(
            "apartmentLogo.generateLogo"
          )} ##Apartment-logo-${currentIdx}##`}
          className={"w-auto"}
        >
          <ModalSection>
            <PageWrapper>
              <FormField label="" className="my-5">
                <div className="flex gap-5 items-center">
                  <HexColorPicker
                    color={logoDet.textColor as string}
                    onChange={(val) => {
                      if (!logoModuleAct.disableButton) {
                        setLogoDet((prev) => {
                          return {
                            ...prev,
                            textColor: val,
                          };
                        });
                      }
                    }}
                    style={{ height: 128 }}
                  />
                  <FormField label={t("apartmentLogo.color")}>
                    <TextInput
                      disabled={logoModuleAct.disableButton}
                      value={logoDet.textColor as string}
                      onChange={(val) =>
                        setLogoDet((prev) => {
                          return {
                            ...prev,
                            textColor: val,
                          };
                        })
                      }
                    ></TextInput>
                  </FormField>
                </div>
                <div
                  className="w-auto h-20 flex items-center justify-center rounded-full mt-5"
                  style={{
                    background:
                      currentIdx === 1 ? (logoDet.color as string) : "#fff",
                  }}
                >
                  <p
                    className="text-xl text-center w-full truncate font-Comfortaa font-bold"
                    style={{ color: logoDet.textColor as string }}
                  >
                    {logoDet.text}
                  </p>
                </div>
                <div className="md:flex gap-5 mt-5">
                  <TextInput
                    disabled={logoModuleAct.disableButton}
                    placeholder={`##Apartment-logo-${currentIdx}##`}
                    value={logoDet.text as string}
                    onChange={(val) =>
                      setLogoDet((prev) => {
                        return {
                          ...prev,
                          text: val,
                        };
                      })
                    }
                  ></TextInput>
                </div>
              </FormField>
            </PageWrapper>
            <ModalActions
              isLoading={uploadMutation.isLoading}
              closeLabel={t("general.close")}
              onClose={() =>
                setLogoDet((prev) => {
                  return {
                    ...prev,
                    text: null,
                    custom: false,
                    textColor: "#000000",
                  };
                })
              }
              onSave={() => {
                uploadMutation.mutate({
                  local_key: localKey,
                  element: "APT-LOGO",
                  idx: 1,
                  text: logoDet.text as string,
                  color: logoDet.textColor as string,
                });
              }}
              saveLabel={t("apartmentLogo.generateLogo")}
            />
          </ModalSection>
        </ModalCard>
      </Modal>
    </>
  );
};

export default ApartmentLogo;
