import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FormSectionTitle from "../components/FormSectionTitle";
import Checkbox from "../components/Checkbox";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { fetchV3AllLocals, updateApartmentServices, updateLocalKeysCustoms } from "../shared/queries";
import ReservationServiceSkeleton from "../components/reservation/ReservationServiceSkeleton";
import Pagination from "../components/Pagination";
import Icon from "../components/Icon";
import { toast } from "react-toastify";
import Modal from "../components/Modal";
import ModalCard from "../components/ModalCard";
import ModalSection from "../components/ModalSection";
import TextInput from "../components/TextInput";
import ModalActions from "../components/ModalAction";

const Experiences = () => {
  const { t } = useTranslation();
  const confirmationKey = t("general.iConfirm");
  const limitPage = 10;
  const queryClient = useQueryClient();
  const [searchValue, setSearchValue] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterPage, setFilterPage] = useState({
    initial: 0,
    final: limitPage,
  });
  const [localKeys, setLocalKeys] = useState<string[]>([]);
  const [confirmValue, setConfirmValue] = useState<string>("");
  const [modalVisible, setModalVisible] = useState<{
    display: boolean;
    id: string[];
  }>({
    display: false,
    id: [],
  });

  const { data: locals, isFetching: isFetchingLocals } = useQuery(
    "localsv3Exp",
    fetchV3AllLocals,
    {
      onSuccess: (data) => {
        const localKeys = data?.results?.map((local) => local.local_key);
        setLocalKeys(localKeys ?? []);
      },
    }
  );

  const mutation = useMutation(
    (body: {
      service: { enable_services: boolean; external_services_notif: boolean };
      local_key: string;
    }) => updateApartmentServices(body.local_key, body.service),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["localsv3Exp"]);
        toast.success(t("general.updated"));
      },
    }
  );
  const mutationMassive = useMutation(
    (body: {
      service: { enable_services: boolean; external_services_notif: boolean };
      local_keys: string[];
    }) => updateLocalKeysCustoms(body.local_keys, body.service),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["localsv3Exp"]);
        toast.success(t("general.updated"));
      },
      onError: () => {
        toast.error(t('general.requestError'));
      }
    }
  );

  return (
    <>
      <FormSectionTitle
        borderless
        title={t("apartmentService.sellWithVikey")}
        edit
        editFull
        editLabel={t("module.enableServicesApartments")}
        onEdit={() => {
          setModalVisible({
            display: true,
            id: localKeys,
          });
        }}
      ></FormSectionTitle>
      <div className="flex gap-5 justify-center flex-wrap">
        <img className="w-30" alt="tiqets" src="/images/svg/tiqets.svg" />
        <img className="w-20" alt="gyg" src="/images/svg/gyg.svg" />
        <img
          className="w-40"
          alt="radical"
          src="/images/svg/radicalstorage.svg"
        />
      </div>
      <div className="relative bg-white rounded-xl border-gray-300 border h-8 md:w-64 mb-5">
        <input
          style={{
            height: "100%",
            width: "100%",
          }}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            setCurrentPage(1);
            setFilterPage({
              initial: 0,
              final: limitPage,
            });
          }}
          placeholder={t("general.searchForApartment")}
          className={
            "relative flex items-center align-middle bg-white focus:outline-none bg-white-600 px-3 text-sm font-medium border-gray-300 rounded-2xl truncate"
          }
        />
        <Icon
          name={"home"}
          size={"18px"}
          className={"absolute top-1 right-1"}
        />
      </div>
      {!isFetchingLocals &&
      locals &&
      locals?.results &&
      locals?.results.length > 1 &&
      locals?.tot_locals ? (
        <div className="mt-5">
          <Pagination
            page={currentPage}
            pageSize={limitPage}
            totalItems={
              locals?.results?.filter((local: any) =>
                local.name?.toLowerCase().includes(searchValue)
              ).length ?? 0
            }
            onPageUpdate={(value) => {
              if (value === "+") {
                setCurrentPage((prev) => prev + 1);
                setFilterPage((prev) => ({
                  initial: prev.initial + limitPage,
                  final: prev.final + limitPage,
                }));
                return;
              }
              if (value === "-") {
                setCurrentPage((prev) => (prev > 1 ? prev - 1 : 1));
                setFilterPage((prev) => ({
                  initial: prev.initial - limitPage,
                  final: prev.final - limitPage,
                }));
                return;
              }
              setCurrentPage(+value);
              setFilterPage({
                initial: limitPage * (value as number) - limitPage,
                final: limitPage * (value as number),
              });
            }}
          />
        </div>
      ) : null}
      {isFetchingLocals ? (
        <ReservationServiceSkeleton />
      ) : (
        locals?.results
          ?.filter((local: any) =>
            local.name?.toLowerCase().includes(searchValue)
          )
          ?.filter(
            (_local: any, idx: number) =>
              idx >= filterPage.initial && idx < filterPage.final
          )
          ?.map((local) => (
            <>
              <FormSectionTitle title={local.name}></FormSectionTitle>
              <div>
                <Checkbox
                  checked={local.enable_services}
                  label={t("apartmentService.activateServices")}
                  onChange={() => {
                    mutation.mutate({
                      local_key: local.local_key,
                      service: {
                        enable_services: !local.enable_services,
                        external_services_notif: local.external_services_notif,
                      },
                    });
                  }}
                ></Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={local.external_services_notif}
                  label={t("apartmentService.activateExternalServices")}
                  onChange={() => {
                    mutation.mutate({
                      local_key: local.local_key,
                      service: {
                        enable_services: local.enable_services,
                        external_services_notif: !local.external_services_notif,
                      },
                    });
                  }}
                ></Checkbox>
              </div>
            </>
          ))
      )}
      {!isFetchingLocals &&
      locals &&
      locals?.results &&
      locals?.results.length > 1 &&
      locals?.tot_locals ? (
        <div className="mt-5">
          <Pagination
            page={currentPage}
            pageSize={limitPage}
            totalItems={
              locals?.results?.filter((local: any) =>
                local.name?.toLowerCase().includes(searchValue)
              ).length ?? 0
            }
            onPageUpdate={(value) => {
              if (value === "+") {
                setCurrentPage((prev) => prev + 1);
                setFilterPage((prev) => ({
                  initial: prev.initial + limitPage,
                  final: prev.final + limitPage,
                }));
                return;
              }
              if (value === "-") {
                setCurrentPage((prev) => (prev > 1 ? prev - 1 : 1));
                setFilterPage((prev) => ({
                  initial: prev.initial - limitPage,
                  final: prev.final - limitPage,
                }));
                return;
              }
              setCurrentPage(+value);
              setFilterPage({
                initial: limitPage * (value as number) - limitPage,
                final: limitPage * (value as number),
              });
            }}
          />
        </div>
      ) : null}
      {/* MODALE CONFERMA*/}
      <Modal visible={modalVisible.display}>
        <ModalCard title={t("module.enableServicesApartments")}>
          <ModalSection>
            <div className="text-gray-700 p-4 pt-0 border-gray-300">
              {t("general.write1")}
              <span className={"font-bold"}>{confirmationKey}</span>{" "}
              {t("general.write2")}
              <div className="mt-2">
                <TextInput
                  value={confirmValue}
                  onChange={(val) => setConfirmValue(val)}
                />
              </div>
            </div>
          </ModalSection>
          <ModalActions
            isLoading={isFetchingLocals}
            onSave={() => {
              mutationMassive.mutate({
                local_keys: localKeys,
                service: {
                  enable_services: true,
                  external_services_notif: true,
                },
              })
              setModalVisible({
                display: false,
                id: localKeys,
              });
              setConfirmValue("");
            }}
            saveLabel={t("general.enable")}
            saveDisabled={confirmationKey !== confirmValue || isFetchingLocals}
            onClose={() => {
              setModalVisible({
                display: false,
                id: localKeys,
              });
              setConfirmValue("");
            }}
          />
        </ModalCard>
      </Modal>
      {/* MODALE CONFERMA*/}
    </>
  );
};

export default Experiences;
