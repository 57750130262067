import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import FormSectionTitle from "../components/FormSectionTitle";
import AddIntegration from "../components/integration/AddIntegration";
import ApartmentConnectionItem from "../components/integration/ApartmentConnectionItem";
import Modal from "../components/Modal";
import ModalActions from "../components/ModalAction";
import ModalCard from "../components/ModalCard";
import ModalSection from "../components/ModalSection";
import PageTitle from "../components/PageTitle";
import PageWrapper from "../components/PageWrapper";
import PreviousPage from "../components/PreviousPage";
import {
  deleteConnection,
  fetchIntegrationLinking,
  fetchIntegrationRemote,
  createIntegration,
  importAllReservations,
} from "../shared/queries";
import { Integration, IntegrationApartment } from "../types";
import integrations from "../shared/integrations";
import LoadingIntegrationSkeleton from "../components/integration/LoadingIntegrationSkeleton";
import { useTranslation } from "react-i18next";
import Pagination from "../components/Pagination";
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import Icon from "../components/Icon";

type IntegrationPageParams = {
  type: string;
  id: string;
};

export default function IntegrationPage() {
  const history = useHistory();

  const { t } = useTranslation();

  const { type, id } = useParams<IntegrationPageParams>();

  const [currentIntegration, setCurrentIntegration] = useState<Integration>();
  const [addIntegration, setAddIntegration] = useState<string | null>(null);
  const [hotelIntegration, setHotelIntegration] =
    useState<IntegrationApartment>();
  const [modesIntegration, setModesIntegration] = useState<any>([]);
  const [currentTypology, setCurrentTypology] = useState<string>();
  const [disabledImportRes, setDisabledImportRes] = useState<boolean>(false);
  const [disabledUpdateApartment, setDisabledUpdateApartment] =
    useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [importBookingId, setImportBookingId] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [filterAptAss, setFilterAptAss] = useState<string>("false");
  const topRef: any = useRef();

  const [askDeleteIntegration, setAskDeleteIntegration] =
    useState<boolean>(false);

  const { mutate: importResMut } = useMutation(
    (data: string) =>
      importAllReservations({
        action: data,
        user_key: currentIntegration?.user_key,
        integr_user_id: currentIntegration?.id,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.operationCompleted"));
      },
      onError: () => {
        toast.error(t("general.requestError"));
      },
    }
  );

  const importResBooking = useMutation(
    (data: any) => importAllReservations(data),
    {
      onSuccess: (data: any) => {
        toast.success(t(data.msg ? data.msg : "general.requestCompleted"));
      },
      onError: (error: Error) => {
        toast.error(t("general.requestError"));
      },
    }
  );

  const handleImportRes = (data: string) => {
    switch (data) {
      case "import_all":
        setDisabledImportRes(true);
        importResMut("import_all");
        setTimeout(() => {
          setDisabledImportRes(false);
        }, 60000);
        break;
      case "update_apartments":
        setDisabledUpdateApartment(true);
        importResMut("update_apartments");
        setTimeout(() => {
          setDisabledUpdateApartment(false);
        }, 60000);
        break;
      default:
        break;
    }
  };

  const {
    data: remoteIntegrations,
    isLoading: isLoadingRemote,
    refetch: refetchRemote,
  } = useQuery<any, Error>(
    ["remoteListing", id, page, currentTypology, searchValue, filterAptAss],
    () =>
      fetchIntegrationRemote(
        id,
        page,
        currentTypology,
        searchValue,
        filterAptAss
      ).then((datas: any) => {
        setCurrentIntegration(datas?.results?.data[0]);
        return datas;
      })
  );

  const { refetch: refetchLinking } = useQuery<any, Error>(
    ["linkings", id, page, currentTypology, searchValue, filterAptAss],
    () =>
      fetchIntegrationLinking(
        id,
        page,
        currentTypology,
        searchValue,
        filterAptAss
      ).then((datas: any) => {
        const dataTemp = datas?.results?.data[0]?.extapts?.filter(
          (all: any) => all.typology === "HOTEL"
        );
        const arrayLinking: any = [];
        dataTemp.map((link: any) => {
          if (link?.links?.length > 0) arrayLinking.push(link?.links[0]);
          return link;
        });
        return arrayLinking;
      })
  );

  useEffect(() => {
    if (currentIntegration) {
      var i = integrations.find(
        (inte) => inte.key === currentIntegration?.integr_type
      );
      if (i) {
        const modesTemp = i.modes
        setModesIntegration(modesTemp);
        if(modesTemp.length === 1){
          setCurrentTypology(modesTemp[0].k)
        }
      } else {
        setModesIntegration([{ k: currentIntegration.typology }]);
      }
      setHotelIntegration(
        currentIntegration?.extapts.find((c) => c.typology === "HOTEL")
      );
    }
  }, [currentIntegration]);

  useEffect(() => {
    if (currentTypology && currentTypology !== null) {
      changeTipologyMutation.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTypology]);

  const changeTipologyMutation = useMutation(
    () =>
      createIntegration({
        action: "change_typology",
        integr_user_id: currentIntegration?.id,
        typology: currentTypology,
      }),
    {
      onSuccess: () => {
        if (currentIntegration && currentTypology && currentTypology !== null) {
          setCurrentIntegration({
            ...currentIntegration,
            typology: currentTypology,
          });
        }
      },
      onError: (error: Error) => {
        toast.error(t(error.message) || t("general.requestError"));
      },
    }
  );

  const deleteMutation = useMutation(
    () =>
      deleteConnection({
        id: currentIntegration?.id,
        integr_token: currentIntegration?.token,
        integr_secret: currentIntegration?.secret,
        integr_type: currentIntegration?.integr_type,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.operationCompleted"));
        history.push("/configuration/integrations");
      },
      onError: (error: Error) => {
        toast.error(t(error.message) || t("general.requestError"));
      },
    }
  );

  useEffect(() => {
    if (modesIntegration && !currentTypology) {
      setCurrentTypology(currentIntegration?.typology);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modesIntegration, currentTypology]);

  return (
    <PageWrapper>
      <PreviousPage
        className={"mb-4"}
        label={t("general.goBack")}
      ></PreviousPage>

      <Modal visible={askDeleteIntegration}>
        <ModalCard title={"Elimina integrazione"}>
          <ModalSection>
            <div className="p-4">
              {t("integration.deleteConfirm")}{" "}
              {currentIntegration ? currentIntegration.integr_type : ""}?<br />
            </div>
            <ModalActions
              onClose={() => setAskDeleteIntegration(false)}
              onSave={() => {
                deleteMutation.mutate();
              }}
              saveLabel={"Elimina"}
            ></ModalActions>
          </ModalSection>
        </ModalCard>
      </Modal>

      <div className="flex justify-between" ref={topRef}>
        <div>
          <PageTitle title={type} />
        </div>

        <div>
          {currentIntegration && (
            <div className="flex flex-col lg:flex-row gap-2 mb-3 items-center -mx-2">
              <button
                disabled={disabledUpdateApartment}
                onClick={() => handleImportRes("update_apartments")}
                className={`py-1 flex items-center focus:outline-none shadow border px-3 text-sm font-medium ${
                  disabledUpdateApartment
                    ? "text-cyan-50 bg-gray-300 cursor-not-allowed"
                    : "text-white bg-cyan-300 cursor-pointer"
                } rounded-2xl`}
              >
                {t("general.updateApartment")}
              </button>
              <button
                disabled={disabledImportRes}
                onClick={() => handleImportRes("import_all")}
                className={`py-1 cursor-pointer flex items-center focus:outline-none shadow border px-3 text-sm font-medium ${
                  disabledImportRes
                    ? "text-cyan-50 bg-gray-300 cursor-not-allowed"
                    : "text-green-800 bg-green-200 cursor-pointer"
                } rounded-2xl`}
              >
                {t("integrationPage.importReservation")}
              </button>
              <button
                onClick={() => setAskDeleteIntegration(true)}
                className={
                  "py-1 cursor-pointer flex items-center focus:outline-none bg-red-600 shadow border px-3 text-sm text-white font-medium border-red-400 rounded-2xl"
                }
              >
                {t("general.delete")}
              </button>
            </div>
          )}
        </div>
      </div>
      {currentIntegration && type.toLowerCase() === "booking" && (
        <>
          <div className="md:text-right font-semibold md:self-end text-sm w-full">
            {t("integrationPage.importReservation")} ID Booking
          </div>
          <div className="md:grid w-full md:place-content-end md:self-end">
            <div>
              <TextInput
                value={importBookingId}
                className="w-96"
                onChange={(val) => {
                  setImportBookingId(val);
                }}
              ></TextInput>
              <Button
                disabled={importResBooking.isLoading}
                loading={importResBooking.isLoading}
                onClick={() => {
                  importResBooking.mutate({
                    action: "import_single",
                    ref_resv: importBookingId,
                    integr: currentIntegration?.id,
                  });
                }}
                className={
                  "cursor-pointer focus:outline-none bg-cyan-600 shadow border px-3 text-sm text-white font-medium border-cyan-600 rounded-2xl w-56 truncate mt-2"
                }
              >
                {t("integrationPage.importReservation")}
              </Button>
            </div>
          </div>
        </>
      )}

      {currentIntegration === undefined && (
        <LoadingIntegrationSkeleton></LoadingIntegrationSkeleton>
      )}

      {currentIntegration !== undefined && (
        <>
          <div className={"py-2 border-t mt-2 border-gray-300 mb-6"}>
            <div className={"flex flex-wrap py-1 -mx-4"}>
              <div
                className={
                  "px-4 mb-3 w-1/2 md:mb-0 md:w-auto md:border-r border-gray-200 text-gray-600 text-sm"
                }
              >
                <div className={"pb-3 md:pb-0 border-b md:border-b-0"}>
                  <div className={"mb-1 font-medium"}>Account</div>
                  <div>Vikey</div>
                </div>
              </div>

              <div
                className={
                  "px-4 mb-3 w-1/2 md:mb-0 md:w-auto md:border-r border-gray-200 text-gray-600 text-sm"
                }
              >
                <div className={"pb-3 md:pb-0 border-b md:border-b-0"}>
                  <div className={"mb-1 font-medium"}>Stato</div>
                  <div>
                    <span>{currentTypology}</span>
                  </div>
                </div>
              </div>
              {currentIntegration?.integr_type === "SUITE5" && (
                <>
                  <div
                    className={
                      "px-4 mb-3 w-1/2 md:mb-0 md:w-auto md:border-r border-gray-200 text-gray-600 text-sm"
                    }
                  >
                    <div className={"pb-3 md:pb-0 border-b md:border-b-0"}>
                      <div className={"mb-1 font-medium"}>Hotel ID</div>
                      <div>
                        <span>
                          {currentIntegration?.secret.split(" ")[2] ?? "---"}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      "px-4 mb-3 w-1/2 md:mb-0 md:w-auto md:border-r border-gray-200 text-gray-600 text-sm"
                    }
                  >
                    <div className={"pb-3 md:pb-0 border-b md:border-b-0"}>
                      <div className={"mb-1 font-medium"}>Username</div>
                      <div>
                        <span>
                          {currentIntegration?.secret.split(" ")[0] ?? "---"}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      "px-4 mb-3 w-1/2 md:mb-0 md:w-auto md:border-r border-gray-200 text-gray-600 text-sm"
                    }
                  >
                    <div className={"pb-3 md:pb-0 border-b md:border-b-0"}>
                      <div className={"mb-1 font-medium"}>Password</div>
                      <div>
                        <span>
                          {currentIntegration?.secret.split(" ")[1] ?? "---"}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      "px-4 mb-3 w-1/2 md:mb-0 md:w-auto md:border-r border-gray-200 text-gray-600 text-sm"
                    }
                  >
                    <div className={"pb-3 md:pb-0 border-b md:border-b-0"}>
                      <div className={"mb-1 font-medium"}>Workstation</div>
                      <div>
                        <span>
                          {currentIntegration?.secret.split(" ")[2] ?? "---"}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className={"flex items-center mb-8"}>
            <div>
              <div className={"font-medium mb-3"}>
                {t("integration.integMode")}
              </div>
              <div
                className={
                  "bg-white border border-gray-300 overflow-hidden shadow rounded-2xl flex w-fit"
                }
              >
                {modesIntegration.map((mode: any, index: number) => (
                  <button
                    key={index}
                    onClick={() => {
                      setCurrentTypology(mode.k);
                    }}
                    className={`${
                      currentTypology === mode.k
                        ? "bg-cyan-400 text-white"
                        : "hover:bg-gray-100"
                    } focus:outline-none font-medium py-2 text-sm cursor-pointer px-3`}
                  >
                    {mode.k}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </>
      )}

      {currentIntegration !== undefined && currentTypology !== "HOTEL" && (
        <>
          <FormSectionTitle
            borderless
            subtitle={t("integration.connectApartments")}
            title={
              t("integration.apartmentsOn") +
              " " +
              currentIntegration?.integr_type
            }
          />

          <div className="md:flex justify-between align-middle items-center mb-3">
            <div className="relative bg-white rounded-xl border-gray-300 border h-8 md:w-64 mb-10">
              <input
                style={{
                  height: "100%",
                  width: "100%",
                }}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  setPage(1);
                }}
                placeholder={t("general.searchForApartment")}
                className={
                  "relative flex items-center align-middle bg-white focus:outline-none bg-white-600 px-3 text-sm font-medium border-gray-300 rounded-2xl truncate"
                }
              />
              <Icon
                name={"home"}
                size={"18px"}
                className={"absolute top-1 right-1"}
              />
            </div>
            <div className="flex-col space-y-1 border-2 rounded-lg text-xs">
              <div
                className={`${
                  filterAptAss === "false" && "bg-cyan-100 text-cyan-800"
                } focus:outline-none hover:bg-cyan-100 cursor-pointer px-3 py-1 text-sm font-semibold truncate w-full`}
                onClick={() => {
                  setFilterAptAss("false");
                  setPage(1);
                }}
              >
                {t("reservations.all")}
              </div>
              <div
                className={`${
                  filterAptAss === "associated" && "bg-cyan-100 text-cyan-800"
                } focus:outline-none hover:bg-cyan-100 cursor-pointer px-3 py-1 text-sm font-semibold truncate w-full`}
                onClick={() => {
                  setFilterAptAss("associated");
                  setPage(1);
                }}
              >
                {t("general.filterAptAssociated")}
              </div>
              <div
                className={`${
                  filterAptAss === "notassociated" &&
                  "bg-cyan-100 text-cyan-800"
                } focus:outline-none hover:bg-cyan-100 cursor-pointer px-3 py-1 text-sm font-semibold truncate w-full`}
                onClick={() => {
                  setFilterAptAss("notassociated");
                  setPage(1);
                }}
              >
                {t("general.filterAptNotAssociated")}
              </div>
            </div>
          </div>

          {currentTypology !== "HOTEL" &&
            remoteIntegrations &&
            remoteIntegrations.results &&
            remoteIntegrations.results.data[0] &&
            remoteIntegrations.results.data[0].extapts
              .filter(
                (r: IntegrationApartment) => r.typology === currentTypology
              )
              .map((remote: IntegrationApartment, index: number) => (
                <ApartmentConnectionItem
                  key={index}
                  integration={remote}
                  onAdd={() => setAddIntegration(remote.ref)}
                  onSave={() => {
                    // refetchActive();
                    refetchLinking();
                    refetchRemote();
                  }}
                  userKey={currentIntegration?.user_key}
                  remoteIntegartions={currentIntegration?.extapts}
                  onEdit={(integration) =>
                    history.push(
                      `/integrations/${type}/${id}/details/${integration}`
                    )
                  }
                />
              ))}

          {isLoadingRemote && (
            <LoadingIntegrationSkeleton></LoadingIntegrationSkeleton>
          )}

          {currentTypology !== "HOTEL" /* 
              linkingIntegrations && */ &&
            remoteIntegrations &&
            remoteIntegrations.results &&
            remoteIntegrations.results.data &&
            remoteIntegrations.results.data[0].extapts.length === 0 && (
              <div className={"flex flex-col items-center justify-center"}>
                <img className={"w-44"} src={"/search.svg"} alt="Search" />

                <div className="mt-4 text-center">
                  <div className="text-xl font-bold">
                    {t("integration.noApartments")}
                  </div>
                  <div className={"mt-2 text-gray-600"}>
                    {t("integration.tryingToFind")}{" "}
                    {currentIntegration?.integr_type},{" "}
                    {t("integration.timehour")}.<br />
                    {t("integration.ifPassed")}{" "}
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/support");
                      }}
                      className={"cursor-pointer text-cyan-600 font-medium"}
                      href="#support"
                    >
                      {t("general.contactUs")}
                    </a>
                    .
                  </div>
                </div>
              </div>
            )}
        </>
      )}
      {currentTypology === "HOTEL" && (
        <>
          <FormSectionTitle
            borderless
            subtitle={"Connetti l'intero hotel con un singolo appartamento"}
            title={"Intero Hotel"}
          />

          <div className="relative bg-white rounded-xl border-gray-300 border h-8 md:w-64 mb-10">
            <input
              style={{
                height: "100%",
                width: "100%",
              }}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                setPage(1);
              }}
              placeholder={t("general.searchForApartment")}
              className={
                "relative flex items-center align-middle bg-white focus:outline-none bg-white-600 px-3 text-sm font-medium border-gray-300 rounded-2xl truncate"
              }
            />
            <Icon
              name={"home"}
              size={"18px"}
              className={"absolute top-1 right-1"}
            />
          </div>

          {remoteIntegrations &&
            remoteIntegrations.results &&
            remoteIntegrations.results.data[0] &&
            remoteIntegrations.results.data[0].extapts
              .filter(
                (r: IntegrationApartment) => r.typology === currentTypology
              )
              .map((remote: IntegrationApartment, index: number) => (
                <ApartmentConnectionItem
                  key={index}
                  integration={remote}
                  onAdd={() => setAddIntegration(remote.ref)}
                  onSave={() => {
                    // refetchActive();
                    refetchLinking();
                    refetchRemote();
                  }}
                  userKey={currentIntegration?.user_key}
                  remoteIntegartions={currentIntegration?.extapts ?? []}
                  onEdit={(integration) =>
                    history.push(
                      `/integrations/${type}/${id}/details/${integration}`
                    )
                  }
                />
              ))}

          {currentTypology === "HOTEL" && !hotelIntegration && (
            <div className={"flex flex-col items-center justify-center"}>
              <img className={"w-44"} src={"/search.svg"} alt="Search" />

              <div className="mt-4 text-center">
                <div className="text-xl font-bold">
                  {t("integration.noApartments")}
                </div>
                <div className={"mt-2 text-gray-600"}>
                  {t("integration.tryingToFind")}{" "}
                  {currentIntegration?.integr_type}, {t("integration.timehour")}
                  .<br />
                  {t("integration.ifPassed")}{" "}
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/support");
                    }}
                    className={"cursor-pointer text-cyan-600 font-medium"}
                    href="#support"
                  >
                    {t("general.contactUs")}
                  </a>
                  .
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {/* PAGINATION */}
      {currentTypology !== "HOTEL" &&
        remoteIntegrations?.results?.data[0]?.extapts_tot > 50 && (
          <Pagination
            page={page}
            pageSize={remoteIntegrations?.results?.data[0]?.extapts_limit}
            totalItems={remoteIntegrations?.results?.data[0]?.extapts_tot}
            onPageUpdate={(value: any) => {
              if (value === "+") {
                setPage((prev) => prev + 1);
                return;
              }
              if (value === "-") {
                setPage((prev) => (prev > 1 ? prev - 1 : 1));
                return;
              }
              setPage(+value);
            }}
          />
        )}

      <AddIntegration
        integrationId={id}
        onSave={() => {
          setAddIntegration(null);
          // refetchActive();
          refetchLinking();
          refetchRemote();
        }}
        refId={addIntegration ? addIntegration : ""}
        visible={addIntegration !== null}
        onClose={() => setAddIntegration(null)}
      />
    </PageWrapper>
  );
}
