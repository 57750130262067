import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Login from "./components/Login";
import SecureLayout from "./layouts/SecureLayout";
import ApartmentView from "./pages/ApartmentView";
import Apartments from "./pages/Apartments";
import Dashboard from "./pages/Dashboard";
import Services from "./pages/Services";
import ServiceView from "./pages/ServiceView";
import Reservations from "./pages/Reservations";
import Reservation from "./pages/Reservation";
import IntegrationsPage from "./pages/Integrations";
import Calendar from "./pages/Calendar";
import CalendarNEW from "./pages/CalendarNEW";
import IntegrationPage from "./pages/Integration";
import Orders from "./pages/Orders";
import OrderPage from "./pages/Order";
import IntegrationDetail from "./components/integration/IntegrationDetail";
import MessageContextDetail from "./components/messaging/MessageContextDetail";

import Profile from "./pages/Profile";
import ConfigurationPage from "./pages/Configuration";
import ContractPage from "./pages/Contract";
import BureaucracySettings from "./pages/BureaucracySettings";
import IntegrationsCallbackOauthPage from "./pages/IntegrationsCallbackOauthPage";
import CustomEmail from "./pages/CustomEmail";
import Invoices from "./pages/Invoices";
import AddReservation from "./components/reservation/AddReservation";
import CustomCheckin from "./pages/CustomCheckin";
import Domotics from "./pages/Domotics";
import Support from "./pages/Support";
import SupportRequest from "./pages/SupportRequest";
import DomoticRules from "./pages/DomoticRules";
import dayjs from "dayjs";
import SessionLoading from "./components/common/SessionLoading";
import AdminLogin from "./pages/AdminLogin";
import ResetFromEmail from "./pages/ResetFromEmail";
import ConfirmNotification from "./pages/ConfirmNotification";
import Messaging from "./pages/Messaging";
import Modules from "./pages/Modules";
import Module from "./pages/Module";
import AddApartmentDigital from "./components/apartment/AddApartmentDigital";
import ChannelManager from "./pages/ChannelManager";
import BookingEngine from "./pages/BookingEngine";
import BookingEngineDetail from "./pages/BookingEngineDetail";


let rangeFrom = dayjs().subtract(200, 'days')
const rangeTo = dayjs()

const days = [] as any
while (rangeFrom.isBefore(rangeTo)) {
    days.push({
        time: rangeFrom.unix(),
        value: Math.floor(Math.random() * 20) + 1
    })
    rangeFrom = rangeFrom.add(1, 'days')
}

export default function AppRouter () {

  return (
    <Router>
      <SessionLoading />
      <Switch>
        <Route path="/" exact component={Login}></Route>
        <Route exact path="/reset/:token" component={ResetFromEmail}></Route>
        <Route exact path="/adminsign/:user/:token" component={AdminLogin}></Route>
        <Route exact path="/adminsign/:user/:token/:admin" component={AdminLogin}></Route>
        <SecureLayout>
          <Switch>
            <Route path="/dashboard" component={Dashboard}></Route>
            <Route path="/channel-manager" component={ChannelManager}></Route>
            <Route exact path="/booking-engine" component={BookingEngine}></Route>
            <Route exact path="/booking-engine/detail/:id" component={BookingEngineDetail}></Route>
            <Route exact path="/domotics" component={Domotics}></Route>
            <Route exact path="/domotics/rules" component={DomoticRules}></Route>
            <Route exact path="/apartments" component={Apartments}></Route>
            <Route path="/apartments/new" exact component={AddApartmentDigital}></Route>
            <Route path="/apartments/:id" component={ApartmentView}></Route>
            <Route exact path="/services" component={Services}></Route>
            <Route path="/services/:id" component={ServiceView}></Route>
            <Route exact path="/orders" component={Orders}></Route>
            <Route path="/orders/:id" component={OrderPage}></Route>
            <Route exact path="/reservations" component={Reservations}></Route>
            <Route path="/reservations/new" exact component={AddReservation}></Route>
            <Route path="/reservations/:id" component={Reservation}></Route>
            <Route exact path="/integrations" component={IntegrationsPage}></Route>
            <Route exact path="/integrations/:type/:id" component={IntegrationPage}></Route>
            <Route exact path="/integrations-callback-out/:type" component={IntegrationsCallbackOauthPage}></Route>
            <Route path="/integrations/:type/:id/details/:integration" component={IntegrationDetail}></Route>
            <Route path="/assistants/:context_id/details" component={MessageContextDetail}></Route>
            <Route path="/calendar" component={Calendar}></Route>
            <Route path="/calendar-new" component={CalendarNEW}></Route>
            <Route path="/profile" component={Profile}></Route>
            <Route path="/configuration" component={ConfigurationPage}></Route>
            <Route exact path="/contracts/:id" component={ContractPage}></Route>
            <Route exact path="/emails/:id" component={CustomEmail}></Route>
            <Route exact path="/custom-checkins/:id" component={CustomCheckin}></Route>
            <Route exact path="/bureaucracy/:type" component={BureaucracySettings}></Route>
            <Route exact path="/invoices" component={Invoices}></Route>
            <Route exact path="/modules" component={Modules}></Route>
            <Route exact path="/support" component={Support}></Route>
            <Route exact path="/support/request" component={SupportRequest}></Route>
            <Route exact path="/confirmnotification/:token" component={ConfirmNotification}></Route>
            <Route exact path="/messaging" component={Messaging}></Route>
            <Route exact path="/modules/:type" component={Module}></Route>
          </Switch>
        </SecureLayout>

      </Switch>
    </Router>
  )

}