import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { SortingRule } from "react-table";
import { toast } from "react-toastify";
import BaseTable from "../components/BaseTable";
import Modal from "../components/Modal";
import ModalActions from "../components/ModalAction";
import ModalCard from "../components/ModalCard";
import ModalSection from "../components/ModalSection";
import PageWrapper from "../components/PageWrapper";
import AddService from "../components/service/AddService";
// import useBreadcrumbs from '../hooks/useBreadcrumbs';
import { fetchServices, storeService, fetchPerms } from "../shared/queries";
import {
  Buyable,
  ServiceTable,
  TableFilterColumn,
  TableResponse,
  TableSort,
} from "../types";
import { isMobile } from "react-device-detect";
import TabSelector from "../components/TabSelector";

export default function Services() {
  const { t } = useTranslation();
  const history = useHistory();

  const [sorting, setSorting] = useState<TableSort[]>([]);
  const [validation, setValidation] = useState<boolean>(false);
  const [showAddService, setShowAddService] = useState(false);
  const [filters, setFilters] = useState<TableFilterColumn[] | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [accounts, setAccounts] = useState<{}>({});
  const [currentTab, setCurrentTab] = useState("services");

  function getColumns() {
    if (isMobile) {
      return [
        {
          id: "3",
          Header: t("services.name") || "Nome",
          accessor: "title",
        },
        {
          id: "4",
          Header: t("services.amount") || "Importo",
          accessor: "amount",
        },
        {
          id: "5",
          sorting: false,
          Header: "Tipo",
          accessor: (row: ServiceTable) => (
            <>
              {row.type === "SINGLE" && (
                <div
                  className={
                    "bg-cyan-100 text-cyan-800 inline-block text-xs font-medium px-2 rounded-2xl  py-1"
                  }
                >
                  {t("services.singlePurchase")}
                </div>
              )}
              {row.type === "MULTI" && (
                <div
                  className={
                    "bg-yellow-100 text-yellow-800 inline-block text-xs font-medium px-2 rounded-2xl  py-1"
                  }
                >
                  {t("services.multiPurchase")}
                </div>
              )}
            </>
          ),
        },

        {
          id: "6",
          sorting: false,
          Header: "Booking Engine",
          accessor: (row: ServiceTable) => (
            <>
              <div
                className={
                  "bg-cyan-100 text-cyan-800 inline-block text-xs font-medium px-2 rounded-2xl  py-1"
                }
              >
                {row.flg_bkengine.toString()}
              </div>
            </>
          ),
        },
      ];
    } else {
      return [
        {
          id: "1",
          Header: t("general.account") || "Account",
          accessor: (row: ServiceTable) => (
            <>
              {
                <div
                  className={
                    "bg-cyan-100 text-cyan-800 inline-block text-xs font-medium px-2 rounded-2xl  py-1"
                  }
                >
                  {(accounts as any)[row.user_key]}
                </div>
              }
            </>
          ),
        },
        {
          id: "3",
          Header: t("services.name") || "Nome",
          accessor: "title",
        },
        {
          id: "4",
          Header: t("services.amount") || "Importo",
          accessor: "amount",
        },
        {
          id: "5",
          sorting: false,
          Header: t("services.type") || "Tipo",
          accessor: (row: ServiceTable) => (
            <>
              {row.type === "SINGLE" && (
                <div
                  className={
                    "bg-cyan-100 text-cyan-800 inline-block text-xs font-medium px-2 rounded-2xl  py-1"
                  }
                >
                  {t("services.singlePurchase")}
                </div>
              )}
              {row.type === "MULTI" && (
                <div
                  className={
                    "bg-yellow-100 text-yellow-800 inline-block text-xs font-medium px-2 rounded-2xl  py-1"
                  }
                >
                  {t("services.multiPurchase")}
                </div>
              )}
            </>
          ),
        },
        {
          id: "6",
          sorting: false,
          Header: "Booking Engine",
          accessor: (row: ServiceTable) => (
            <>
              <div
                className={`  inline-block text-xs font-medium px-2 rounded-2xl  py-1 ${
                  row?.flg_bkengine
                    ? "text-green-800 bg-green-100"
                    : "text-cyan-800 bg-cyan-100"
                }`}
              >
                {row?.flg_bkengine?.toString()}
              </div>
            </>
          ),
        },
      ];
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: data1, isLoading: isLoadingAccount } = useQuery(
    "accounts",
    fetchPerms,
    {
      onSuccess: (data) => {
        const obj = {} as any;
        if (data && data.results) {
          data.results.forEach((account) => {
            obj[account.o_user_key] = account.o_name;
          });
          setAccounts(obj);
        }
      },
    }
  );

  const { data, isFetching } = useQuery<TableResponse<ServiceTable>, Error>(
    ["services", currentPage, filters, sorting],
    () => fetchServices(filters, (currentPage - 1) * 15, sorting),
    {
      keepPreviousData: true,
    }
  );

  function handleSorting(data: SortingRule<any>[]) {
    const cols = [] as TableSort[];
    data.forEach((f) => {
      const columnKey = getColumns().find((df) => df.id === f.id);
      if (columnKey) {
        cols.push({
          columnIndex: columnKey.id,
          desc: f.desc || false,
        });
      }
    });
    setSorting(cols);
  }

  const [service, setService] = useState<Buyable>();

  const mutation = useMutation(() => storeService(service), {
    onSuccess: (data) => {
      setShowAddService(false);
      toast.success(t("services.created"));
      history.push("/services/" + data?.results?.id);
    },
    onError: () => {
      toast.error(t("general.requestError"));
    },
  });

  /* useBreadcrumbs([
    { label: t('navigation.services'), route: '/services' }
  ]) */

  useEffect(() => {
    setFilters([
      {
        key: "2",
        type: "text",
        label: t("services.name"),
        active: false,
        value: null,
      },
      {
        key: "3",
        type: "text",
        label: t("services.amount"),
        active: false,
        value: null,
      },
      {
        key: "4",
        type: "select",
        label: t("services.type"),
        active: false,
        value: null,
        options: [
          { label: "Acquisto singlo", value: "SINGLE" },
          { label: "Acquisto multiplo", value: "MULTI" },
        ],
      },
      {
        key: "5",
        type: "select",
        label: "Booking engine",
        active: false,
        value: null,
        options: [
          { label: t("general.yes"), value: 1 },
          { label: t("general.no"), value: 0 },
        ],
      },
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    history.push(`/${currentTab}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);
  return (
    <PageWrapper>
      <TabSelector
        tabs={[
          {
            key: "orders",
            label: t("navigation.orders"),
            active: currentTab === "orders",
          },
          {
            key: "services",
            label: t("navigation.servicesDirect"),
            active: currentTab === "services",
          },
        ]}
        className={"mt-4 mb-8"}
        onChange={(tab) => setCurrentTab(tab.key)}
      />
      <BaseTable
        currentPage={currentPage}
        pageCount={Math.ceil(parseInt(data?.recordsTotal || "0") / 15)}
        identifierKey={"id"}
        // title={t(6ò
        data={!isLoadingAccount && !isFetching && data ? data.data : []}
        loading={isLoadingAccount || isFetching}
        totalRows={parseInt(data?.recordsTotal || "0")}
        displayRows={15}
        onSorting={handleSorting}
        renderButtons={() => (
          <button
            onClick={() => {
              setShowAddService(true);
            }}
            className={
              "py-1 cursor-pointer flex items-center focus:outline-none bg-cyan-600 shadow border px-3 text-sm text-white font-medium border-cyan-400 rounded-2xl"
            }
          >
            {t("services.newService")}
          </button>
        )}
        onFilterChange={(filters, page) => {
          setCurrentPage(page || 0);
          setFilters(filters);
        }}
        filters={filters ?? []}
        columns={getColumns()}
        onEdit={(id) => {
          history.push("/services/" + id);
        }}
      />

      <Modal visible={showAddService}>
        <ModalCard
          style={{
            width: "100%",
            maxWidth: "800px",
          }}
          title={t("services.newService")}
        >
          <ModalSection>
            <div className="p-6">
              <AddService
                onChange={(s, valid) => {
                  setService(s);
                  setValidation(valid);
                }}
              />
            </div>
          </ModalSection>
          <ModalActions
            isLoading={mutation.isLoading}
            saveLabel={t("general.save")}
            onClose={() => {
              setShowAddService(false);
            }}
            onSave={() => {
              if (validation) mutation.mutate();
            }}
          ></ModalActions>
        </ModalCard>
      </Modal>
    </PageWrapper>
  );
}
