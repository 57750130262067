import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { updateApartmentV3 } from "../../shared/queries";
import { Local } from "../../types";
import FormField from "../FormField";
import Modal from "../Modal";
import ModalActions from "../ModalAction";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import TextInput from "../TextInput";
import GoogleMapsSearch from "../GoogleMapsSearch";

type ApartmentUpdateAddressProps = {
  data: Local;
  visible: boolean;
  onClose?: () => void;
  onSave?: () => void;
};

export default function ApartmentUpdateAddress({
  data,
  visible,
  onClose,
  onSave,
}: ApartmentUpdateAddressProps) {
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const [name, setName] = useState<string>(data.name || "");
  const [publicName, setPublicName] = useState<string>(data.public_name || "");
  const [address, setAddress] = useState<string>(data.address || "");
  const [city, setCity] = useState<string>(data.city || "");
  const [zip, setZip] = useState<string>(data.zip || "");
  const [specs, setSpecs] = useState<string>(data.specs || "");
  const [bellName, setBellName] = useState<string>(data.bell_name || "");
  const [googlePlaceId, setGooglePlaceId] = useState("");
  const [countryEn, setCountryEn] = useState("");
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [cityEn, setCityEn] = useState("");
  const [houseNumberMissing, setHouseNumberMissing] = useState(false);
  const [province, setProvince] = useState(data.province || "");
  const [housenumber, setHouseNumber] = useState("");

  const handleGoogleMaps = (data: any) => {
    if (!data.address || data.address === "") {
      toast.error(t("general.addressNotFound"));
      return;
    }
    if (!data.housenumber || data.housenumber === "") {
      toast.error(t("addAddress.insertHousenumber"));
      setHouseNumberMissing(true);
      setHouseNumber("");
      setAddress(data.address);
      setCity(data.city);
      setZip(data.zipcode);
      setGooglePlaceId(data.google_place_id);
      setCountryEn(data.country_en);
      setLat(data.latitude);
      setLng(data.longitude);
      setCityEn(data.city_en);
      setProvince(data.province);
      return;
    }
    setHouseNumberMissing(false);
    setHouseNumber(data.housenumber);
    setAddress(
      `${data.address}, ${
        data.housenumber === "" || !data.housenumber ? "" : data.housenumber
      }`
    );
    setCity(data.city);
    setZip(data.zipcode);
    setGooglePlaceId(data.google_place_id);
    setCountryEn(data.country_en);
    setLat(data.latitude);
    setLng(data.longitude);
    setCityEn(data.city_en);
    setProvince(data.province);
  };

  /**
   * Mutate the selected stripe account
   */
  const mutation = useMutation(
    (body: any) =>
      updateApartmentV3(data.local_key || "", {
        name,
        public_name: publicName,
        address: body.address,
        city,
        specs,
        zip,
        bell_name: bellName,
        lat: lat,
        lng: lng,
        google_place_id: googlePlaceId,
        country_en: countryEn,
        city_en: cityEn,
        province,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        queryClient.invalidateQueries(["apartment", data.local_key]);
        if (onClose) onClose();
      },
    }
  );

  return (
    <Modal visible={visible}>
      <ModalCard title={t("updateAddress.updateAddress")}>
        <ModalSection>
          <div className={"p-4 flex-wrap flex -mx-2"}>
            <FormField
              className={"px-2 mb-4 w-full"}
              label={t("updateAddress.publicName")}
            >
              <TextInput
                value={publicName}
                onChange={(value) => setPublicName(value)}
              ></TextInput>
            </FormField>
            <FormField
              className={"px-2 mb-4 w-full"}
              label={t("updateAddress.name")}
            >
              <TextInput
                value={name}
                onChange={(value) => setName(value)}
              ></TextInput>
            </FormField>
            <FormField
              className={"px-2 mb-4 w-full"}
              label={t("general.findYourAddressAutoFill")}
            >
              <GoogleMapsSearch handleGoogleMaps={handleGoogleMaps} />
            </FormField>
            <FormField
              className={"px-2 mb-4 w-full"}
              label={t("general.address")}
            >
              <TextInput
                disabled
                className="bg-gray-200"
                value={address}
                onChange={(value) => setAddress(value)}
              ></TextInput>
            </FormField>

            {houseNumberMissing && (
              <FormField
                className={"px-2 w-full mb-4"}
                label={t("addAddress.housenumber")}
              >
                <TextInput
                  onChange={(value) => setHouseNumber(value)}
                  value={housenumber}
                ></TextInput>
                <p className="absolute text-red-500 text-xs">
                  {t("addAddress.insertHousenumber")}
                </p>
              </FormField>
            )}
            <FormField
              label={t("addAddress.province")}
              className="px-2 mb-4 w-full"
            >
              <TextInput
                maxLength={2}
                className="bg-gray-200"
                disabled
                value={province}
                onChange={(val) => setProvince(val)}
              />
            </FormField>
            <FormField className={"px-2 mb-4 w-full"} label={t("general.city")}>
              <TextInput
                disabled
                className="bg-gray-200"
                value={city}
                onChange={(value) => setCity(value)}
              ></TextInput>
            </FormField>
            <FormField
              className={"px-2 mb-4 w-full"}
              label={t("updateAddress.zip")}
            >
              <TextInput
                disabled
                className="bg-gray-200"
                value={zip}
                onChange={(value) => setZip(value)}
              ></TextInput>
            </FormField>
            <FormField
              className={"px-2 mb-4 w-full"}
              label={t("updateAddress.bellName")}
            >
              <TextInput
                onChange={(value) => setBellName(value)}
                value={bellName}
              ></TextInput>
            </FormField>
            <FormField
              className={"px-2 w-full"}
              label={t("updateAddress.specification")}
            >
              <TextInput
                onChange={(value) => setSpecs(value)}
                value={specs}
              ></TextInput>
            </FormField>
          </div>
        </ModalSection>
        <ModalActions
          isLoading={mutation.isLoading}
          saveDisabled={mutation.isLoading || (houseNumberMissing && (housenumber === "" || !housenumber))}
          closeDisabled={mutation.isLoading}
          saveLabel={t("general.update")}
          onClose={() => {
            if (onClose) onClose();
          }}
          onSave={() => {
            if (houseNumberMissing && housenumber !== "" && housenumber) {
              mutation.mutate({
                address: `${address}, ${housenumber}`,
              });
              if (onSave) onSave();
            }
            if (!houseNumberMissing) {
              mutation.mutate({
                address: address,
              });
              if (onSave) onSave();
            }
          }}
        ></ModalActions>
      </ModalCard>
    </Modal>
  );
}
