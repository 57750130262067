import React, { useEffect, useRef, useState } from "react";
import FormSectionTitle from "../FormSectionTitle";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import {
  deleteGalleryV3,
  fetchGalleryV3,
  positionGalleryV3,
  uploadGalleryV3,
} from "../../shared/queries";
import AsyncImage from "../AsyncImage";
import Icon from "../Icon";
import Button from "../Button";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import SelectInput from "../SelectInput";
import { BaseSelectItem } from "../../types";
import LoadingIntegrationSkeleton from "../integration/LoadingIntegrationSkeleton";
import Modal from "../Modal";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import TextInput from "../TextInput";
import ModalActions from "../ModalAction";

type Props = {
  id: string;
};

const BkGallery = (props: Props) => {
  const { id } = props;
  const { t } = useTranslation();
  const confirmationKey = t("general.iConfirm");
  const [currentPosition, setCurrentPosition] = useState<any>();
  const uploadInput = useRef<any>();
  const uploadInputAdd = useRef<any>();
  const [positionOption, setPositionOption] = useState<any[]>([]);
  const [modalDelete, setModalDelete] = useState({
    display: false,
    confirmValue: "",
    local_key: "",
    element: "",
    idx: "",
  });
  const {
    data: imageGallery,
    refetch: refetchImageGallery,
    isLoading: isLoadingImageGallery,
  } = useQuery(
    ["galleryBkEngine", id],
    () => fetchGalleryV3("BK-APT", "local_key", id as string),
    {
      enabled: !!id,
    }
  );

  const uploadMutation = useMutation(
    (body: {
      local_key: string;
      file?: string;
      element: string;
      idx: any;
      color?: string;
      text?: string;
    }) => uploadGalleryV3(body),
    {
      onSuccess: () => {
        toast.success(t("general.fileUploaded"));
        setTimeout(() => refetchImageGallery(), 1000);
      },
      onError: (error: Error) => {
        toast.error(t(error?.message ? error.message : "general.requestError"));
        setTimeout(() => refetchImageGallery(), 1000);
      },
    }
  );

  const deleteMutation = useMutation(
    (body: { local_key: string; element: string; idx: any }) =>
      deleteGalleryV3(body),
    {
      onSuccess: () => {
        toast.success(t("general.operationCompleted"));
        setTimeout(() => refetchImageGallery(), 1000);
        setModalDelete({
          display: false,
          confirmValue: "",
          local_key: "",
          element: "",
          idx: "",
        });
      },
      onError: (error: Error) => {
        toast.error(t(error?.message ? error.message : "general.requestError"));
        setTimeout(() => refetchImageGallery(), 1000);
        setModalDelete({
          display: false,
          confirmValue: "",
          local_key: "",
          element: "",
          idx: "",
        });
      },
    }
  );

  const positionMutation = useMutation(
    (body: { id: any; new_idx: any }) => positionGalleryV3(body),
    {
      onSuccess: () => {
        toast.success(t("general.fileUploaded"));
        setTimeout(() => refetchImageGallery(), 1000);
      },
      onError: (error: Error) => {
        toast.error(t(error?.message ? error.message : "general.requestError"));
        setTimeout(() => refetchImageGallery(), 1000);
      },
    }
  );

  const handleFile = (event: any) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const reader = new FileReader();
      reader.addEventListener("loadend", () => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const maxSize = 640;
          let width = image.width;
          let height = image.height;
          if (width > image.width) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width = 640;
              height = 480;
            }
          }
          canvas.height = height;
          canvas.width = width;
          const context = canvas.getContext("2d");
          if (context) {
            context.drawImage(image, 0, 0, width, height);
            const data = canvas.toDataURL();
            uploadMutation.mutate({
              local_key: id,
              file: data,
              element: "BK-APT",
              idx: currentPosition,
            });
          }
        };
        if (reader.result) {
          image.src = reader.result.toString();
        }
        return image.onload;
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleFileAdd = (event: any) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      if (imageGallery?.results?.length + event.target.files.length > 12) {
        toast.error("massimo numero di immagini raggiunto");
        return;
      }
      const files = Array.from(event.target.files);
      files?.forEach((file: any, index: number) => {
        const reader = new FileReader();
        reader.addEventListener("loadend", () => {
          const image = new Image();
          image.onload = () => {
            const canvas = document.createElement("canvas");
            const maxSize = 640;
            let width = image.width;
            let height = image.height;
            if (width > image.width) {
              if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
              }
            } else {
              if (height > maxSize) {
                width = 640;
                height = 480;
              }
            }
            canvas.height = height;
            canvas.width = width;
            const context = canvas.getContext("2d");
            if (context) {
              context.drawImage(image, 0, 0, width, height);
              const data = canvas.toDataURL();
              if (imageGallery?.results?.length) {
                if (imageGallery?.results?.length === 12) {
                  toast.error("massimo numero di immagini raggiunto");
                } else {
                  uploadMutation.mutate({
                    local_key: id,
                    file: data,
                    element: "BK-APT",
                    idx: imageGallery?.results?.length + index + 1,
                  });
                }
              } else {
                uploadMutation.mutate({
                  local_key: id,
                  file: data,
                  element: "BK-APT",
                  idx: index + 1,
                });
              }
            }
          };
          if (reader.result) {
            image.src = reader.result.toString();
          }
          return image.onload;
        });
        reader.readAsDataURL(file);
      });
    }
  };

  useEffect(() => {
    const options = Array.from(
      { length: imageGallery?.results?.length ?? 0 },
      (v, i) => {
        return { label: i + 1, value: i + 1 };
      }
    );
    setPositionOption(options);
  }, [imageGallery]);

  if (
    isLoadingImageGallery ||
    positionMutation.isLoading ||
    uploadMutation.isLoading
  ) {
    return <LoadingIntegrationSkeleton />;
  }

  return (
    <>
      {/* Image Gallery  */}
      <FormSectionTitle
        title={t("apartmentContent.galleryBkEngine")}
        onEdit={() => {}}
      ></FormSectionTitle>
      <div className="flex flex-wrap gap-5">
        {/* imageGallery */}

        {imageGallery?.results
          ?.sort((a, b) => (parseInt(a.idx) > parseInt(b.idx) ? 1 : -1))
          .map((image) => (
            <div className={"border flex flex-col rounded-2xl mb-3"}>
              <input
                key="input"
                type="file"
                hidden
                onChange={handleFile}
                ref={uploadInput}
              />

              {image.public_url ? (
                <AsyncImage
                  isDirect
                  className={"w-full h-full"}
                  width={"300px"}
                  height={"150px"}
                  zoomable
                  isLogo
                  url={image.public_url}
                />
              ) : (
                <img
                  className={"w-full "}
                  src={"/images/emptyimage.jpg"}
                  alt="empty"
                  style={{ height: "180px" }}
                />
              )}
              <div
                className={
                  "px-4 flex items-center justify-between py-2 text-xs border-t border-gray-300"
                }
              >
                <div className="flex">
                  <div>##BK-APT-{image.idx}##</div>
                  <div data-tip={`##BK-APT-${image.idx}##`}>
                    <Icon name="information-circle" size="16px" />
                  </div>
                </div>
                <div>
                  <div data-tip={t("general.edit")}>
                    <Button
                      flat
                      padding={"xs"}
                      size={"small"}
                      icon={"pencil"}
                      onClick={() => {
                        const idx = image?.idx;
                        setCurrentPosition(idx);
                        if (uploadInput && uploadInput.current)
                          uploadInput.current.click();
                      }}
                    />
                    <ReactTooltip />
                  </div>
                  <div data-tip={t("general.delete")}>
                    <Button
                      flat
                      color="negative"
                      padding={"xs"}
                      size={"small"}
                      icon={"trash"}
                      onClick={() => {
                        setModalDelete({
                          confirmValue: "",
                          display: true,
                          element: image.element,
                          local_key: id,
                          idx: image.idx,
                        });
                      }}
                    />
                    <ReactTooltip />
                  </div>
                </div>
              </div>
              <div>
                <SelectInput<BaseSelectItem, false>
                  value={positionOption.find(
                    (pos) => pos.value === parseFloat(image.idx)
                  )}
                  options={positionOption}
                  onChange={(item) => {
                    if (item) {
                      positionMutation.mutate({
                        id: image.id,
                        new_idx: item.value,
                      });
                    }
                  }}
                />
              </div>
            </div>
          ))}
      </div>
      <div className="mb-10">
        <input
          key="inputAdd"
          type="file"
          multiple
          hidden
          onChange={handleFileAdd}
          ref={uploadInputAdd}
          disabled={imageGallery?.results?.length === 12}
        />
        <Button
          label={t("general.add")}
          disabled={imageGallery?.results?.length === 12}
          icon="camera"
          onClick={() => {
            if (uploadInputAdd && uploadInputAdd.current)
              uploadInputAdd.current.click();
          }}
        />
      </div>

      <Modal visible={modalDelete.display}>
        <ModalCard
          title={`${t("general.delete")} -> ${modalDelete.element}${
            modalDelete.idx
          }`}
          className="w-full"
        >
          <ModalSection>
            <div className="text-gray-700 p-4 pt-0 border-gray-300">
              {t("general.write1")}
              <span className={"font-bold"}>{confirmationKey}</span>{" "}
              {t("general.write2")}
              <div className="mt-2">
                <TextInput
                  value={modalDelete.confirmValue}
                  onChange={(val) =>
                    setModalDelete((prev) => {
                      return {
                        ...prev,
                        confirmValue: val,
                      };
                    })
                  }
                />
              </div>
            </div>
          </ModalSection>
          <ModalActions
            isLoading={isLoadingImageGallery}
            onSave={() => {
              deleteMutation.mutate({
                local_key: modalDelete.local_key,
                element: modalDelete.element,
                idx: modalDelete.idx,
              });
            }}
            saveLabel={t("general.enable")}
            saveDisabled={
              confirmationKey !== modalDelete.confirmValue ||
              isLoadingImageGallery
            }
            onClose={() => {
              setModalDelete({
                display: false,
                confirmValue: "",
                local_key: "",
                element: "",
                idx: "",
              });
            }}
          />
        </ModalCard>
      </Modal>
    </>
  );
};

export default BkGallery;
