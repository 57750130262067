import React, { useState } from "react";
import FormSectionTitle from "../../FormSectionTitle";
import { useTranslation } from "react-i18next";
import Button from "../../Button";
import { useMutation, useQuery } from "react-query";
import {
  fetchLocalBkEngineServices,
  linkLocalToService,
  storeService,
} from "../../../shared/queries";
import Modal from "../../Modal";
import ModalCard from "../../ModalCard";
import ModalSection from "../../ModalSection";
import AddService from "../../service/AddService";
import ModalActions from "../../ModalAction";
import { Buyable } from "../../../types";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

type Props = {
  local_key: string;
};

const BkService = (props: Props) => {
  const { t } = useTranslation();
  const { local_key } = props;
  const [addService, setAddService] = useState(false);
  const [validation, setValidation] = useState<boolean>(false);
  const [service, setService] = useState<Buyable>();

  const { data: serviceBkEngine, refetch: refetchServiceBkEngine } =
    useQuery<any>(["serviceBkEngine", local_key], () =>
      fetchLocalBkEngineServices(local_key)
    );

  const mutation = useMutation(
    () =>
      storeService({
        ...service,
        flg_bkengine: true,
        mandatory: true,
      }),
    {
      onSuccess: (data: any) => {
        setAddService(false);
        toast.success(t("apartmentServices.serviceCreated"));
        linkMutation.mutate({
          id: data.results.id,
          local_keys: [local_key],
          mandatory_local_keys: [local_key]
        })
      },
      onError: () => {
        toast.error(t("general.requestError"));
      },
    }
  );
  const linkMutation = useMutation(
    (body: {
      id: string;
      local_keys: string[];
      mandatory_local_keys: string[];
    }) =>
      linkLocalToService(body.id, body.local_keys, body.mandatory_local_keys),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        refetchServiceBkEngine();
      },
      onError: () => {
        toast.error(t("general.requestError"));
      },
    }
  );
  return (
    <>
      <FormSectionTitle
        title={t("apartmentServices.availableServices")}
      ></FormSectionTitle>
      {serviceBkEngine && (
        <div className="mt-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {serviceBkEngine.results.map((service: any, index: number) => (
            <div
              key={index}
              className={
                "border border-gray-300 p-10 space-y-10 bg-white rounded-2xl justify-between"
              }
            >
              <div>
                <div className={"font-medium text-gray-700"}>
                  {service?.title}
                </div>
                <div className={"text-gray-600 text-sm"}>
                  {t("apartmentService.inSellAt")} {service?.amount_tot} EUR
                </div>
                <div>
                  <span className={"text-green-600 font-medium text-sm"}>
                    {service?.type === "MULTI"
                      ? t("services.multiPurchase")
                      : t("services.singlePurchase")}
                  </span>
                </div>
              </div>

              <div className={"self-end"}>
                <Link
                  to={"/services/" + service?.id}
                  className={
                    "bg-cyan-600 p-2 text-white text-center rounded-2xl text-sm"
                  }
                >
                  {t("general.edit")}
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="mt-4">
        <Button
          onClick={() => {
            setAddService(true);
          }}
          label={t("apartmentServices.addService")}
        />
      </div>
      <Modal visible={addService}>
        <ModalCard
          style={{
            width: "100%",
            maxWidth: "800px",
          }}
          title={t("apartmentServices.newService")}
        >
          <ModalSection>
            <div className="p-6">
              <AddService
                isFlgBkEngine
                onChange={(s, valid) => {
                  setValidation(valid);
                  setService(s);
                }}
              />
            </div>
          </ModalSection>
          <ModalActions
            saveLabel={t("apartmentServices.createService")}
            onClose={() => {
              setAddService(false);
              setService(undefined);
            }}
            onSave={() => {
              // store it
              if (validation) mutation.mutate();
            }}
          ></ModalActions>
        </ModalCard>
      </Modal>
    </>
  );
};

export default BkService;
