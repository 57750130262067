import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseSelectItem, EntityUpdateData } from "../../types";
import FormField from "../FormField";
import Modal from "../Modal";
import ModalActions from "../ModalAction";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import TextInput from "../TextInput";
import SelectInput from "../SelectInput";

export default function EntityUpdateDialog({
  title,
  data,
  fields,
  visible,
  loading = false,
  onClose,
  onSave,
  phoneCodeListLoading = false,
  phoneList
}: {
  title: string;
  fields: EntityUpdateData[];
  data: any;
  loading?: boolean;
  visible: boolean;
  onClose?: () => void;
  onSave?: (data: any) => void;
  phoneCodeListLoading?: boolean
  phoneList?: any
}) {
  const { t } = useTranslation();

  const [entityData, setEntityData] = useState<any>();
  const [prefixNumber, setPrefixNumber] = useState<any>();
  const [prefixNumberSecond, setPrefixNumberSecond] = useState<any>();

  /**
   * Set the base status of the object
   */
  useEffect(() => {
    if (data) {
      setEntityData(data);
    }
  }, [data, visible]);

  return (
    <Modal visible={visible}>
      <ModalCard title={title}>
        <ModalSection>
          {entityData && (
            <div className={"p-4 flex-wrap flex -mx-2"}>
              {fields.map((data: EntityUpdateData, index: number) => {
                if (data.field === "voip_buddy_number") {
                  return (
                    <div className="w-full md:grid grid-cols-5 md:gap-3">
                      <FormField
                        label={`${t("resv.prefixNumber")} (${data.label})`}
                        className="col-span-2"
                      >
                        <SelectInput<BaseSelectItem, false>
                          isLoading={phoneCodeListLoading}
                          placeholder={t("selector.selectOption")}
                          value={prefixNumber}
                          options={phoneList}
                          onChange={(item) => {
                            if (item) {
                              setPrefixNumber(item);
                              setEntityData({
                                ...entityData,
                                [data.field]: item.value,
                              });
                            }
                          }}
                        />
                      </FormField>
                      <FormField
                        key={index}
                        className={"px-2 mb-4 w-full flex-1 col-span-3"}
                        label={data.label}
                      >
                        <TextInput
                          value={entityData[data.field]}
                          onChange={(value) => {
                            setEntityData({
                              ...entityData,
                              [data.field]: value,
                            });
                          }}
                        ></TextInput>
                      </FormField>
                    </div>
                  );
                }
                if (data.field === "other_number") {
                  return (
                    <div className="w-full md:grid grid-cols-5 md:gap-3">
                      <FormField
                        label={`${t("resv.prefixNumber")} (${data.label})`}
                        className="col-span-2"
                      >
                        <SelectInput<BaseSelectItem, false>
                          isLoading={phoneCodeListLoading}
                          placeholder={t("selector.selectOption")}
                          value={prefixNumberSecond}
                          options={phoneList}
                          onChange={(item) => {
                            if (item) {
                              setPrefixNumberSecond(item);
                              setEntityData({
                                ...entityData,
                                [data.field]: item.value,
                              });
                            }
                          }}
                        />
                      </FormField>
                      <FormField
                        key={index}
                        className={"px-2 mb-4 w-full flex-1 col-span-3"}
                        label={data.label}
                      >
                        <TextInput
                          value={entityData[data.field]}
                          onChange={(value) => {
                            setEntityData({
                              ...entityData,
                              [data.field]: value,
                            });
                          }}
                        ></TextInput>
                      </FormField>
                    </div>
                  );
                }
                return (
                  <FormField
                    key={index}
                    className={"px-2 mb-4 w-full"}
                    label={data.label}
                  >
                    <TextInput
                      value={entityData[data.field]}
                      onChange={(value) => {
                        setEntityData({
                          ...entityData,
                          [data.field]: value,
                        });
                      }}
                    ></TextInput>
                  </FormField>
                );
              })}
            </div>
          )}
        </ModalSection>
        <ModalActions
          isLoading={loading}
          saveLabel={t("general.update")}
          onClose={() => {
            if (onClose) onClose();
            setPrefixNumberSecond(null)
            setPrefixNumber(null)
          }}
          onSave={() => {
            if (onSave) onSave(entityData);
            setPrefixNumberSecond(null)
            setPrefixNumber(null)
          }}
        ></ModalActions>
      </ModalCard>
    </Modal>
  );
}
