import Modal from "../Modal";
import ModalCard from "../ModalCard";
import { useTranslation } from "react-i18next";
import ModalSection from "../ModalSection";
import ModalActions from "../ModalAction";
import TextInput from "../TextInput";
import { useMutation, useQuery } from "react-query";
import {
  editRevenueManagerLocal,
  fetchRevenueManagerLocal,
  sendRevenueManagerLocal,
} from "../../shared/queries";
import SelectInput from "../SelectInput";
import { BaseSelectItem } from "../../types";
import { currencies } from "../../shared/utils/config";
import { useState } from "react";
import { toast } from "react-toastify";

interface RevenueModalProps {
  openModal: {
    display: boolean;
    local_key: string;
    local_name: string;
  };
  setOpenModal: any;
  refetchRevenueManager: any;
}

const RevenueModal = ({
  openModal,
  setOpenModal,
  refetchRevenueManager,
}: RevenueModalProps) => {
  const { t } = useTranslation();
  const revenueOptions = [
    { label: t("revenueManager.custom"), value: "custom" },
    { label: t("revenueManager.balanced"), value: "balanced" },
    { label: t("revenueManager.high_adr"), value: "high_adr" },
    { label: t("revenueManager.high_occupancy"), value: "high_occupancy" },
  ];
  const orphanOptions = [
    { label: t("revenueManager.addPercentage"), value: "+" },
    { label: t("revenueManager.minusPercentage"), value: "-" },
  ];

  const [percentageApp, setPercentageApp] = useState(1);
  const [percentageAppTwo, setPercentageAppTwo] = useState(1);
  const [isEditing, setIsEditing] = useState(false);

  const [revenue, setRevenue] = useState<{
    listing_id: string | null;
    pricing_strategy: string | null;
    base_rate: number | null;
    min_rate: number | null;
    max_rate: number | null;
    currency: string | null;
    orphan_1_day_modifier: number | null;
    orphan_2_day_modifier: number | null;
    num_months: number | null;
  }>({
    listing_id: null,
    pricing_strategy: null,
    base_rate: null,
    min_rate: null,
    max_rate: null,
    currency: null,
    orphan_1_day_modifier: null,
    orphan_2_day_modifier: null,
    num_months: null,
  });

  useQuery<any>(
    ["moduleDetailRevenue", openModal.local_key],
    () => fetchRevenueManagerLocal(openModal.local_key),
    {
      onSuccess: (data) => {
        const results = data.results;
        setRevenue({
          listing_id: results.listing_id,
          pricing_strategy: results.pricing_strategy,
          base_rate: results.base_rate,
          min_rate: results.min_rate,
          max_rate: results.max_rate,
          currency: results.currency,
          orphan_1_day_modifier: results.orphan_1_day_modifier,
          orphan_2_day_modifier: results.orphan_2_day_modifier,
          num_months: results.num_months,
        });
        setIsEditing(true);
      },
      enabled: !!openModal.local_key,
      retry: false,
    }
  );
  /* useQuery<any>(
    ["moduleRevenueListingId", openModal.local_key],
    () => fetchRevenueManagerListingId(openModal.local_key),
    {
      onSuccess: (data) => {
        console.log('datadata', data)
      },
      enabled: !!openModal.local_key,
      retry: false,
    }
  ); */

  const changeData = (prop: string, value: any) => {
    setRevenue((prev: any) => {
      return {
        ...prev,
        [prop]: value,
      };
    });
  };

  const sendRevenueManagerLocalMutation = useMutation(
    isEditing ? editRevenueManagerLocal : sendRevenueManagerLocal,
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        setOpenModal({
          display: false,
          local_key: "",
          local_name: "",
        });
        refetchRevenueManager();
      },
      onError: () => {
        toast.error(t("general.requestError"));
        setOpenModal({
          display: false,
          local_key: "",
          local_name: "",
        });
        refetchRevenueManager();
      },
    }
  );

  return (
    <Modal visible={openModal.display} isResvDocs>
      <ModalCard className="w-full md:h-auto h-screen">
        <ModalSection>
          <div className={"space-y-4 p-4"}>
            <div className={"w-full text-sm font-medium text-cyan-600 mt-4"}>
              {openModal.local_name}
            </div>
            <div className="border-b border-cyan-300 h-full my-3"></div>
            <div
              className={
                "items-center justify-between md:flex md:flex-1 md:space-x-3"
              }
            >
              <div className={"block"}>
                <span className="text-sm">
                  {t("revenueManager.listing_id")}
                  <span className="text-xs italic">
                    ({t("general.requiredField")})
                  </span>
                </span>
                <p className="text-xs">{t("revenueManager.abnbInfo")}</p>
              </div>
              <div className={"md:w-80 w-full md:mt-0 mt-1.5"}>
                <TextInput
                  value={revenue.listing_id ?? ""}
                  onChange={(val) => {
                    const value = val;
                    changeData("listing_id", value);
                  }}
                />
              </div>
            </div>
            <div
              className={
                "items-center justify-between md:flex md:flex-1 md:space-x-3"
              }
            >
              <div className={"block"}>
                <span className="text-sm">
                  {t("revenueManager.pricing_strategy")}
                  <span className="text-xs italic">
                    ({t("general.requiredField")})
                  </span>
                </span>
                <p className="text-xs">
                  {t("revenueManager.pricing_strategyInfo")}
                </p>
              </div>
              <div className={"md:w-80 w-full md:mt-0 mt-1.5"}>
                <SelectInput<BaseSelectItem, false>
                  value={revenueOptions.find(
                    (rev) => rev.value === revenue.pricing_strategy
                  )}
                  options={[
                    ...revenueOptions,
                    {
                      label: null,
                      value: null,
                    },
                  ]}
                  onChange={(item) => {
                    if (item) {
                      changeData("pricing_strategy", item.value);
                    }
                  }}
                />
              </div>
            </div>
            <div className={"w-full text-sm font-medium text-cyan-600 mt-4"}>
              {revenue.pricing_strategy === "" || !revenue.pricing_strategy
                ? t("revenueManager.pricing_strategy")
                : revenue.pricing_strategy === "custom"
                ? t(`revenueManager.${revenue.pricing_strategy}`)
                : t(`revenueManager.${revenue.pricing_strategy}Info`)}
            </div>
            <div className="border-b border-cyan-300 h-full my-1"></div>
            {revenue.pricing_strategy === "custom" && (
              <div
                className={
                  "items-center justify-between md:flex md:flex-1 md:space-x-3"
                }
              >
                <div>
                  <span className="text-sm">
                    {t("revenueManager.base_rate")}{" "}
                    <span className="text-xs italic">
                      ({t("general.requiredField")})
                    </span>
                  </span>
                  <p className="text-xs">{t("revenueManager.base_rateInfo")}</p>
                </div>
                <div className={"md:w-80 w-full md:mt-0 mt-1.5"}>
                  <TextInput
                    type="number"
                    numbered
                    value={revenue.base_rate ?? 0}
                    onChange={(val) => {
                      const value = parseFloat(val) ? parseFloat(val) : 0;
                      changeData("base_rate", value);
                    }}
                  />
                </div>
              </div>
            )}
            <div
              className={
                "items-center justify-between md:flex md:flex-1 md:space-x-3"
              }
            >
              <div className={"block"}>
                <span className="text-sm">
                  {t("revenueManager.num_months")}{" "}
                  <span className="text-xs italic">
                    ({t("general.requiredField")})
                  </span>
                </span>
                <p className="text-xs">{t("revenueManager.num_monthsInfo")}</p>
              </div>
              <div className={"md:w-80 w-full md:mt-0 mt-1.5"}>
                <TextInput
                  type="number"
                  value={revenue.num_months ?? 0}
                  numbered
                  onChange={(val) => {
                    const value = parseInt(val) ? parseInt(val) : 0;
                    changeData("num_months", value);
                  }}
                />
              </div>
            </div>
            <div
              className={
                "items-center justify-between md:flex md:flex-1 md:space-x-3"
              }
            >
              <div className={"block"}>
                <p className="text-sm">{t("revenueManager.min_rate")}</p>
                <p className="text-xs">{t("revenueManager.min_rateInfo")}</p>
              </div>
              <div className={"md:w-80 w-full md:mt-0 mt-1.5"}>
                <TextInput
                  type="number"
                  value={revenue.min_rate ?? 0}
                  onChange={(val) => {
                    const value = parseInt(val) ? parseInt(val) : 1;
                    changeData("min_rate", value);
                  }}
                />
              </div>
            </div>
            <div
              className={
                "items-center justify-between md:flex md:flex-1 md:space-x-3"
              }
            >
              <div className={"block"}>
                <p className="text-sm">{t("revenueManager.max_rate")}</p>
                <p className="text-xs">{t("revenueManager.max_rateInfo")}</p>
              </div>
              <div className={"md:w-80 w-full md:mt-0 mt-1.5"}>
                <TextInput
                  type="number"
                  value={revenue.max_rate ?? 0}
                  onChange={(val) => {
                    const value = parseInt(val) ? parseInt(val) : 1;
                    changeData("max_rate", value);
                  }}
                />
              </div>
            </div>
            <div
              className={
                "items-center justify-between md:flex md:flex-1 md:space-x-3"
              }
            >
              <div className={"block"}>
                <p className="text-sm">{t("revenueManager.currency")}</p>
                <p className="text-xs">{t("revenueManager.currencyInfo")}</p>
              </div>
              <div className={"md:w-80 w-full md:mt-0 mt-1.5"}>
                <SelectInput
                  menuPortalTarget={document.body}
                  value={currencies.find(
                    (curr) =>
                      curr.value.toLowerCase() ===
                      revenue.currency?.toLowerCase()
                  )}
                  onChange={(item: any) => {
                    changeData("currency", item.value);
                  }}
                  options={currencies}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </div>
            </div>
            <div
              className={
                "items-center justify-between md:flex md:flex-1 md:space-x-3"
              }
            >
              <div className={"block"}>
                <p className="text-sm">
                  {t("revenueManager.orphan_1_day_modifier")}
                </p>
                <p className="text-xs">
                  {t("revenueManager.orphan_1_day_modifierInfo")}
                </p>
              </div>
              <div className="md:w-80 w-full md:mt-0 mt-1.5 space-y-1">
                <div className="w-full">
                  <SelectInput<BaseSelectItem, false>
                    options={orphanOptions}
                    onChange={(item) => {
                      if (item?.value === "-") {
                        changeData(
                          "orphan_1_day_modifier",
                          revenue.orphan_1_day_modifier
                            ? Math.abs(revenue.orphan_1_day_modifier) * -1
                            : 0
                        );
                        setPercentageApp(-1);
                      } else if (item?.value === "+") {
                        changeData(
                          "orphan_1_day_modifier",
                          revenue.orphan_1_day_modifier
                            ? Math.abs(revenue.orphan_1_day_modifier)
                            : 0
                        );
                        setPercentageApp(1);
                      }
                    }}
                  />
                </div>
                <div className="w-full">
                  <TextInput
                    value={
                      revenue.orphan_1_day_modifier
                        ? Math.abs(revenue.orphan_1_day_modifier) * 100
                        : 0
                    }
                    type="number"
                    min={0}
                    max={100}
                    numbered
                    onChange={(val) => {
                      changeData(
                        "orphan_1_day_modifier",
                        parseFloat(val)
                          ? (percentageApp * parseFloat(val)) / 100
                          : 0
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className={
                "items-center justify-between md:flex md:flex-1 md:space-x-3"
              }
            >
              <div className={"block"}>
                <p className="text-sm">
                  {t("revenueManager.orphan_2_day_modifier")}
                </p>
                <p className="text-xs">
                  {t("revenueManager.orphan_2_day_modifierInfo")}
                </p>
              </div>
              <div className="md:w-80 w-full md:mt-0 mt-1.5 space-y-1">
                <div className="w-full">
                  <SelectInput<BaseSelectItem, false>
                    options={orphanOptions}
                    onChange={(item) => {
                      if (item?.value === "-") {
                        setPercentageAppTwo(-1);
                        changeData(
                          "orphan_2_day_modifier",
                          revenue.orphan_2_day_modifier
                            ? Math.abs(revenue.orphan_2_day_modifier) * -1
                            : 0
                        );
                      } else if (item?.value === "+") {
                        setPercentageAppTwo(1);
                        changeData(
                          "orphan_2_day_modifier",
                          revenue.orphan_2_day_modifier
                            ? Math.abs(revenue.orphan_2_day_modifier) * 1
                            : 0
                        );
                      }
                    }}
                  />
                </div>
                <div className="w-full">
                  <TextInput
                    value={
                      revenue.orphan_2_day_modifier
                        ? Math.abs(revenue.orphan_2_day_modifier) * 100
                        : 0
                    }
                    type="number"
                    numbered
                    min={0}
                    max={100}
                    onChange={(val) => {
                      changeData(
                        "orphan_2_day_modifier",
                        parseFloat(val)
                          ? (percentageAppTwo * parseFloat(val)) / 100
                          : 0
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalSection>
        <ModalActions
          isLoading={false}
          onSave={() => {
            const revenueObject = Object.fromEntries(
              Object.entries(revenue).filter(([key, value]) => value !== null)
            );
            sendRevenueManagerLocalMutation.mutate({
              revenueObject: revenueObject,
              local_key: openModal.local_key,
            });
          }}
          saveLabel={t("general.save")}
          saveDisabled={
            revenue.pricing_strategy === "custom"
              ? !revenue.base_rate ||
                (revenue.base_rate && revenue.base_rate < 1) ||
                (revenue.num_months && revenue.num_months < 1) ||
                !revenue.num_months
              : revenue.pricing_strategy === "" ||
                !revenue.pricing_strategy ||
                (revenue.num_months && revenue.num_months < 1) ||
                !revenue.num_months
          }
          onClose={() => {
            setOpenModal({
              display: false,
              local_key: "",
              local_name: "",
            });
          }}
        />
      </ModalCard>
    </Modal>
  );
};

export default RevenueModal;
