import { useState } from "react";
import PageWrapper from "../components/PageWrapper";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PreviousPage from "../components/PreviousPage";
import { useQuery } from "react-query";
import { fetchAllModules, fetchApartment } from "../shared/queries";
import TabSelector from "../components/TabSelector";
import BkGallery from "../components/bkengine/BkGallery";
import BkRate from "../components/bkengine/BkRate";
import BkContents from "../components/bkengine/BkContents";
import BkContacts from "../components/bkengine/BkContacts";
import Icon from "../components/Icon";
import ApartmentLogo from "../components/apartment/ApartmentLogo";
import { moduleId } from "../shared/utils/config";

const BookingEngineDetail = () => {
  const { id } = useParams<any>();
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState("gallery");
  const [logoTab, setLogoTab] = useState<boolean>(true);
  const { data: apartmentDetail } = useQuery<any>(
    ["bkApartment", id],
    () => fetchApartment(id as string),
    {
      enabled: !!id,
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data: dataModules } = useQuery<any>([id], () => {
      if (id) {
        return fetchAllModules(id).then((data: any) => {
          const moduleLogoFinded = data?.modules?.find(
            (module: any) => module?.id === moduleId.logo
          );
          if (moduleLogoFinded) {
            setLogoTab(false);
          } else {
            setLogoTab(true);
          }
          return data;
        });
      }
    });

  return (
    <PageWrapper>
      <PreviousPage
        className={"mb-4"}
        label={t("general.goBack")}
      ></PreviousPage>
      <div
        style={{
          boxShadow:
            "0px 2px 8px rgba(0,0,0,0.03), 0px 0px 0px 1px rgba(0,0,0,0.05)",
        }}
        className={"bg-gray-50 rounded-2xl overflow-hidden my-5 mb-5"}
      >
        <div
          className={
            "bg-white items-center w-full p-4 flex flex-wrap justify-between rounded-2xl rounded-tr-none rounded-tl-none"
          }
        >
          {/* TITOLO E SOTTOTITOLO */}
          <div className="">
            <strong>{apartmentDetail?.results?.name}</strong>
            <p className={`text-cyan-700 text-sm mb-2`}>
              www.booking.vikey.it?local_key=
              {apartmentDetail?.results?.local_key}
            </p>
          </div>
          {/* ACTION BOTTONE */}
          <div className="max-w-max">
            <a
              href={`https://booking.vikey.it/?local_key=${apartmentDetail?.results?.local_key}`}
              target={"_blank"}
              style={{
                boxShadow:
                  "0px 2px 4px rgba(0,0,0,0.06), 0px 0px 0px 1px rgba(0,0,0,0.07)",
              }}
              className={`bg-white flex items-center px-3 py-2 rounded-2xl`}
              rel="noreferrer"
            >
              {t('channelManager.reservationLink')}
              <Icon name={"calendar"} size={"20px"} className={"ml-2"}></Icon>
            </a>
          </div>
        </div>
      </div>
      <TabSelector
        tabs={[
          {
            key: "gallery",
            label: t("apartmentContent.gallery"),
            active: currentTab === "gallery",
          },
          {
            key: "rate",
            label: t("channelManager.rate_plan.rate"),
            active: currentTab === "rate",
          },
          {
            key: "contents",
            label: t("apartment.contents"),
            active: currentTab === "contents",
          },
          {
            key: "contacts",
            label: t("resv.links"),
            active: currentTab === "contacts",
          },
          {
            key: "logo",
            label: t("apartment.logo"),
            active: currentTab === "logo",
            hidden: logoTab,
          },
        ]}
        className={"mt-4"}
        onChange={(tab) => {
          setCurrentTab(tab.key);
        }}
      />
      {currentTab === "gallery" && <BkGallery id={id} />}
      {currentTab === "rate" && <BkRate id={id} />}
      {currentTab === "contents" && (
        <BkContents id={id} apartmentDetail={apartmentDetail} />
      )}
      {currentTab === "contacts" && <BkContacts id={id} />}
      {currentTab === 'logo' && !logoTab && <ApartmentLogo data={apartmentDetail?.results} isBkEngine />}
    </PageWrapper>
  );
};

export default BookingEngineDetail;
