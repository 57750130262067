import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  fetchLocalSmsAddon,
  fetchPhoneCodeList,
  saveLocalSmsAddon,
  updateApartment,
  updateApartmentV3,
} from "../../shared/queries";
import { Local } from "../../types";
import EntityUpdateDialog from "../common/EntityUpdateDialog";
import FormSectionTitle from "../FormSectionTitle";
import Checkbox from "../Checkbox";
import ApartmentUpdateAddress from "./ApartmentUpdateAddress";
import ApartmentUpdateBeds from "./ApartmentUpdateBeds";
import ApartmentUpdateWifi from "./ApartmentUpdateWifi";
import { isDefined } from "../../shared/utils/guards";

type ApartmentGeneralProps = {
  data: Local;
};

export default function ApartmentGeneral({ data }: ApartmentGeneralProps) {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const [updateAddress, setUpdateAddress] = useState<boolean>(false);
  const [updateBeds, setUpdateBeds] = useState<boolean>(false);
  const [updateWifi, setUpdateWifi] = useState<boolean>(false);
  const [updateContacts, setUpdateContacts] = useState<boolean>(false);
  const [phoneList, setPhoneList] = useState<any>([]);

  const {
    data: smsAddon,
    isFetching: loadingSmsAddon,
    refetch: refetchSmsAddon,
  } = useQuery(
    ["smsAddon", data.local_key],
    () => fetchLocalSmsAddon(data.local_key || ""),
    {
      enabled: isDefined(data.local_key),
    }
  );

  const { isLoading: phoneCodeListLoading } = useQuery<any>(
    ["phoneCodeList"],
    () => fetchPhoneCodeList(),
    {
      onSuccess: (data) => {
        const states = Object.keys(data.results).map((d) => {
          return {
            label: `${d} (${data.results[d]})`,
            value: data.results[d],
          };
        });
        setPhoneList(states);
        return data;
      },
    }
  );

  const updateContactMutation = useMutation(
    (data: any) =>
      updateApartmentV3(data.local_key || "", {
        voip_buddy_number: data["voip_buddy_number"],
        other_number: data["other_number"],
      }),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        queryClient.invalidateQueries(["apartment", data.local_key]);
        setUpdateContacts(false);
      },
    }
  );

  const updateVideoCallMutation = useMutation(
    (value: boolean) =>
      updateApartmentV3(data.local_key || "", {
        videocall: value ? 1 : 0,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        queryClient.invalidateQueries(["apartment", data.local_key]);
      },
    }
  );

  const updateSMSMutation = useMutation(
    () =>
      saveLocalSmsAddon(
        data.local_key || "",
        !smsAddon?.results?.enabled || smsAddon?.results?.renabled
          ? "enable"
          : "disable"
      ),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        refetchSmsAddon();
      },
      onError: () => {
        toast.success(t("general.error"));
      },
    }
  );

  return (
    <div>
      <FormSectionTitle
        borderless
        title={t("general.address")}
        edit={true}
        onEdit={() => {
          setUpdateAddress(true);
        }}
      ></FormSectionTitle>

      <div className="flex mb-3 flex-wrap">
        <div className={"w-40 xs:w-full text-gray-500"}>
          {t("apartmentGeneral.publicName")}
        </div>
        <div>{data.public_name}</div>
      </div>

      <div className="flex mb-3 flex-wrap">
        <div className={"w-40 xs:w-full text-gray-500"}>
          {t("apartmentGeneral.name")}
        </div>
        <div>{data.name}</div>
      </div>

      <div className="flex mb-3 flex-wrap">
        <div className={"w-40 xs:w-full text-gray-500"}>
          {t("general.address")}
        </div>
        <div>{data.address}</div>
      </div>

      <div className="flex mb-3 flex-wrap relative">
        {(data.city === "" ||
          data.city === null ||
          data.city === undefined) && (
          <div
            style={{
              animation: "pulse 2s infinite",
            }}
            className="border-2 border-white absolute top-0 -left-2 w-3 h-3 bg-red-600 rounded-full"
          />
        )}
        <div className={"w-40 xs:w-full text-gray-500"}>
          {t("general.city")}
        </div>
        <div>
          {data.city || t("apartmentGeneral.noAddress")} - {data.zip}
        </div>
      </div>

      <div className="flex mb-3 flex-wrap">
        <div className={"w-40 xs:w-full text-gray-500"}>
          {t("apartmentGeneral.bellName")}
        </div>
        <div>{data.bell_name || t("apartmentGeneral.noSpecification")}</div>
      </div>

      <div className="flex mb-3 flex-wrap">
        <div className={"w-40 xs:w-full text-gray-500"}>
          {t("apartmentGeneral.specification")}
        </div>
        <div>{data.specs || t("apartmentGeneral.noSpecification")}</div>
      </div>

      <ApartmentUpdateAddress
        visible={updateAddress}
        data={data}
        onClose={() => {
          setUpdateAddress(false);
        }}
      ></ApartmentUpdateAddress>

      <FormSectionTitle
        title={t("apartmentGeneral.composition")}
        edit={true}
        onEdit={() => {
          setUpdateBeds(true);
        }}
      ></FormSectionTitle>

      <div className="flex mb-3 flex-wrap">
        <div className={"w-40 xs:w-full text-gray-500"}>
          {t("apartmentGeneral.rooms")}
        </div>
        <div>{data.room_count}</div>
      </div>

      <div className="flex mb-3 flex-wrap">
        <div className={"w-40 xs:w-full text-gray-500"}>
          {t("apartmentGeneral.bathRooms")}
        </div>
        <div>{data.bathroom_count || "0"}</div>
      </div>

      <div className="flex mb-3 flex-wrap">
        <div className={"w-40 xs:w-full text-gray-500"}>
          {t("apartmentGeneral.beds")}
        </div>
        <div>{data.beds_count || "0"}</div>
      </div>

      <ApartmentUpdateBeds
        visible={updateBeds}
        data={data}
        onClose={() => {
          setUpdateBeds(false);
        }}
      ></ApartmentUpdateBeds>

      <FormSectionTitle
        title={t("apartmentGeneral.connection")}
        edit={true}
        onEdit={() => {
          setUpdateWifi(true);
        }}
      ></FormSectionTitle>

      <div className="flex mb-3 flex-wrap">
        <div className={"w-40 xs:w-full text-gray-500"}>
          {t("apartmentGeneral.ssid")}
        </div>
        <div>{data.wifi_name || t("updateConnection.notAvailable")}</div>
      </div>

      <div className="flex mb-3 flex-wrap">
        <div className={"w-40 xs:w-full text-gray-500"}>
          {t("apartmentGeneral.password")}
        </div>
        <div>{data.wifi_password || t("updateConnection.notAvailable")}</div>
      </div>

      <ApartmentUpdateWifi
        visible={updateWifi}
        data={data}
        onClose={() => {
          setUpdateWifi(false);
        }}
      ></ApartmentUpdateWifi>
      <FormSectionTitle
        title={t("apartmentGeneral.contacts")}
        edit={true}
        onEdit={() => {
          setUpdateContacts(true);
        }}
      ></FormSectionTitle>
      <div className="flex mb-3 flex-wrap">
        <div className={"w-96 xs:w-full text-gray-500"}>
          {t("apartmentGeneral.hostFirstNumber")}
        </div>
        <div>{data.voip_buddy_number || "ND"}</div>
      </div>
      <div className="flex mb-3 flex-wrap">
        <div className={"w-96 xs:w-full text-gray-500"}>
          {t("apartmentGeneral.hostSecondNumber")}
        </div>
        <div className="flex">
          {data.other_number && data.other_number !== ""
            ? data.other_number
            : t("updateConnection.notAvailable")}
        </div>
      </div>

      <FormSectionTitle title={t("resv.communication")} />

      <Checkbox
        loading={updateVideoCallMutation.isLoading}
        checked={data.videocall || false}
        onChange={() => updateVideoCallMutation.mutate(!data.videocall)}
        label={t("resv.enableVideocall")}
        hint={t("resv.videoCallInfo")}
      />

      {/* <Checkbox
        className={'mt-2'}
        loading={updateSMSMutation.isLoading || loadingSmsAddon}
        checked={
          smsAddon && smsAddon.results
            ? smsAddon.results.enabled && !smsAddon.results.renabled
            : false
        }
        onChange={() => updateSMSMutation.mutate()}
        label={
          t('resv.enableSMS') + ' ' + (smsAddon?.results?.active_until
            ? ' - ' + t('resv.activeUntil')+ ' ' + formatSmsTime(smsAddon.results.active_until)
            : '')
        }
        hint={t('resv.smsInfo')}
      />

      <div
        className={
          'text-sm text-gray-600 p-3 bg-white rounded-2xl border ml-11 mt-2'
        }
      >
        <div className={'text-gray-600 text-xs'}>
          {t('resv.smsPrice')}
        </div>
      </div> */}

      <EntityUpdateDialog
        phoneCodeListLoading={phoneCodeListLoading}
        phoneList={phoneList}
        fields={[
          {
            label: t("apartmentGeneral.hostFirstNumber"),
            value: "",
            field: "voip_buddy_number",
          },
          {
            label: t("apartmentGeneral.hostSecondNumber"),
            value: "",
            field: "other_number",
          },
        ]}
        visible={updateContacts}
        onClose={() => {
          setUpdateContacts(false);
          queryClient.invalidateQueries(["apartment", data.local_key]);
        }}
        onSave={(data: any) => {
          if (
            (data.voip_buddy_number && !data.voip_buddy_number.includes("+")) ||
            (data.other_number && !data.other_number.includes("+"))
          ) {
            toast.error(t("general.selectPrefixNumber"));
            return;
          }
          updateContactMutation.mutate(data);
        }}
        title={t("apartmentGeneral.updateContacts")}
        data={data}
      />
    </div>
  );
}
