export default function ModalSection ({
  children,
  className,
  style,
} : {
  children: any,
  className?: string,
  style?:any,
}) {

  return (
    <div className={className} style={style}>
      {children}
    </div>
  )

}