import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { isBefore } from "date-fns";
import Icon from "./Icon";
import Popover from "./Popover";

type DatePickerProps = {
  value?: string | undefined;
  inputFormat: string;
  outputFormat: string;
  displayFormat: string;
  size?: string;
  onChange: (value: string | { from: Date; to: Date }) => void;
  label?: string;
};

export default function DateRangePicker({
  value,
  label,
  inputFormat,
  outputFormat,
  displayFormat,
  size,
  onChange,
}: DatePickerProps) {
  const [date, setDate] = useState<any>();
  const [start, setStart] = useState(false);

  const [searchStartDate, setSearchStartDate] = useState<Date | undefined>(
    dayjs().subtract(1, 'days').toDate()
  );
  const [searchEndDate, setSearchEndDate] = useState<Date | undefined>(
    dayjs().toDate()
  );
  // const [enteredTo, setEnteredTo] = useState<Date | undefined>()

  function isSelectingFirstDay(
    from: Date | undefined,
    to: Date | undefined,
    day: Date
  ) {
    const isBeforeFirstDay = from && isBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }

  function handleDayClick(day: Date) {
    if (
      searchStartDate &&
      searchEndDate &&
      day >= searchStartDate &&
      day <= searchEndDate
    ) {
      setSearchStartDate(undefined);
      setSearchEndDate(undefined);
      return;
    }
    if (isSelectingFirstDay(searchStartDate, searchEndDate, day)) {
      setSearchStartDate(day);
      setSearchEndDate(undefined);
      // setEnteredTo(undefined)
    } else {
      setSearchEndDate(day);
      // setEnteredTo(day)
    }
  }

  // function handleDayMouseEnter(day: Date) {
  //   if (searchStartDate && searchEndDate && !isSelectingFirstDay(searchStartDate, searchEndDate, day)) {
  //     setEnteredTo(day)
  //   }
  // }

  useEffect(() => {
    if (value) {
      setDate(value);
      setSearchStartDate(dayjs(value?.substring(0, 10)).toDate());
      setSearchEndDate(dayjs(value?.substring(15, 26)).toDate());
    }
  }, []);

  useEffect(() => {
    if (searchStartDate && searchEndDate && new Date(searchStartDate).toString() !== 'Invalid Date'&& new Date(searchEndDate).toString() !== 'Invalid Date') {
      onChange({
        from: searchStartDate,
        to: searchEndDate,
      });
      setDate(
        dayjs(searchStartDate).format("DD-MM-YYYY") +
          " -> " +
          dayjs(searchEndDate).format("DD-MM-YYYY")
      );
    }
  }, [searchStartDate, searchEndDate]);

  return (
    <div className={" w-full"}>
      <Popover
        className={
          "w-full cursor-pointer h-full flex items-center justify-center"
        }
        renderContent={() => (
          <>
            <DayPicker
              modifiers={{
                start: searchStartDate || new Date(),
                end: searchEndDate || new Date(),
              }}
              className={"w-full Selectable "}
              onDayClick={handleDayClick}
              mode="range"
              selected={{ from: searchStartDate, to: searchEndDate }}
              weekStartsOn={1}
            />

            <div className="px-4 py-2 font-medium text-center ">
              <button
                onClick={() => {
                  setSearchStartDate(undefined);
                  setSearchEndDate(undefined);
                }}
              >
                Reset
              </button>
            </div>
          </>
        )}
      >
        <div
          className="w-full bg-white rounded-2xl appearance-none px-3 items-center py-1 focus:outline-none h-9 flex items-center cursor-pointer hover:bg-gray-100 space-x-2 text-sm"
          style={{
            boxShadow:
              "0px 2px 3px rgba(0,0,0,0.1), 0px 0px 0px 1px rgba(0,0,0,0.1)",
          }}
        >
          <div>
            <Icon name={"calendar"} size={"20px"} />
          </div>
          <div>{label ? label : date}</div>
        </div>
      </Popover>
    </div>
  );
}
