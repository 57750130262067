import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  fetchAllModules,
  fetchApartmentSettings,
  fetchContracts,
  fetchCustomCheckins,
  fetchCustomEmails,
  fetchDirectLocals,
  fetchPhoneCodeList,
  storeReservation,
} from "../../shared/queries";
import reservationSchema from "../../shared/schema/reservationSchema";
import { moduleId } from "../../shared/utils/config";
import { times } from "../../shared/utils/formatting";
import { BaseSelectItem, Local } from "../../types";
import Button from "../Button";
import Checkbox from "../Checkbox";
import DatePicker from "../DatePicker";
import FormField from "../FormField";
import FormSectionTitle from "../FormSectionTitle";
import LanguageSelector from "../LanguageSelector";
import PageTitle from "../PageTitle";
import PageWrapper from "../PageWrapper";
import SelectInput from "../SelectInput";
import TextInput from "../TextInput";
import PreviousPage from "../PreviousPage";

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export default function AddReservation() {
  const { t } = useTranslation();
  const query = useQueryParams();
  const history = useHistory();

  const [local, setLocal] = useState<Local | undefined>(undefined);
  const [guestName, setGuestName] = useState<string>("");
  const [guestPhone, setGuestPhone] = useState<string>("");
  const [guestEmail, setGuestEmail] = useState<string>("");
  const [language, setLanguage] = useState<string>("");
  const [email, setEmail] = useState<{
    label: string;
    value: number | undefined;
  }>({
    label: "Vikey Default",
    value: 0,
  });
  const [checkin, setCheckin] = useState<{
    label: string;
    value: number | undefined;
  }>({
    label: "Vikey Default",
    value: 0,
  });
  const [total, setTotal] = useState<number>(0);
  const [checkInDate, setCheckInDate] = useState<string>("");
  const [checkOutDate, setCheckOutDate] = useState<string>("");
  const [checkInTime, setCheckInTime] = useState<string>("15:00");
  const [checkInAllDay, setCheckInAllDay] = useState(false);
  const [checkOutAllDay, setCheckOutAllDay] = useState(false);
  const [addResviCal, setAddResviCal] = useState({
    display: false,
    active: false,
    disableButton: true,
  });
  const [addChannel, setAddChannel] = useState({
    display: false,
    active: true,
    disableButton: true,
  });
  const [checkOutTime, setCheckOutTime] = useState<string>("10:00");
  const [sendSms, setSendSms] = useState<boolean>(false);
  const [sendWhatsapp, setSendWhatsapp] = useState<boolean>(false);
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [ccEmail, setCcEmail] = useState<boolean>(false);
  const [guestActive, setGuestActive] = useState<boolean>(false);
  const [payStay, setPayStay] = useState<boolean>(false);
  const [depositPay, setDeposit] = useState<boolean>(false);
  const [taxPay, setTaxPay] = useState<boolean>(false);
  const [mandatoryPayment, setMandatoryPayment] = useState<boolean>(false);
  const [paymentDescription, setPaymentDescription] = useState<string>();
  const [documents, setDocuments] = useState<boolean>(false);
  const [sign, setSign] = useState<boolean>(false);
  const [billing, setBilling] = useState<boolean>(false);
  const [chargeTax, setChargeTax] = useState<boolean>(false);
  const [chargeStay, setChargeStay] = useState<boolean>(false);
  const [chargeDeposit, setChargeDeposit] = useState<boolean>(false);
  const [guestsCount, setGuestsCount] = useState<number>(1);
  const [depositAmount, setDepositValue] = useState<number>(0);
  const [askGuests, setAskGuests] = useState<boolean>(false);
  const [askForPhoto, setAskForPhoto] = useState<boolean>(false);
  const [contract, setContract] = useState<{
    label: string;
    value: number | undefined;
  }>();
  const [askForAllPhoto, setAskForAllPhoto] = useState<boolean>(false);
  const [activeVerifyDocs, setActiveVerifyDocs] = useState<boolean>(false);
  const [askOtp, setAskOtp] = useState<boolean>(false);
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const [notifyNow, setNotifyNow] = useState<boolean>(false);
  const [notify1Day, setNotify1Day] = useState<boolean>(false);
  const [notify3Day, setNotify3Day] = useState<boolean>(false);
  const [notify7Day, setNotify7Day] = useState<boolean>(false);
  const [notifyDone1Day, setNotifyDone1Day] = useState<boolean>(false);

  const [validationError, setValidationError] = useState<any>(null);

  const [custom1, setCustom1] = useState<any>(null);
  const [custom2, setCustom2] = useState<any>(null);
  const [custom3, setCustom3] = useState<any>(null);
  const [custom4, setCustom4] = useState<any>(null);

  const [activeContract, setActiveContract] = useState<any>(false);
  const [activeCityTax, setActiveCityTax] = useState<any>(true);
  const [activeDeposit, setActiveDeposit] = useState<any>(false);
  const [sendSmsDone, setSendSmsDone] = useState(false);
  const [sendWhatsappDone, setSendWhatsappDone] = useState(false);
  const [phoneList, setPhoneList] = useState<any>([]);
  const [prefixNumber, setPrefixNumber] = useState<any>();

  const { data: locals, isLoading: isLoadingLocals } = useQuery(
    "localsList",
    fetchDirectLocals
  );

  const { data: emails, isLoading: isLoadingEmails } = useQuery(
    ["emails", local],
    () => fetchCustomEmails(undefined, true),
    {
      enabled: local !== null,
    }
  );

  const { data: contracts, isLoading: isLoadingContracts } = useQuery(
    ["contracts", local],
    () => fetchContracts(local?.local_key, true),
    {
      enabled: local !== null,
    }
  );

  const { data: checkins, isLoading: isLoadingCheckins } = useQuery(
    ["checkins", local],
    () => fetchCustomCheckins(),
    {
      enabled: local !== null,
    }
  );

  const { data: dataModules } = useQuery<any>([local?.local_key], () => {
    if (local?.local_key) {
      return fetchAllModules(local?.local_key).then((data: any) => {
        const moduleFinded = data?.modules?.find(
          (module: any) => module?.id === moduleId.contract
        );
        if (moduleFinded && moduleFinded.active) {
          setActiveContract(true);
        } else {
          setActiveContract(false);
        }

        const moduleTax = data?.modules?.find(
          (module: any) => module?.id === moduleId.city_tax
        );
        if (moduleTax && moduleTax.active) {
          setActiveCityTax(false);
        } else {
          setActiveCityTax(true);
        }

        setActiveDeposit(false);
        const moduleDeposit = data?.modules?.find(
          (module: any) => module?.id === moduleId.deposit
        );
        if (moduleDeposit && moduleDeposit.active) {
          setActiveDeposit(false);
        } else {
          setActiveDeposit(true);
        }

        const moduleCalendar = data?.modules?.find(
          (module: any) => module?.id === moduleId.calendar
        );
        if (moduleCalendar) {
          if (moduleCalendar.active) {
            setAddResviCal({
              display: true,
              active: true,
              disableButton: false,
            });
          } else {
            setAddResviCal({
              display: true,
              active: false,
              disableButton: true,
            });
          }
        } else {
          setAddResviCal({
            display: false,
            active: false,
            disableButton: true,
          });
        }
        const moduleChannel = data?.modules?.find(
          (module: any) => module?.id === moduleId.channel_manager
        );
        if (moduleChannel) {
          if (moduleChannel.active) {
            setAddChannel({
              display: true,
              active: true,
              disableButton: false,
            });
          } else {
            setAddChannel({
              display: true,
              active: true,
              disableButton: true,
            });
          }
        } else {
          setAddChannel({
            display: false,
            active: true,
            disableButton: true,
          });
        }
        return data;
      });
    }
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: settingsDef } = useQuery<any>(
    ["settingsDef", local?.local_key],
    () => fetchApartmentSettings(local?.local_key as string),
    {
      enabled: local?.local_key !== null && local?.local_key !== undefined,
      onSuccess: (data) => {
        const result = data.result;
        setDocuments(result.chk_documents);
        setSendEmail(result.chk_mail);
        setCcEmail(result.chk_cc);
        setGuestActive(result.notify_mail_guest_active);
        setNotifyNow(result.notify_now);
        setNotify1Day(result.notify_1days);
        setNotify3Day(result.notify_3days);
        setNotify7Day(result.notify_7days);
        setNotifyDone1Day(result.notify_done_1days);
        setSign(result.chk_contract);
        setTaxPay(result.chk_citytax);
        setChargeStay(result.chk_paystay_chargeguest);
        setChargeTax(result.chk_citytax_chargeguest);
        setPayStay(result.chk_paystay);
        setDeposit(result.chk_deposit);
        setDepositValue(result.deposit_amount);
        setChargeDeposit(result.chk_deposit_chargeguest);
        setAskOtp(result.chk_otp);
        setAskForPhoto(result.chk_photo);
        setAskForAllPhoto(result.chk_photo_all);
        setActiveVerifyDocs(result.docs_auto_confirmation);
        setCheckin(result.cust_checkin_ptr);
        setEmail(result.cust_email_ptr);
        setContract(result.cust_contract_ptr);
        setAskGuests(result.chk_asknumguests);
        setSendSms(result.chk_sms);
        setSendWhatsapp(result.chk_whatsapp);
        setSendSmsDone(result.chk_sms_done);
        setSendWhatsappDone(result.chk_whatsapp_done);
        setBilling(result.require_invoice);
      },
    }
  );

  const { isLoading: phoneCodeListLoading } = useQuery<any>(
    ["phoneCodeList"],
    () => fetchPhoneCodeList(),
    {
      onSuccess: (data) => {
        const states = Object.keys(data.results).map((d) => {
          return {
            label: `${d} (${data.results[d]})`,
            value: data.results[d],
          };
        });
        setPhoneList(states);
        return data;
      },
    }
  );
  /**
   * Set the current local reading the querystring if there is a value
   */
  useEffect(() => {
    if (locals && query.get("local")) {
      setLocal(locals.results.find((l) => l.local_key === query.get("local")));
    }
  }, [locals]);

  useEffect(() => {
    if (!local?.stripe_account) {
      setPayStay(false);
      setDeposit(false);
    }

    if (!local?.stripe_account || !local.city_tax_params) {
      setTaxPay(false);
    }
  }, [local]);
  /**
   * Set default check-in and check-out date to today and tomorrow
   */
  useEffect(() => {
    const fromDate = query.get("from");
    const toDate = query.get("to");
    if (fromDate && !toDate && new Date(fromDate).toString() !== 'Invalid Date') {
      setCheckInDate(dayjs(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD HH:mm"));
      setCheckOutDate(
        dayjs(fromDate, "DD-MM-YYYY").add(1, "day").format("YYYY-MM-DD HH:mm")
      );
    } else if (fromDate && toDate && new Date(fromDate).toString() !== 'Invalid Date' && new Date(toDate).toString() !== 'Invalid Date') {
      setCheckInDate(dayjs(fromDate).format("YYYY-MM-DD HH:mm"));
      setCheckOutDate(dayjs(toDate).format("YYYY-MM-DD HH:mm"));
    }
  }, []);

  const mutation = useMutation((data: any) => storeReservation(data), {
    onSuccess: (data) => {
      toast.success(t(data.msg ? data.msg : "general.requestCompleted"));
      if (data && data.resv_key) {
        history.push("/reservations/" + data.resv_key);
      }
    },
  });

  const onCheckInAllDay = () => {
    setCheckInAllDay(!checkInAllDay);
    setCheckInTime("00:00");
  };

  const onCheckOutAllDay = () => {
    setCheckOutAllDay(!checkOutAllDay);
    setCheckOutTime("23:59");
  };

  function handleSubmit() {
    const checkInParts = checkInTime.split(":");
    const checkOutParts = checkOutTime.split(":");
    var temp_checkInDate = checkInDate;
    var temp_checkOutDate = checkOutDate;

    if (checkInParts.length > 1 && checkInDate) {
      temp_checkInDate = dayjs(checkInDate)
        .hour(parseInt(checkInParts[0]))
        .minute(parseInt(checkInParts[1]))
        .format("YYYY-MM-DD HH:mm:ss");
    }
    if (checkOutParts.length > 1 && checkOutDate) {
      temp_checkOutDate = dayjs(checkOutDate)
        .hour(parseInt(checkOutParts[0]))
        .minute(parseInt(checkOutParts[1]))
        .format("YYYY-MM-DD HH:mm:ss");
    }

    const data = {
      local_key: local ? local.local_key : null,
      lang: language ? language : null,
      name: guestName,
      chk_documents: documents ? 1 : 0,
      guests_num: isNaN(guestsCount) ? 1 : guestsCount,
      chk_mail: sendEmail ? 1 : 0,
      chk_cc: ccEmail ? 1 : 0,
      notify_mail_guest_active: guestActive ? 1 : 0,
      notify_now: notifyNow ? 1 : 0,
      notify_7days: notify7Day ? 1 : 0,
      notify_3days: notify3Day ? 1 : 0,
      notify_1days: notify1Day ? 1 : 0,
      notify_done_1days: notifyDone1Day ? 1 : 0,
      guest_email: guestEmail,
      chk_payments: mandatoryPayment ? 1 : 0,
      chk_contract: sign ? 1 : 0,
      chk_citytax: taxPay ? 1 : 0,
      chk_paystay_chargeguest: chargeStay ? 1 : 0,
      chk_citytax_chargeguest: chargeTax ? 1 : 0,
      chk_paystay: payStay ? 1 : 0,
      chk_deposit_chargeguest: chargeDeposit ? 1 : 0,
      chk_deposit: depositPay ? 1 : 0,
      deposit_amount: isNaN(depositAmount) ? 0 : depositAmount,
      chk_otp: askOtp ? 1 : 0,
      custom1: sign ? custom1 : null,
      custom2: sign ? custom2 : null,
      custom3: sign ? custom3 : null,
      custom4: sign ? custom4 : null,
      chk_photo: askForPhoto ? 1 : 0,
      chk_photo_all: askForAllPhoto ? 1 : 0,
      chk_docs_auto_confirmation: activeVerifyDocs ? 1 : 0,
      price: total,
      cust_checkin_ptr: checkin ? checkin.value : 0,
      cust_email_ptr: email ? email.value : 0,
      cust_contract_ptr: contract ? contract.value : 0,
      guest_phone: guestPhone,
      chk_asknumguests: askGuests ? 1 : 0,
      chk_sms: sendSms ? 1 : 0,
      chk_whatsapp: sendWhatsapp ? 1 : 0,
      chk_sms_done: sendSmsDone ? 1 : 0,
      chk_whatsapp_done: sendWhatsappDone ? 1 : 0,
      date_from: temp_checkInDate,
      date_to: temp_checkOutDate,
      payment_text: paymentDescription,
      payment_amount: paymentAmount,
      require_invoice: billing ? 1 : 0,
      flag_cm_add_calendar: addResviCal.active,
      channex_set_availability: addChannel.active,
    };

    if (data.guest_phone.length > 0 && !data.guest_phone.includes("+")) {
      toast.error(t("general.selectPrefixNumber"));
    } else {
      const { error, value } = reservationSchema.validate(data);
      if (error) {
        setValidationError(error);
        toast.error(t("general.invalidFields"));
      } else {
        setValidationError(null);
        mutation.mutate(data);
      }
    }
  }

  return (
    <PageWrapper>
      <PreviousPage
        className={"mb-4"}
        label={t("general.goBack")}
      ></PreviousPage>
      <PageTitle title={t("resv.newReservation")} />

      <div className={"space-y-4"}>
        <FormField
          label={t("resv.apartment")}
          errors={validationError}
          errorKey={"local_key"}
        >
          <SelectInput<BaseSelectItem, false>
            isLoading={isLoadingLocals}
            value={
              local
                ? {
                    value: local.local_key,
                    label: local.name,
                  }
                : null
            }
            placeholder={t("resv.apartmentConnectedToRes")}
            options={locals?.results.map((l) => {
              return {
                label: l.name,
                value: l.local_key,
              };
            })}
            onChange={(item) => {
              if (item && locals)
                setLocal(
                  locals.results.find((l) => l.local_key === item.value)
                );
            }}
          />
        </FormField>

        <div className="flex space-x-6">
          <div className="w-1/2">
            <FormField
              errors={validationError}
              errorKey={"name"}
              label={t("resv.guestName")}
            >
              <TextInput
                value={guestName}
                onChange={(value) => setGuestName(value)}
              ></TextInput>
            </FormField>
          </div>
          <div className="w-1/2">
            <FormField
              errors={validationError}
              errorKey={"lang"}
              label={t("resv.guestLanguage")}
            >
              <LanguageSelector
                onChange={(item) => {
                  if (item) setLanguage(item);
                }}
              ></LanguageSelector>
            </FormField>
          </div>
        </div>

        <div className="md:flex md:space-x-6">
          <div className="md:w-1/2 w-full">
            <FormField
              errors={validationError}
              errorKey={"guest_email"}
              label={t("resv.email")}
            >
              <TextInput
                onChange={(val) => setGuestEmail(val)}
                value={guestEmail}
                type={"text"}
              ></TextInput>
            </FormField>
          </div>
          <div className="md:w-1/2 w-full flex gap-5 md:mt-0 mt-5">
            <FormField label={t("resv.prefixNumber")} className="w-full">
              <SelectInput<BaseSelectItem, false>
                isLoading={phoneCodeListLoading}
                placeholder={t("selector.selectOption")}
                value={prefixNumber}
                options={phoneList}
                onChange={(item) => {
                  if (item) {
                    setPrefixNumber(item);
                    setGuestPhone(() => {
                      return item.value;
                    });
                  }
                }}
              />
            </FormField>
            <FormField
              className="w-full"
              errors={validationError}
              errorKey={"guest_phone"}
              label={t("resv.phone")}
            >
              <TextInput
                onChange={(val) => {
                  if (val.length < 2) {
                    setPrefixNumber(null);
                  }
                  setGuestPhone(val);
                }}
                value={guestPhone}
                type={"text"}
              ></TextInput>
            </FormField>
          </div>
        </div>

        <FormField
          errors={validationError}
          errorKey={"price"}
          label={t("resv.reservationTotal")}
        >
          <TextInput
            numbered={true}
            value={total}
            onChange={(val) => setTotal(val)}
            type={"number"}
          ></TextInput>
        </FormField>
      </div>
      {addResviCal.display && (
        <div className="my-6">
          <Checkbox
            className=" max-w-max"
            disabled={addResviCal.disableButton}
            label={t("apartmentCalendar.addCalendarResv")}
            checked={addResviCal.active}
            onChange={() =>
              setAddResviCal((prev) => {
                return {
                  display: true,
                  active: !prev.active,
                  disableButton: false,
                };
              })
            }
          />
        </div>
      )}
      {addChannel.display && (
        <div className="my-6">
          <Checkbox
            className=" max-w-max"
            disabled={addChannel.disableButton}
            label={t("apartmentCalendar.addChannel")}
            checked={addChannel.active}
            onChange={() =>
              setAddChannel((prev) => {
                return {
                  display: true,
                  active: !prev.active,
                  disableButton: false,
                };
              })
            }
          />
        </div>
      )}
      <FormSectionTitle title={"Check-in & Check-out"}></FormSectionTitle>

      <div className={"flex space-x-6"}>
        <div className="w-1/2 space-y-4">
          <FormField
            errors={validationError}
            errorKey={"date_from"}
            label={t("resv.checkInDate")}
          >
            <DatePicker
              value={checkInDate}
              // min={dayjs().toDate()}
              inputFormat={"YYYY-MM-DD HH:mm"}
              outputFormat={"YYYY-MM-DD HH:mm:ss"}
              displayFormat={"DD-MM-YYYY"}
              onChange={(value) => setCheckInDate(value)}
            ></DatePicker>
          </FormField>

          <FormField label={t("resv.checkInTime")}>
            <SelectInput<BaseSelectItem, false>
              value={
                checkInTime
                  ? {
                      label: checkInTime,
                      value: checkInTime,
                    }
                  : null
              }
              onChange={(item) => {
                if (item) setCheckInTime(item.value);
              }}
              options={times}
              isDisabled={checkInAllDay}
            />
          </FormField>
          <Checkbox
            className=" max-w-max"
            label={t("resv.allDay")}
            hint={t("resv.allDayHint")}
            checked={checkInAllDay}
            onChange={() => onCheckInAllDay()}
          ></Checkbox>
        </div>

        <div className="w-1/2 space-y-4">
          <FormField
            label={t("resv.checkOutDate")}
            errors={validationError}
            errorKey={"date_to"}
          >
            <DatePicker
              min={dayjs(checkInDate, "YYYY-MM-DD").toDate()}
              value={checkOutDate}
              inputFormat={"YYYY-MM-DD HH:mm"}
              outputFormat={"YYYY-MM-DD HH:mm:ss"}
              displayFormat={"DD-MM-YYYY"}
              onChange={(value) => setCheckOutDate(value)}
            ></DatePicker>
          </FormField>

          <FormField label={t("resv.checkOutTime")}>
            <SelectInput<BaseSelectItem, false>
              value={
                checkOutTime
                  ? {
                      label: checkOutTime,
                      value: checkOutTime,
                    }
                  : null
              }
              onChange={(item) => {
                if (item) setCheckOutTime(item.value);
              }}
              options={times}
              isDisabled={checkOutAllDay}
            />
          </FormField>
          <Checkbox
            className=" max-w-max"
            label={t("resv.allDay")}
            hint={t("resv.allDayHint")}
            checked={checkOutAllDay}
            onChange={() => onCheckOutAllDay()}
          ></Checkbox>
        </div>
      </div>

      <FormSectionTitle title={t("resv.communication")}></FormSectionTitle>

      <div className={"mt-6 space-y-2"}>
        {dataModules?.modules?.map((module: any) => {
          if (module.id === moduleId.sms) {
            return (
              <Checkbox
                className=" max-w-max"
                label={`${t("resv.sendRemindSMS")} ${
                  module.date_closed
                    ? `(${t("resv.activeUntil").toLowerCase()} ${dayjs(
                        module.date_closed
                      ).format("DD-MM-YYYY")})`
                    : ""
                }`}
                disabled={!module.active && local && local.sms_enabled === 0}
                onChange={() => setSendSms(!sendSms)}
                checked={sendSms}
              />
            );
          }
          return null;
        })}
        {dataModules?.modules?.map((module: any) => {
          if (module.id === moduleId.whatsapp) {
            return (
              <Checkbox
                className=" max-w-max"
                label={`${t("resv.sendRemindWHATSAPP")} ${
                  module.date_closed
                    ? `(${t("resv.activeUntil").toLowerCase()} ${dayjs(
                        module.date_closed
                      ).format("DD-MM-YYYY")})`
                    : ""
                }`}
                disabled={!module.active}
                onChange={() => setSendWhatsapp(!sendWhatsapp)}
                checked={sendWhatsapp}
              />
            );
          }
          return null;
        })}
        {dataModules?.modules?.map((module: any) => {
          if (module.id === moduleId.sms_done) {
            return (
              <Checkbox
                className=" max-w-max"
                label={`${t("resv.sendRemindSMSdone")} ${
                  module.date_closed
                    ? `(${t("resv.activeUntil").toLowerCase()} ${dayjs(
                        module.date_closed
                      ).format("DD-MM-YYYY")})`
                    : ""
                }`}
                disabled={!module.active}
                onChange={() => setSendSmsDone(!sendSmsDone)}
                checked={sendSmsDone}
              />
            );
          }
          return null;
        })}
        {dataModules?.modules?.map((module: any) => {
          if (module.id === moduleId.whatsapp_done) {
            return (
              <Checkbox
                className=" max-w-max"
                label={`${t("resv.sendRemindWHATSAPPdone")} ${
                  module.date_closed
                    ? `(${t("resv.activeUntil").toLowerCase()} ${dayjs(
                        module.date_closed
                      ).format("DD-MM-YYYY")})`
                    : ""
                }`}
                disabled={!module.active}
                onChange={() => setSendWhatsappDone(!sendWhatsappDone)}
                checked={sendWhatsappDone}
              />
            );
          }
          return null;
        })}
        <Checkbox
          className=" max-w-max"
          onChange={() => setSendEmail(!sendEmail)}
          checked={sendEmail}
          label={t("resv.enableEmail")}
        ></Checkbox>

        {sendEmail && (
          <div className="space-y-4 ml-10 mt-4 mb-4 p-4 border border-gray-300 shadow-inner rounded-2xl  bg-white">
            <FormField label={t("resv.emailToBeSent")}>
              <SelectInput<BaseSelectItem, false>
                value={email}
                onChange={(item) => {
                  if (item) setEmail(item);
                }}
                placeholder={"Scegli una mail"}
                isLoading={isLoadingEmails}
                options={
                  emails?.results
                    ? [
                        ...emails.results.map((c) => {
                          return {
                            label: c.objkey,
                            value: c.id,
                          };
                        }),
                        {
                          label: "Vikey default",
                          value: undefined,
                        },
                      ]
                    : []
                }
              />
            </FormField>

            <Checkbox
              className=" max-w-max"
              label={t("resv.createNotification")}
              checked={notifyNow}
              onChange={() => setNotifyNow(!notifyNow)}
            />

            <Checkbox
              className=" max-w-max"
              label={t("resv.oneDayNotification")}
              checked={notify1Day}
              onChange={() => setNotify1Day(!notify1Day)}
            />

            <Checkbox
              className=" max-w-max"
              label={t("resv.threeDayNotification")}
              checked={notify3Day}
              onChange={() => setNotify3Day(!notify3Day)}
            />

            <Checkbox
              className=" max-w-max"
              label={t("resv.sevenDayNotification")}
              checked={notify7Day}
              onChange={() => setNotify7Day(!notify7Day)}
            />

            <Checkbox
              className=" max-w-max"
              label={t("integration.completeNotification")}
              checked={notifyDone1Day}
              onChange={() => setNotifyDone1Day(!notifyDone1Day)}
            />
          </div>
        )}

        <Checkbox
          className=" max-w-max"
          onChange={() => setCcEmail(!ccEmail)}
          checked={ccEmail}
          label={t("resv.enableCCEmail")}
        ></Checkbox>

        <Checkbox
          className=" max-w-max"
          onChange={() => setGuestActive(!guestActive)}
          checked={guestActive}
          label={t("resv.enableGuestActive")}
        ></Checkbox>
      </div>

      <FormSectionTitle title={"Check-in online"}></FormSectionTitle>

      <div className={"mt-6 space-y-2"}>
        <FormField
          label={t("resv.checkin")}
          errors={validationError}
          errorKey={"local_key"}
        >
          <SelectInput<BaseSelectItem, false>
            isLoading={isLoadingCheckins}
            value={checkin}
            placeholder={t("resv.checkinToSelect")}
            options={
              checkins?.results
                ? [
                    ...checkins.results.map((c) => {
                      return {
                        label: c.objkey,
                        value: c.id,
                      };
                    }),
                    {
                      label: "Vikey default",
                      value: undefined,
                    },
                  ]
                : []
            }
            onChange={(item) => {
              if (item) setCheckin(item);
            }}
          />
        </FormField>
        {local && !local.stripe_account && (
          <div className="mb-2 p-2 rounded-2xl bg-red-100 text-red-800">
            {t("resv.noStripeAccount")}
          </div>
        )}

        <Checkbox
          className=" max-w-max"
          onChange={() => setPayStay(!payStay)}
          checked={payStay}
          disabled={local && !local.stripe_account && !payStay}
          label={t("resv.requestStayPayment")}
        ></Checkbox>

        {payStay && (
          <div className="space-y-4 ml-10 mt-4 mb-4 p-4 border border-gray-300 shadow-inner rounded-2xl  bg-white">
            <FormField label={t("resv.stayTotal")}>
              <TextInput
                value={total}
                onChange={(value) => setTotal(value)}
                numbered={true}
              ></TextInput>
            </FormField>

            <Checkbox
              className=" max-w-max"
              label={t("resv.chargeGuestCommission")}
              hint={t("resv.chargeGuestStayCommissionText")}
              checked={chargeStay}
              onChange={() => setChargeStay(!chargeStay)}
            ></Checkbox>
          </div>
        )}

        {dataModules?.modules?.map((module: any) => {
          if (module.id === moduleId.deposit) {
            return (
              <>
                <Checkbox
                  className=" max-w-max"
                  onChange={() => setDeposit(!depositPay)}
                  checked={depositPay}
                  disabled={
                    (local && !local.stripe_account) ||
                    activeDeposit ||
                    !module.active
                  }
                  label={t("resv.requestDepositPayment")}
                ></Checkbox>
                {depositPay && (
                  <div className="space-y-4 ml-10 mt-4 mb-4 p-4 border border-gray-300 shadow-inner rounded-2xl  bg-white">
                    <FormField label={t("resv.depositTotal")}>
                      <TextInput
                        value={depositAmount}
                        onChange={(value) => setDepositValue(value)}
                        numbered={true}
                      ></TextInput>
                    </FormField>

                    <Checkbox
                      className=" max-w-max"
                      label={t("resv.chargeGuestCommission")}
                      hint={t("resv.chargeGuestDepositCommissionText")}
                      checked={chargeDeposit}
                      onChange={() => setChargeDeposit(!chargeDeposit)}
                    ></Checkbox>
                  </div>
                )}
              </>
            );
          }
          return null;
        })}

        {local && !local.city_tax_params && (
          <div className="mb-2 p-2 rounded-2xl bg-red-100 text-red-800">
            {t("resv.noCityTaxtParamsInLocal")}
          </div>
        )}

        <Checkbox
          className=" max-w-max"
          onChange={() => setTaxPay(!taxPay)}
          checked={taxPay}
          disabled={
            (local && (!local.stripe_account || !local.city_tax_params)) ||
            activeCityTax
          }
          label={t("resv.requestTaxPayment")}
        ></Checkbox>

        {taxPay && (
          <div className="space-y-4 ml-10 mt-4 mb-4 p-4 border border-gray-300 shadow-inner rounded-2xl  bg-white">
            <FormField
              errors={validationError}
              errorKey={"guests_num"}
              label={t("resv.guestCount")}
            >
              <TextInput
                type="number"
                value={guestsCount}
                onChange={(value) => {
                  const newValue = parseInt(value);
                  if (!isNaN(newValue)) {
                    setGuestsCount(newValue);
                  }
                  if (value === "") {
                    setGuestsCount(0);
                  }
                }}
                numbered={true}
              ></TextInput>
            </FormField>

            <Checkbox
              className=" max-w-max"
              label={t("resv.chargeGuestCommission")}
              hint={t("resv.chargeGuestTaxCommissionText")}
              checked={chargeTax}
              onChange={() => setChargeTax(!chargeTax)}
            ></Checkbox>
          </div>
        )}

        <Checkbox
          className=" max-w-max"
          onChange={() => setMandatoryPayment(!mandatoryPayment)}
          checked={mandatoryPayment}
          disabled={local && !local.stripe_account}
          label={"Pagamento obbligatorio"}
        ></Checkbox>

        {mandatoryPayment && (
          <div className="ml-10 mt-4 mb-4 p-4 border border-gray-300 shadow-inner rounded-2xl  bg-white">
            <div className="flex space-x-6">
              <div className="w-1/2">
                <FormField
                  errors={validationError}
                  errorKey={"payment_text"}
                  label={t("resv.paymentDescription")}
                >
                  <TextInput
                    value={paymentDescription}
                    onChange={(value) => setPaymentDescription(value)}
                  ></TextInput>
                </FormField>
              </div>
              <div className="w-1/2">
                <FormField
                  errors={validationError}
                  errorKey={"payment_amount"}
                  label={t("resv.paymentAmount")}
                >
                  <TextInput
                    numbered={true}
                    value={paymentAmount}
                    onChange={(value) => setPaymentAmount(value)}
                  ></TextInput>
                </FormField>
              </div>
            </div>
          </div>
        )}

        <Checkbox
          className=" max-w-max"
          onChange={() => setDocuments(!documents)}
          checked={documents}
          label={t("resv.sendDocuments")}
        ></Checkbox>

        {documents && (
          <div className="space-y-4 ml-10 mt-4 mb-4 p-4 border border-gray-300 shadow-inner rounded-2xl  bg-white">
            <FormField
              label={t("resv.guestCount")}
              errors={validationError}
              errorKey={"guests_num"}
            >
              <TextInput
                type="number"
                numbered={true}
                value={guestsCount}
                onChange={(value) => {
                  const newValue = parseInt(value);
                  if (!isNaN(newValue)) {
                    setGuestsCount(newValue);
                  }
                  if (value === "") {
                    setGuestsCount(0);
                  }
                }}
              ></TextInput>
            </FormField>

            <Checkbox
              className=" max-w-max"
              onChange={() => setAskGuests(!askGuests)}
              checked={askGuests}
              label={t("resv.requestNumberOfGuests")}
            ></Checkbox>

            <Checkbox
              className=" max-w-max"
              onChange={() => {
                setAskForPhoto((prev) => {
                  if (prev) {
                    setActiveVerifyDocs(false);
                    setAskForAllPhoto(false);
                  }
                  return !prev;
                });
              }}
              checked={askForPhoto}
              label={t("resv.requestDocumentMainGuest")}
            ></Checkbox>

            <Checkbox
              className=" max-w-max"
              onChange={() => {
                setAskForAllPhoto((prev) => {
                  if (!askForAllPhoto) {
                    setAskForPhoto(true);
                  }
                  return !prev;
                });
              }}
              checked={askForAllPhoto}
              label={t("resv.requestDocumentOtherGuests")}
            ></Checkbox>

            {dataModules?.modules?.map((module: any) => {
              if (module.id === moduleId.docs_auto_confirmation) {
                return (
                  <Checkbox
                    className=" max-w-max"
                    label={`${t("resv.activeRecognition")} ${
                      module.date_closed
                        ? `(${t("resv.activeUntil").toLowerCase()} ${dayjs(
                            module.date_closed
                          ).format("DD-MM-YYYY")})`
                        : ""
                    }`}
                    disabled={!module.active || !askForPhoto}
                    onChange={() => setActiveVerifyDocs(!activeVerifyDocs)}
                    checked={activeVerifyDocs}
                  />
                );
              }
              return null;
            })}
          </div>
        )}

        <Checkbox
          className=" max-w-max"
          disabled={
            !contracts ||
            (contracts?.results && contracts.results.length <= 0) ||
            (!activeContract && !sign)
          }
          onChange={() => setSign(!sign)}
          checked={sign}
          label={t("resv.signContract")}
        ></Checkbox>

        {sign && (
          <div className="space-y-4 ml-10 mt-4 mb-4 p-4 border border-gray-300 shadow-inner rounded-2xl  bg-white">
            <div className={"text-gray-600 font-medium"}>
              {t("resv.mandatoryContractSign")}
            </div>

            <div className={"space-y-4"}>
              <SelectInput<BaseSelectItem, false>
                placeholder={"Scegli un contratto"}
                isLoading={isLoadingContracts}
                value={contract}
                onChange={(item) => {
                  if (item) setContract(item);
                }}
                options={
                  contracts?.results
                    ? [
                        ...contracts.results.map((c) => {
                          return {
                            label: c.objkey,
                            value: c.id,
                          };
                        }),
                      ]
                    : []
                }
              />

              <Checkbox
                className={"mt-4 max-w-max"}
                checked={askOtp}
                onChange={() => setAskOtp(!askOtp)}
                label={t("reservationOnlineCheckIn.requestOTP")}
                hint={t("reservationOnlineCheckIn.requestOTPText")}
              ></Checkbox>

              {/* tag component */}
              {sign && (
                <>
                  <FormField
                    label={`${t(
                      "reservationOnlineCheckIn.customVariable"
                    )} (1) ${t(
                      "apartmentContent.custom_contract_1description"
                    )} ##Custom-1##`}
                  >
                    <TextInput
                      value={custom1}
                      onChange={(value) => setCustom1(value)}
                    ></TextInput>
                  </FormField>
                  <FormField
                    className={"mt-4"}
                    label={`${t(
                      "reservationOnlineCheckIn.customVariable"
                    )} (2) ${t(
                      "apartmentContent.custom_contract_1description"
                    )} ##Custom-2##`}
                  >
                    <TextInput
                      value={custom2}
                      onChange={(value) => setCustom2(value)}
                    ></TextInput>
                  </FormField>

                  <FormField
                    className={"mt-4"}
                    label={`${t(
                      "reservationOnlineCheckIn.customVariable"
                    )} (3) ${t(
                      "apartmentContent.custom_contract_1description"
                    )} ##Custom-3##`}
                  >
                    <TextInput
                      value={custom3}
                      onChange={(value) => setCustom3(value)}
                    ></TextInput>
                  </FormField>

                  <FormField
                    className={"mt-4"}
                    label={`${t(
                      "reservationOnlineCheckIn.customVariable"
                    )} (4) ${t(
                      "apartmentContent.custom_contract_1description"
                    )} ##Custom-4##`}
                  >
                    <TextInput
                      value={custom4}
                      onChange={(value) => setCustom4(value)}
                    ></TextInput>
                  </FormField>
                </>
              )}
            </div>
          </div>
        )}

        <Checkbox
          className=" max-w-max"
          onChange={() => setBilling(!billing)}
          checked={billing}
          label={t("resv.requestInvoice")}
        ></Checkbox>
      </div>

      <div className="mt-10 mb-10">
        <Button
          loading={mutation.isLoading}
          color={"primary"}
          label={t("general.save")}
          onClick={() => handleSubmit()}
        ></Button>
      </div>
    </PageWrapper>
  );
}
