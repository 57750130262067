import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { fetchPerms } from "../../shared/queries";
import serviceSchema from "../../shared/schema/serviceSchema";
import { Buyable } from "../../types";
import Checkbox from "../Checkbox";
import FormField from "../FormField";
import SelectInput from "../SelectInput";
import TextInput from "../TextInput";
import ServiceCats from "./ServiceCats";

type AddServiceProps = {
  onChange: (service: Buyable, valid: boolean) => void;
  isVikeyPay?: boolean;
  isFlgBkEngine?: boolean;
};

export default function AddService({
  onChange,
  isVikeyPay,
  isFlgBkEngine,
}: AddServiceProps) {
  const { t } = useTranslation();

  const [accounts, setAccounts] = useState<any[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, isLoading } = useQuery("accounts", fetchPerms, {
    onSuccess: (data) => {
      const elements = [] as any;
      if (data && data.results) {
        data.results.forEach((account) => {
          elements.push({
            label: account.o_name,
            value: account.o_user_key,
          });
        });
        setAccounts(elements);
      }
    },
  });

  const [validationError, setValidationError] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validationValue, setValidationValue] = useState<any>();

  const [service, setService] = useState<Buyable>({
    user_key: "",
    amount_tot: "0",
    id: "new",
    type: "SINGLE",
    mandatory: false,
    notifemails: "emails",
    title: "",
    cats: "",
    chargeguest: false,
    email_to_guest: false,
  });

  useEffect(() => {
    const { error, value } = serviceSchema.validate(service);
    setValidationError(error);
    setValidationValue(value);
    onChange(service, error === undefined);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);

  return (
    <div className={"space-y-8"}>
      <FormField
        errors={validationError}
        errorKey={"cats"}
        label={t("addService.categories")}
      >
        <ServiceCats
          onChange={(categories) => {
            setService({
              ...service,
              cats: JSON.stringify(categories),
            });
          }}
          data={service.cats ? service.cats : ""}
        />
      </FormField>

      <FormField
        errorKey={"user_key"}
        errors={validationError}
        label={"Account"}
      >
        <SelectInput
          onChange={(item) => {
            if (item)
              setService({
                ...service,
                user_key: item.value,
              });
          }}
          options={accounts}
        />
      </FormField>

      <FormField
        label={t("addService.title")}
        errors={validationError}
        errorKey={"title"}
      >
        <TextInput
          type={"text"}
          value={service.title}
          onChange={(val) =>
            setService({
              ...service,
              title: val,
            })
          }
        ></TextInput>
      </FormField>

      <FormField
        label={t("addService.cost")}
        errors={validationError}
        errorKey={"amount_tot"}
      >
        <TextInput
          numbered
          min={0}
          value={service.amount_tot}
          onChange={(val) =>
            setService({
              ...service,
              amount_tot: val,
            })
          }
        ></TextInput>
      </FormField>

      {isFlgBkEngine ? (
        <></>
      ) : (
        <FormField
          label={t("addService.emailToNotify")}
          tooltip={t("addService.YouWillStillReceiveEmail")}
          errors={validationError}
          errorKey={"title"}
        >
          <TextInput
            type={"text"}
            value={service.notifemails}
            onChange={(val) =>
              setService({
                ...service,
                notifemails: val,
              })
            }
          ></TextInput>
        </FormField>
      )}

      {isFlgBkEngine ? (
        <></>
      ) : (
        <Checkbox
          checked={service.mandatory ?? false}
          onChange={() => {
            setService({
              ...service,
              mandatory: service.mandatory ? false : true,
            });
          }}
          label={t("addService.mandatory")}
        />
      )}

      {isFlgBkEngine ? (
        <></>
      ) : (
        <Checkbox
          checked={service.type === "MULTI"}
          onChange={() => {
            setService({
              ...service,
              type: service.type === "MULTI" ? "SINGLE" : "MULTI",
            });
          }}
          label={t("addService.enablePurchase")}
          hint={t("addService.enablePurchaseText")}
        />
      )}
      {isVikeyPay ? (
        <>
          <Checkbox
            checked={service?.chargeguest ?? false}
            onChange={() => {
              setService({
                ...service,
                chargeguest: !service.chargeguest,
              });
            }}
            label={t("resv.chargeGuestCommission")}
          />
          <Checkbox
            checked={service?.email_to_guest ?? false}
            onChange={() => {
              setService({
                ...service,
                email_to_guest: !service.email_to_guest,
              });
            }}
            label={t("resv.requestPaymentNow")}
          />
        </>
      ) : null}
    </div>
  );
}
