import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../components/Button';
import Checkbox from '../components/Checkbox';
import DeleteDialog from '../components/DeleteDialog';
import FormSectionTitle from '../components/FormSectionTitle';
import Icon from '../components/Icon';
import LanguageSelector from '../components/LanguageSelector';
import PageWrapper from '../components/PageWrapper';
import ManageApartments from '../components/service/ManageApartments';
import ServiceCats from '../components/service/ServiceCats';
import ServiceContent from '../components/service/ServiceContent';
import ServicePreview from '../components/service/ServicePreview';
import ServiceQuestionItem from '../components/service/ServiceQuestionItem';
import UpdateServiceGeneral from '../components/service/UpdateServiceGeneral';
// import useBreadcrumbs from '../hooks/useBreadcrumbs';
import {
  deleteReservationService,
  fetchConnectedServiceApartments,
  fetchServiceContent,
  updateService,
  updateServiceData,
} from '../shared/queries';
import {
  Buyable,
  ListApiResponse,
  ListApiResponseData,
  LocalService,
} from '../types';

type ServiceViewParams = {
  id: string | undefined;
};

export default function ServiceView() {
  const { t } = useTranslation();

  const history = useHistory();
  const { id } = useParams<ServiceViewParams>();
  const [service, setService] = useState<Buyable>();
  const [updateServiceContent, setUpdateServiceContent] =
    useState<boolean>(false);
  const [updateGeneral, setUpdateGeneral] = useState<boolean>(false);
  const [updateConnectedApartments, setUpdateConnectedApartments] =
    useState<boolean>(false);
  const [currentLang, setCurrentLang] = useState<
    'de' | 'en' | 'es' | 'fr' | 'it' | 'pt' | 'ru' | 'zh'
  >('it');
  const [showDelete, setShowDelete] = useState(false);

  
  const { data, refetch } = useQuery<any>(
    ['service', id],
    () => fetchServiceContent(id),
    {
      onSuccess: (data) => {
        const buyable = data.results;
        setService(buyable);
        setCurrentLang('it');
      },

    }
  );

  const { data: apartments, refetch: refetchApartments } = useQuery<ListApiResponse<LocalService>, Error>(
    'connectedApartments',
    () => fetchConnectedServiceApartments(id)
  );

  const handleSuccessMutation = (data: any) => {
    setService((prev) => {return {...prev, amount_tot: data?.results?.amount_tot }}) 
  }

  const mutation = useMutation(
    (props: Buyable) =>
      updateService(service?.id || 0, {
        ...props,
      }),
    {
      onSuccess: () => {
        toast.success(t('general.updated'));
        refetch();
      },
    }
  );

  const updateMutation = useMutation(
    (props: any) =>
      updateServiceData({
        ...props,
      }),
    {
      onSuccess: () => {
        toast.success(t('general.updated'));
        setUpdateServiceContent(false);
        refetch();
      },
    }
  );

  const deleteMutation = useMutation(() => deleteReservationService(id), {
    onSuccess: () => {
      // onClose()
      setShowDelete(false);
      toast.success(t('general.operationCompleted'));
      history.goBack();
    },
    onError: () => {
      toast.error(t('general.requestError'));
    },
  }); 


  useEffect(() => {
    if (
      service &&
      currentLang &&
      service.langs &&
      !service.langs[currentLang]
    ) {
      // generate language
      setService({
        ...service,
        langs: {
          ...service.langs,
          [currentLang]: {
            DEL: 0,
            body: '',
            selvals: [],
            extras: '[]',
            lang: currentLang,
            title: '',
          },
        },
      });
    }
  }, [currentLang, data]);

  /* useBreadcrumbs([
    { label: t('services.services'), route: '/services' },
    {
      label: service && service.title ? service.title : '',
      route:
        '/services/' + (service && service.id ? service.id?.toString() : '#'),
    },
  ]); */

  return (
    <PageWrapper>
      <div className={'mb-8'}>
        <button
          onClick={() => history.goBack()}
          className={
            'flex items-center hover:text-gray-800 text-gray-500 cursor-pointer text-sm'
          }
        >
          <Icon
            name={'arrow-narrow-left'}
            className={'mr-2'}
            size={'20px'}
          ></Icon>{' '}
          {t('general.backToList')}
        </button>
        <div className={'mt-8 mb-2'}>
          <div className="flex w-full justify-between">
            <div className={'flex items-center'}>
              <div className={'text-2xl font-bold'}>
                <span className={'text-disambuigation'}>{service?.title} {service?.flg_bkengine && '(Booking Engine)'}</span>
              </div>
            </div>
            <div>
              <Button
                onClick={() => setShowDelete(true)}
                color={'negative'}
                label={t('general.delete')}
                flat
              />
            </div>
          </div>

          <div className={'flex justify-between items-center'}>
            <div className="w-full">
              <div className={'py-2 border-t mt-2 border-gray-300 lg:mb-6'}>
                <div className={'flex py-1'}>
                  <div
                    className={
                      'border-r border-gray-200 text-gray-600 text-sm pr-6 mr-6'
                    }
                  >
                    <div className={'mb-1 font-medium'}>
                      {t('service.apartments')}
                    </div>
                    <div>
                      {data?.results?.length} {t('apartmentServices.apartments')}
                    </div>
                  </div>

                  <div
                    className={
                      'border-r border-gray-200 text-gray-600 text-sm pr-6 mr-6'
                    }
                  >
                    <div className={'mb-1 font-medium'}>
                      {t('service.sellPrice')}
                    </div>
                    <div>{service?.amount_tot} EUR</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {data && (
          <div className="flex flex-col lg:flex-row -mx-6">
            <div className="flex-1 lg:w-1/2 px-6">
              <>
                <FormSectionTitle
                  title={t('general.generalInformation')}
                  edit={true}
                  onEdit={() => {
                    setUpdateGeneral(true);
                  }}
                />

                <div className="flex mb-4 flex-wrap">
                  <div className={'w-44 xs:w-full text-gray-500'}>
                    {t('service.internalName')}
                  </div>
                  <div className={'flex-1'}>{service?.title}</div>
                </div>

                <div className="flex mb-4 flex-wrap">
                  <div className={'w-44 xs:w-full text-gray-500'}>
                    {t('service.amount')}
                  </div>
                  <div className={'flex-1'}>{service?.amount_tot} EUR</div>
                </div>

                <div className="flex mb-4">
                  <div className={'w-44 xs:w-full text-gray-500'}>
                    {t('service.categories')}
                  </div>
                  <div className={'flex-1'}>
                    {service && service.cats && (
                      <ServiceCats
                        onChange={(categories) => {
                          mutation.mutate({
                            ...service,
                            cats: JSON.stringify(categories),
                          });
                        }}
                        data={service?.cats}
                      />
                    )}
                  </div>
                </div>

                <div className="flex mb-4">
                  <div className={'w-44 xs:w-full text-gray-500'}>
                    {t('service.enabledApartments')}
                  </div>
                  <div className={'flex-1'}>
                    <div className="grid gap-1 grid-cols-4">
                      {apartments &&
                        apartments.results &&
                        apartments.results.map(
                          (item: LocalService, index: number) => (
                            <div
                              key={item.id + ' ' + index}
                              className={
                                'whitespace-nowrap overflow-ellipsis overflow-hidden bg-white border px-3 py-2 rounded-2xl text-sm'
                              }
                            >
                              {item.prop.name}
                            </div>
                          )
                        )}
                    </div>

                    <Button
                      className={'mt-2 '}
                      label={t('general.add')}
                      icon={'plus-circle'}
                      iconSize={'20px'}
                      size={'small'}
                      onClick={() => {
                        setUpdateConnectedApartments(true);
                      }}
                    />
                  </div>
                </div>

                <FormSectionTitle
                  title={t('service.purchaseRules')}
                  edit={false}
                  onEdit={() => {}}
                />

                <div>
                  <Checkbox
                    checked={!service?.DISABLED || false}
                    className={'mb-2'}
                    label={t('service.availableToSell')}
                    onChange={() => {
                      if (service) {
                        mutation.mutate({
                          ...service,
                          action: service.DISABLED ? 'ENABLE' : 'DISABLE',
                        });
                      }
                    }}
                  />
                  <Checkbox
                    hint={t('service.sellMultiText')}
                    label={t('service.sellMulti')}
                    checked={service?.type === 'MULTI'}
                    onChange={() => {
                      if (service) {
                        mutation.mutate({
                          ...service,
                          type: service.type === 'MULTI' ? 'SINGLE' : 'MULTI',
                        });
                      }
                    }}
                  />
                </div>

                <FormSectionTitle
                  title={t('service.purchaseInformation')}
                  edit={false}
                />

                <LanguageSelector
                  className={'mb-3'}
                  onChange={(lang) => setCurrentLang(lang)}
                />
                {service && currentLang && service.langs && (
                  <>
                    <div className="font-medium mb-4 mt-6">
                      {t('service.nameAndDescription')}
                    </div>

                    <div
                      className={
                        'rounded-2xl border bg-white cursor-pointer transition-all hover:text-cyan-600 px-4 py-4 flex items-center justify-between'
                      }
                      onClick={() => setUpdateServiceContent(true)}
                    >
                      <div className={'font-medium'}>
                        {service.langs[currentLang]?.title}
                      </div>
                      <div>
                        <Icon name={'pencil'} size={'20px'} />
                      </div>
                    </div>

                    <ServiceContent
                      onClose={() => {
                        setUpdateServiceContent(false);
                        refetch();
                      }}
                      onChange={(data) => {
                        if (service.langs)
                          updateMutation.mutate({
                            ...data,
                            lang: currentLang,
                            extras: data.extras ?? '[]',
                          });
                      }}
                      visible={updateServiceContent}
                      lang={{
                        ...service.langs[currentLang],
                        lang: currentLang,
                        id: service.id ?? 'new', 
                        buyable_id: service.id ?? 'new',
                      }}
                    />

                    {service.langs[currentLang]?.title && (
                      <>
                        <div className="font-medium mb-4 mt-6">
                          {t('service.purchaseQuestion')}
                        </div>
                        <ServiceQuestionItem
                          lang={service.langs[currentLang]}
                          onChange={(data) => {
                            if (service.langs)
                              updateMutation.mutate({
                                ...service.langs[currentLang],
                                lang: currentLang ?? 'it',
                                buyable_id: service.id ?? 'new',
                                extras: JSON.stringify(data),
                              });
                          }}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            </div>
            <div className="flex-1 lg:w-1/2 px-6">
              {service && (
                <>
                  <FormSectionTitle title={'Preview'} />
                  <ServicePreview data={service} />
                </>
              )}
            </div>
          </div>
        )}
      </div>

      {service && (
        <ManageApartments
          flg_bkengine={service?.flg_bkengine}
          onClose={() => {
            refetch();
            refetchApartments()
            setUpdateConnectedApartments(false);
          }}
          connectedApartments={
            apartments && apartments.results
              ? apartments.results.map((local) => {
                  return {
                    ...local,
                    mandantory: false,
                    enabled: false,
                  };
                })
              : []
          }
          service={service}
          visible={updateConnectedApartments}
        />
      )}

      {service && (
        <UpdateServiceGeneral
          visible={updateGeneral}
          data={service}
          onGet={(data: any) => handleSuccessMutation(data)}
          onSave={() => {
            setUpdateGeneral(false);
          }}
          onClose={() => setUpdateGeneral(false)}
        />
      )}
      <DeleteDialog
        // askConfirmation={true}
        isLoading={deleteMutation.isLoading}
        visible={showDelete}
        message={t('services.removeService')}
        confirmationKey={t('general.iConfirm')}
        onCancel={() => setShowDelete(false)}
        onConfirm={() => {
          deleteMutation.mutate();
        }}
      />
    </PageWrapper>
  );
}
