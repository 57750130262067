import { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";
import ModalActions from "./ModalAction";
import ModalCard from "./ModalCard";
import TextInput from "./TextInput";

export default function DeleteDialog ({
    // message = '',
    visible = false,
    askConfirmation = false,
    confirmationKey = 'confermo',
    isLoading = false,
    onConfirm,
    onCancel
} : {
    // message: string
    visible: boolean,
    isLoading?: boolean,
    askConfirmation?: boolean,
    confirmationKey?: string,
    onConfirm: () => void,
    onCancel: () => void
}) {

    const { t } = useTranslation()

    const [confirmValue, setConfirmValue] = useState<string>('')

    function handleConfirmation () {
        if (askConfirmation) {
            if (confirmValue === confirmationKey) {
                onConfirm()
                setConfirmValue('')
            }
        } else {
            onConfirm()
        }
    }

    return (
        <Modal
            visible={visible}>
            <ModalCard
                className={'w-full max-w-lg'}
                actionsLoading={isLoading}
                renderActions={() => (
                    <ModalActions
                        saveLabel={t('general.refund')}
                        onClose={onCancel}
                        onSave={handleConfirmation}></ModalActions>
                )}
                title={t('general.refund')}>

                {/* <div className="p-4 text-gray-700 ">
                    {message}
                </div> */}

                {
                    askConfirmation &&
                    <div className="text-gray-700 p-4 pt-0 border-gray-300">
                        {t('general.write1')} <span className={'font-bold'}>{confirmationKey}</span> {t('general.write2')}
                        <div className="mt-2">
                            <TextInput
                                value={confirmValue}
                                onChange={val => setConfirmValue(val)} />
                        </div>
                    </div>
                }

            </ModalCard>
        </Modal>
    )

}