import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ApartmentPermission from '../components/apartment/ApartmentPermission';
import BaseTable from '../components/BaseTable';
import Icon from '../components/Icon';
import Modal from '../components/Modal';
import ModalActions from '../components/ModalAction';
import ModalCard from '../components/ModalCard';
import ModalSection from '../components/ModalSection';
import AddSubAccount from '../components/AddSubAccount';
import { fetchAccounts, deleteSubaccount } from '../shared/queries';
import { Account, ListApiResponse } from '../types';
import { useTranslation } from 'react-i18next';
import DeleteDialog from '../components/DeleteDialog';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

export default function SubAccounts() {
  const { t } = useTranslation();

  const {
    data: accounts,
    isLoading: loadingAccounts,
    refetch: refetchAccounts,
  } = useQuery<ListApiResponse<Account>, Error>('accounts', fetchAccounts, {
    initialData: {
      msg: '',
      results: [],
    },
    onError: (error: Error) => {
      toast.error(t(error.message) || t("general.requestError"));
    },
  });

  const deleteMutation = useMutation(() => deleteSubaccount(userKeyToDelete), {
    onSuccess: () => {
      toast.success(t('general.operationCompleted'));
      setUserKeyToDelete(null);
      if (refetchAccounts) refetchAccounts();
    },
  });

  const [addAccount, setAddAccount] = useState<boolean>(false);
  const [userKey, setUserKey] = useState<string | null>(null);
  const [userKeyToDelete, setUserKeyToDelete] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState<Account[]>();

  useEffect(() => {
    if (accounts && accounts.results) {
      setPageData(
        accounts.results.slice((currentPage - 1) * 15, 15 * currentPage)
      );
    }
  }, [accounts, currentPage]);

  return (
    <div>
      <DeleteDialog
        askConfirmation={true}
        confirmationKey={t('general.iConfirm')}
        isLoading={deleteMutation.isLoading}
        visible={userKeyToDelete !== null}
        message={''}
        onCancel={() => setUserKeyToDelete(null)}
        onConfirm={() => {
          deleteMutation.mutate();
        }}
      />
      <BaseTable
        currentPage={currentPage}
        pageCount={Math.ceil(
          parseInt(
            accounts && accounts.results
              ? accounts.results.length.toString()
              : '0'
          ) / 15
        )}
        identifierKey={'user_key'}
        title={'Sub-Accounts'}
        data={pageData}
        loading={loadingAccounts}
        totalRows={accounts?.results ? accounts?.results.length : 0}
        displayRows={15}
        onFilterChange={(filters, page) => {
          setCurrentPage(page || 1);
        }}
        renderButtons={() => (
          <div
            onClick={() => setAddAccount(true)}
            className={
              'py-1 cursor-pointer flex items-center bg-white focus:outline-none bg-cyan-600 shadow border px-3 text-sm text-white font-medium border-cyan-400 rounded-2xl'
            }
          >
            <span className={'hidden md:block'}>
              {t('subAccounts.newSubAccount')}
            </span>
            <span className={'block md:hidden'}>
              <Icon name={'document-add'} size={'24px'} />
            </span>
          </div>
        )}
        filters={[]}
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Email',
            accessor: 'mail',
          },
          {
            Header: t('invoices.actions').toString(),
            accessor: (originalRow, rowIndex) => (
              <div className={'flex space-x-2'}>
                <button
                  onClick={(id) => {
                    setUserKey(originalRow.user_key);
                  }}
                  className={'focus:outline-none'}
                >
                  <div className={'text-cyan-600 flex space-x-2'}>
                    <div>
                      <Icon name={'link'} size={'20px'}></Icon>
                    </div>
                    <div>{t('subAccounts.addApartments')}</div>
                  </div>
                </button>
              </div>
            ),
          },
          {
            Header: t('general.delete').toString(),
            accessor: (originalRow, rowIndex) => (
              <div className={'flex space-x-2'}>
                <button
                  onClick={(id) => {
                    setUserKeyToDelete(originalRow.user_key);
                  }}
                  className={'focus:outline-none'}
                >
                  <div className={'text-red-600 flex space-x-2'}>
                    <div>
                      <Icon name={'trash'} size={'20px'}></Icon>
                    </div>
                    <div>{t('general.delete')}</div>
                  </div>
                </button>
              </div>
            ),
          },
        ]}
      />

      <Modal visible={userKey !== null}>
        <ModalCard
          style={{
            width: '100%',
          }}
          title={t('subAccounts.managePermission')}
        >
          <ModalSection>
            <div className={'p-8 overflow-auto'}>
              {userKey && <ApartmentPermission user_key={userKey} />}
            </div>
          </ModalSection>
          <ModalActions
            saveLabel={t('general.confirm')}
            onSave={() => {
              setUserKey(null);
            }}
            onClose={() => {
              setUserKey(null);
            }}
          />
        </ModalCard>
      </Modal>

      <AddSubAccount
        visible={addAccount}
        onClose={() => {
          setAddAccount(false);
          refetchAccounts();
        }}
      />
    </div>
  );
}
