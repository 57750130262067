import { useQuery } from "react-query";
import {
  fetchLocalServices,
} from "../../shared/queries";
import { ListApiResponse, Local, LocalService } from "../../types";
import FormSectionTitle from "../FormSectionTitle";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

type ApartmentServicesProps = {
  data: Local;
  onEnableServices?: () => void;
};

export default function ApartmentServices({
  data,
  onEnableServices,
}: ApartmentServicesProps) {
  const { t } = useTranslation();

  const { data: services } = useQuery<
    ListApiResponse<LocalService>,
    Error
  >("apartmentServices", () => fetchLocalServices(data.local_key));

  return (
    <div>
      <FormSectionTitle
        title={t("apartmentService.activeServices")}
        subtitle={t("apartmentService.activeServicesText")}
      ></FormSectionTitle>

      {services && (
        <div className="mt-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {services.results.map((service: LocalService, index: number) => (
            <div
              key={index}
              className={
                "border border-gray-300 p-10 space-y-10 bg-white rounded-2xl justify-between"
              }
            >
              <div
                style={{
                  backgroundImage: "url(" + service.buyable?.cover + ")",
                }}
                className={"w-full h-36 m-auto rounded-2xl bg-cover bg-center"}
              ></div>
              <div>
                <div className={"font-medium text-gray-700"}>
                  {service.buyable?.title}
                </div>
                <div className={"text-gray-600 text-sm"}>
                  {t("apartmentService.inSellAt")} {service.buyable?.amount_tot}{" "}
                  EUR
                </div>
                <div>
                  <span className={"text-green-600 font-medium text-sm"}>
                    {service.buyable?.type === "MULTI"
                      ? t("services.multiPurchase")
                      : t("services.singlePurchase")}
                  </span>
                </div>
              </div>

              <div className={"self-end"}>
                <Link
                  to={"/services/" + service.buyable?.id}
                  className={
                    "bg-cyan-600 p-2 text-white text-center rounded-2xl text-sm"
                  }
                >
                  {t("general.edit")}
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
