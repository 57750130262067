import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { refundReservationService, deleteReservationService, captureDepositService, deleteCaptureDepositService, fetchLocalServices, fetchReservationServices, storeService } from "../../shared/queries"
import { Buyable, ListApiResponse, ListApiResponseV3, LocalService, ReservationService, ReservationV3, SuccessApiResponse } from "../../types"
import Button from "../Button"
import FormSectionTitle from "../FormSectionTitle"
import Modal from "../Modal"
import ModalActions from "../ModalAction"
import ModalCard from "../ModalCard"
import EmptyState from "../EmptyState"
import ModalSection from "../ModalSection"
import AddService from "../service/AddService"
import ReservationServiceSkeleton from "./ReservationServiceSkeleton"
import Icon from "../Icon"
import CaptureDeposit from "../CaptureDeposit"
import RefundDialog from "../RefundDialog"

type ReservationServicesProps = {
  reservation: ReservationV3,
  openModal: boolean,
  isClosing: any,
}

export default function ReservationServices ({
  reservation,
  openModal,
  isClosing
} : ReservationServicesProps) {

  const { t } = useTranslation()
  
  const history = useHistory()
  const [service, setService] = useState<Buyable>()
  const [validation, setValidation] = useState<boolean>(false)
  const [addService, setAddService] = useState(false)
  const [captureDepositvalue, setCaptureDeposit] = useState(false)
	const [visible, setVisible] = useState(false);
  const [showRefund, setShowRefund] = useState(false);
  const [showDepositRefund, setShowDepositRefund] = useState(false);



  const {
    data,
    isLoading
  } = useQuery<ListApiResponse<LocalService>, Error>(
    ['services', reservation.local.local_key],
    () => fetchLocalServices(reservation.local.local_key)
  )

  const {
    data: reservationServices,
    isLoading: loadingReservationServices,
    refetch: refetchReservationServices
  } = useQuery<ListApiResponseV3<ReservationService>, Error>(
    ['services', reservation.resv_key],
    () => fetchReservationServices(reservation.resv_key)
  )

  const mutation = useMutation(() => storeService({
    ...service,
    resv_key: reservation.resv_key
  }), {
    onSuccess: (data) => {
      setAddService(false)
      isClosing(false)
      toast.success(t('apartmentServices.serviceCreated'))
      refetchReservationServices()
    },
    onError: () => {
      toast.error(t('general.requestError'))
    }
  })

  const deleteMutation = useMutation((id: string) => deleteReservationService(id, reservation.resv_key), {
    onSuccess: () => {
      refetchReservationServices()
      toast.success(t('general.operationCompleted'))
    },
    onError: () => {
      toast.error(t('general.requestError'))
    }
  })

  const refundMutation = useMutation((id: string) => refundReservationService(id, reservation.resv_key), {
    onSuccess: () => {
      refetchReservationServices()
      setShowDepositRefund(false)
      toast.success(t('general.operationCompleted'))
    },
    onError: () => {
      setShowDepositRefund(false)
      toast.error(t('general.requestError'))
    }
  })

  const captureDeposit = useMutation((data: {id:string, amount:number, deposit_reason?:string}) => captureDepositService(data, reservation.resv_key), {
    onSuccess: () => {
      setCaptureDeposit(false)
      refetchReservationServices()
      toast.success(t('general.operationCompleted'))
    },
    onError: () => {
      toast.error(t('general.requestError'))
    }
  })

  const deleteCaptureDeposit = useMutation((id: string) => deleteCaptureDepositService(id, reservation.resv_key), {
    onSuccess: () => {
      setShowDepositRefund(false)
      refetchReservationServices()
      toast.success(t('general.operationCompleted'))
    },
    onError: () => {
      setShowDepositRefund(false)
      toast.error(t('general.requestError'))
    }
  })


  const refundAndtake = (item:any) => {
    switch (item.status) {
      case 'ASSOCIATED':
      case 'PARTIAL':
        return (
          <div className="flex items-vert">
            <Button
              onClick={() => {
                setCaptureDeposit(true)
              }}
              flat
              size={'medium'}
              color={'primary'}
              icon={'card'}
              iconSize={'16px'} />
              {captureDepositvalue == true &&
              <CaptureDeposit
                isLoading={captureDeposit.isLoading}
                guestLang={reservation.lang}
                buyId={item.id.toString()}
                visible={captureDepositvalue}
                maxValueDefault={parseFloat(item.amount_tot)}
                onCancel={() => setCaptureDeposit(false)}
                onConfirm={(amount, depositReason) => {
                  captureDeposit.mutate({id: item.id.toString(), amount: amount, deposit_reason: depositReason});
                  setCaptureDeposit(false);
                }}
              />
              }
            <Button
              onClick={() => setShowDepositRefund(true)}
              flat
              size={'small'}
              color={'negative'}
              icon={'arrow-return-left'}
              iconSize={'16px'}
            />
            <RefundDialog
              askConfirmation={true}
              isLoading={deleteCaptureDeposit.isLoading}
              visible={showDepositRefund}
              confirmationKey={t("general.iConfirm")}
              onCancel={() => setShowDepositRefund(false)}
              onConfirm={() => {
                deleteCaptureDeposit.mutate(item.id.toString());
                setShowDepositRefund(false)
              }}/>
          </div>
        );
      case 'REFUNDED':
        return null;
      default:
        return (
          <div className="flex items-vert">
            <Button
              onClick={() => setShowRefund(true)}
              flat
              size={'small'}
              color={'negative'}
              icon={'arrow-return-left'}
              iconSize={'16px'}/>
            <RefundDialog
              askConfirmation={true}
              isLoading={refundMutation.isLoading}
              visible={showRefund}
              confirmationKey={t("general.iConfirm")}
              onCancel={() => setShowRefund(false)}
              onConfirm={() => {
                refundMutation.mutate(item.id.toString());
                setShowRefund(false)
              }}/>
          </div>
        )
  }};


  useEffect(() => {
    if(openModal){
      setAddService(true)
    }
  }, [openModal])

  return (
    <div>

      <FormSectionTitle
        borderless
        title={t('apartmentServices.servicesAdded')} />

      {
        (!loadingReservationServices && reservationServices && reservationServices.results && reservationServices.results.length === 0) &&
        <EmptyState
          subtitle={''}
          className={'border rounded-2xl mb-8 bg-white p-10'}
          action={() => setAddService(true)}
          actionLabel={t('service.addService')}
          image={'/search.svg'}
          title={t('service.noServiceAvailable')}
        />
      }

      <div className=" grid grid-cols-1 lg:grid-cols-4 gap-4 mb-8">
        {
          (reservationServices && reservationServices.results) &&
          reservationServices.results.map((item, index) => (
            <div className={'p-4 bg-white border rounded-2xl'} key={item.id}>
              <div className="flex justify-between">
                <div className={"font-medium pb-2 mb-2 border-b"}>
                  {item.title}
                  {item.title === "Deposit" && 
                   <div className={"text-xs text-gray-500"}>
                    {t('resv.expiredPayment') + ': ' + item.capture_before}
                  </div>}  
                </div>
                
                {item.mandatory ? (
                  <div className=" flex items-center space-x-0.5 mb-4">
                    <Icon
                      name={"credit-card"}
                      size={"16px"}
                      className={"text-yellow-600"}
                    />
                    <div className={"text-xs font-bold text-yellow-600 "}>
                      {t('resv.paymentMandatory')}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="flex justify-between items-center">
                <div className={'text-sm'}>
                  {item.amount_tot} EUR
                </div>
                <div className={'flex items-center'}>
                    <div>
                      { item.status === 'SPECIAL' && <div className={'text-xs rounded-2xl px-2 py-1 bg-red-600 text-white'}>{t('apartmentServices.paymentFailed')}</div> }
                      { item.status === 'ACTIVE' && <div className={'text-xs rounded-2xl px-2 py-1 bg-yellow-100'}>{t('apartmentServices.paymentDue')}</div> }
                      { item.status === 'SUBMITTED' && <div className={'text-xs rounded-2xl px-2 py-1 bg-green-600 text-white'}>{t('apartmentServices.paymentVerified')}</div> }
                      { item.status === 'ASSOCIATED' && <div className={'text-xs rounded-2xl px-2 py-1 bg-green-600 text-white'}>{t('apartmentServices.paymentAssociated')}</div> }
                      { item.status === 'CONFIRMED' && <div className={'text-xs rounded-2xl px-2 py-1 bg-green-600 text-white'}>{t('apartmentServices.paymentMade')}</div> }
                      { item.status === 'DONE' && <div className={'text-xs rounded-2xl px-2 py-1 bg-green-600 text-white'}>{t('apartmentServices.paymentMade')}</div> }
                      { item.status === 'PARTIAL' && <div className={'text-xs rounded-2xl px-2 py-1 bg-yellow-600 text-white'}>{t('apartmentServices.paymentPartial')}</div> }
                      { item.status === 'REFUNDED' && <div className={'text-xs rounded-2xl px-2 py-1 bg-yellow-600 text-white'}>{t('apartmentServices.paymentRefunded')}</div> }
                    </div>

                    {refundAndtake(item)}

                    <div>
                      <Button
                        disabled={item.status === 'DONE' || item.status === 'SUBMITTED' || item.status === 'ASSOCIATED' || item.status === 'PARTIAL' || item.status === 'REFUNDED'}
                        loading={deleteMutation.isLoading}
                        onClick={() => {
                          deleteMutation.mutate(item.id.toString())
                        }}
                        flat
                        size={'small'}
                        color={'negative'}
                        icon={'trash'}
                        iconSize={'16px'} />
                    </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>

      <FormSectionTitle
        borderless
        title={t('apartmentServices.availableServices')}></FormSectionTitle>

      {
        isLoading &&
        <div className="flex -mx-2 -my-2">
          <div className={'px-2 py-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4'}>
            <ReservationServiceSkeleton></ReservationServiceSkeleton>
          </div>
          <div className={'px-2 py-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4'}>
            <ReservationServiceSkeleton></ReservationServiceSkeleton>
          </div>
          <div className={'px-2 py-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4'}>
            <ReservationServiceSkeleton></ReservationServiceSkeleton>
          </div>
          <div className={'px-2 py-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4'}>
            <ReservationServiceSkeleton></ReservationServiceSkeleton>
          </div>
        </div>
      }
      
      {
        (!isLoading && data && data.results) &&
        <div className={'flex flex-wrap -mx-2 -my-2'}>
          {
            data.results.map((service:LocalService, index: number) => (
              <div className={'px-2 py-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4'} key={index}>
                <div className={'bg-white border rounded-2xl px-4 py-2'}>
                  <div className="font-medium">
                    <div className="flex items-center justify-between">
                      <div className={'truncate'}>
                        {service.buyable?.title}
                      </div>
                      <div
                        className={`text-xs rounded-2xl px-2 py-1 ${service.buyable?.type === 'MULTI' ? 'bg-cyan-600 text-white' : 'bg-cyan-600 text-white'}`}>
                        {service.buyable?.type}
                      </div>
                    </div>
                  </div>
                  <div className={'mt-2 border-t pt-1 flex items-center justify-between'}>
                    <div className={'text-md font-medium'}>{service.buyable?.amount_tot} EUR</div>
                    <div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      }

      <div className="mt-8">
        <Button
          onClick={() => setAddService(true)}
          label={t('apartmentServices.addService')} />
      </div>

      <Modal
          visible={addService}>
          <ModalCard
            style={{
              width: '100%',
              maxWidth: '800px'
            }}
            title={t('apartmentServices.newService')}>
            <ModalSection>
              <div className="p-6">
                <AddService
                  isVikeyPay
                  onChange={(s, valid) => {
                    setValidation(valid)
                    setService(s)
                }} />
              </div>
            </ModalSection>
            <ModalActions
              isLoading={mutation.isLoading}
              saveLabel={t('apartmentServices.createService')}
              onClose={() => {
                setAddService(false)
                isClosing(false)
                setService(undefined)
              }}
              onSave={() => {
                // store it
                if (validation) mutation.mutate()
              }}></ModalActions>
          </ModalCard>
        </Modal>

    </div>
  )

}
