import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import DateRangePicker from '../DateRangePicker'
import DatePicker from "../DatePicker"
import { fetchAllLocals, fetchAlloggiatiUser, fetchTurismoIds } from "../../shared/queries";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import SelectInput from "../SelectInput";
import { BaseSelectItem } from "../../types";
import FormField from "../FormField";

const exportOptions = [
  { label: 'Turismo 5', value: 'TURISMO5' },
  { label: 'SPOT', value: 'SPOT' },
  { label: 'RADAR', value: 'RADAR' },
  { label: 'SIRT', value: 'SIRT' },
  { label: 'SIRED', value: 'SIRED' },
  { label: 'c59', value: 'C59' },
  { label: 'Campania', value: 'CAMPANIA' },
  { label: 'STU Trento', value: 'TRENTO'},
  { label: 'WebTur', value: 'WEBTUR' },
  { label: 'Turismatica', value: 'TURISMATICA'},
  { label: 'Abit', value: 'ABIT'},
  
]

export default function ExportBuroIstat ({
  onChange
} : {
  onChange: (data: any) => void
}) {

  const {
    data: ids,
    isFetching: isFetchingIds
  } = useQuery('turismoIds', fetchTurismoIds)

  const { t } = useTranslation()



  const [fromDate, setFromDate] = useState<string>(dayjs().format('YYYY-MM-DD'))
  const [toDate, setToDate] = useState<string>(dayjs().add(1, 'day').format('YYYY-MM-DD'))
  
  const [type, setType] = useState<string>('TURISMO5')
  const [id, setId] = useState<string>('')
  const [accountKey, setAccountKey] = useState<string|undefined>()

  useEffect(() => {
    onChange({
      action: 'TURISMO5XML',
      day_from: fromDate,
      day_to: toDate,
      turismo5_id: id,
      turismo5_type: type
    })
  }, [
    type,
    fromDate,
    toDate,
    id
  ])

  return (
    <div className={'p-4'}>
      <div className="text-lg font-semibold">
        {t('export.istat')}
      </div>

      <div className="mt-4 text-gray-600 text-sm">
        {t('export.desc')}
      </div>

      <div className="mt-6 flex flex-col space-y-4">
        {['SIRT', 'C59', 'RADAR', 'CAMPANIA', 'TRENTO', 'TURISMATICA', 'WEBTUR'].includes(type) ?
        <DatePicker
        inputFormat={'YYYY-MM-DD'}
        displayFormat={'DD-MM-YYYY'}
        outputFormat={'DD-MM-YYYY'}
        value={fromDate}
        onChange={value => {
          setFromDate(dayjs(value, 'DD-MM-YYYY' ).format('YYYY-MM-DD'))}}
          />
        :
        <DateRangePicker
          inputFormat={'YYYY-MM-DD'}
          displayFormat={'DD-MM-YYYY'}
          outputFormat={'DD-MM-YYYY'}
          size={'sm'}
          value={fromDate + ' -> ' + toDate}
          onChange={value => {
            const { from, to } = value as any
            setFromDate(dayjs(from).format('YYYY-MM-DD'))
            setToDate(dayjs(to).format('YYYY-MM-DD'))
          }} />
        }

        <FormField
          label={t('export.fileType')}>
          <SelectInput<BaseSelectItem, false>
            menuPosition='fixed'

            menuShouldScrollIntoView={false}
            value={exportOptions.find(o => o.value === type)}
            onChange={item => {
              if (item) setType(item.value)
            }}
            options={exportOptions} />
        </FormField>

        <FormField
          label={t('navigation.apartments')}>
          <SelectInput<BaseSelectItem, false>
            menuPosition='fixed'
              menuShouldScrollIntoView={false}
              onChange={item => {
                if (item) setId(item.value)
              }}
              options={(ids && ids.results) ? ids.results.map(id => {
                return {
                  label: id,
                  value: id
                }
              }) : [] } />
        </FormField>
      </div>
    </div>
  )

}