import { useEffect, useRef, useState } from "react";
import Select, { Props } from "react-select";
import { BaseSelectItem } from "../types";

const SelectInput = <T extends BaseSelectItem, M extends boolean>(
  props: Props<T, M>
) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const selectRef:any = useRef();

  useEffect(() => {
    setMenuIsOpen(false);
  }, [props.onChange])

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!selectRef?.current?.contains(event.target)) {
        setMenuIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [selectRef]);
  return (
    <div onClick={() => setMenuIsOpen((prev) => !prev)} onTouchStart={() => setMenuIsOpen(true)} ref={selectRef}>
      <Select
        // menuPortalTarget={document.querySelector('body')}
        {...props}
        isDisabled={props.isDisabled}
        menuIsOpen={menuIsOpen}
        closeMenuOnScroll={false}
        closeMenuOnSelect={false}
        openMenuOnClick
        blurInputOnSelect
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#D6F1F7",
            primary: "#00BDE1",
            neutral90: "#000",
            neutral0: "#fff",
          },
        })}
        styles={{
          ...props.styles,

          control: (styles) => ({
            ...styles,
            border: 0,
            borderRadius: 18,
            boxShadow:
              "0px 2px 3px rgba(0,0,0,0.1), 0px 0px 0px 1px rgba(0,0,0,0.1)",
            padding: 2,
          }),
          option: (styles, state) => ({
            ...styles,
            borderRadius: 18,
            marginBottom: 4,
            marginTop: 4,
            // zIndex: 1,
          }),
          menu: (provided) => ({
            ...provided,
            border: 'red',
            borderRadius: 18,
            paddingLeft: 4,
            paddingRight: 4,
            zIndex: 9999,
            padding: 6,
          }),
        }}
      />
    </div>
  );
};
export default SelectInput;
