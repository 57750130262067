import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import Portal from './Portal'

type ModalProps = {
  visible: boolean,
  onClose?: Function,
  className?: string,
  children?: any,
  noBackdropDismiss?: boolean
  skipPreventDefualt?:boolean
  isResvDocs?: boolean
}

export default function Modal ({
  visible,
  onClose,
  className,
  children,
  noBackdropDismiss = false,
  skipPreventDefualt = false,
  isResvDocs = false
}: ModalProps) {

  const overlayRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (visible) {
      const scrollingContainer = document.getElementById('scrolling-container')
      if (scrollingContainer) {
        scrollingContainer.style.overflow = 'hidden'
      }
    }
    return () => {
      const scrollingContainer = document.getElementById('scrolling-container')
      if (scrollingContainer) {
        scrollingContainer.style.overflow = 'auto'
      }
    }
  }, [ visible ])

  function renderModal () {

    return (
      <motion.div
        ref={overlayRef}
        onClick={(event:any) => {
          // console.log("PREVENTING DEFAULT ")
          if(skipPreventDefualt){return}
          event.preventDefault()
          if (overlayRef &&
              overlayRef.current &&
              overlayRef.current.isEqualNode(event.target) &&
              onClose &&
              !noBackdropDismiss) {
                // console.log("not propagated")
            event.stopPropagation()
            onClose()
          }
          return false
        }}
        initial={{
          opacity: 0
        }}
        animate={{
          opacity: 1
        }}
        exit={{
          opacity: 0
        }}
        transition={{
          duration: 0.2
        }}
        className={'h-full fixed flex items-center justify-center top-0 left-0 w-full h-full ' + className}
        style={{
          background: 'rgba(0,0,0,0.3)',
          zIndex: isResvDocs ? 1050 : 'auto'
        }}>
          <motion.div
            className={'relative w-full flex justify-center'}
            initial={{
              opacity: 0,
              scale: 0.5
            }}
            animate={{
              opacity: 1,
              scale: 1
            }}
            exit={{
              opacity: 0,
              scale: 0.5
            }}
            transition={{
              duration: 0.2
            }}
            style={{
              maxHeight: '100%'
            }}>
            {children}
          </motion.div>
        </motion.div>
    )
  }

  return (
    <>
      <AnimatePresence>
        {
          visible &&
          <Portal>
            {renderModal()}
          </Portal>
        }
      </AnimatePresence>
    </>
  )

}