import React, { useState } from "react";

import CircleSpinner from "../CircleSpinner";
import PageWrapper from "../PageWrapper";
import ModalSection from "../ModalSection";
import ModalCard from "../ModalCard";
import Modal from "../Modal";

import Icon from "../Icon";

import { useTranslation } from "react-i18next";
import {
  BroadcastSelectedBooking,
  MessageUploadAttachments,
} from "../../types";
import { toast } from "react-toastify";
import { encodeFileToBase64 } from "../../shared/utils/common";
import { useDispatch, useSelector } from "react-redux";
import store from "../../store";
import { RootState } from "../../reducers";
import MessageInput from "./MessageInput";
import { ImageViewModal } from "../ImageViewModal";
import Button from "../Button";
import { sendMessage } from "../../features/messaging/messagingSlice";
import PageTitle from "../PageTitle";

// Define the types for the props
interface BroadcastMessageModalProps {
  newBroadCastMessageModalStatus: boolean;
  selectedBookingsForMessage: BroadcastSelectedBooking[];
  toggleBroadcastMessageModal: () => void;
  allowedImageMimeTypes: Array<string>;
}

const BroadcastMessageModal: React.FC<BroadcastMessageModalProps> = ({
  newBroadCastMessageModalStatus,
  selectedBookingsForMessage,
  toggleBroadcastMessageModal,
  allowedImageMimeTypes,
}) => {
  const dispatch = useDispatch();
  const { isSendingMessage } = useSelector(
    (state) => store.getState().messageStore
  );
  const { t } = useTranslation();
  const { profile } = useSelector((state: RootState) => state.login);

  const [broadcastAttachments, setBroadCastAttachments] = useState<
    MessageUploadAttachments[]
  >([]);
  const [viewImage, setViewImage] = useState<string | null>(null);
  const [newBroadcastMessage, setNewBroadCastMessage] = useState("");

  const handleThumbnailClick = (base64: string) => {
    setViewImage(base64);
  };

  const handleBroadCastFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    // console.log("files ",files)
    if (files && files.length > 0) {
      let totalSize = 0;
      const newAttachments: MessageUploadAttachments[] = [];
      for (let i = 0; i < files.length; i++) {
        totalSize += files[i].size;
      }
      if (totalSize > 1024 * 1024 * 1024) {
        toast.error(t("messaging.attachments.fileTooBig"));
        event.target.value = "";
        return;
      }
      for (let i = 0; i < files.length; i++) {
        let fileMimeType = files[i].type;

        if (!allowedImageMimeTypes.includes(fileMimeType)) {
          toast.error(t("messaging.attachments.notAnImage"));
          event.target.value = "";
          return;
        }
      }
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        try {
          const base64 = await encodeFileToBase64(file);
          const temp_file_id = file.name + "_" + file.size;
          const fileExists = broadcastAttachments.some(
            (attachment) => attachment.id === temp_file_id
          );
          if (!fileExists) {
            newAttachments.push({
              id: temp_file_id,
              base_64_string: base64,
              file_name: file.name.replace(/\.[^/.]+$/, ""),
              file_mime_type: file.type,
            });
          }
        } catch (error) {
          console.error("Error encoding file to base64:", error);
        }
      }
      const updatedAttachments = [...broadcastAttachments, ...newAttachments];
      setBroadCastAttachments(updatedAttachments);
      event.target.value = "";
    }
  };
  const handleSendBroadcastMessage = () => {
    if (
      (newBroadcastMessage.trim() && selectedBookingsForMessage.length > 0) ||
      (broadcastAttachments.length > 0 && selectedBookingsForMessage.length > 0)
    ) {
      console.log("dispatching ")
      dispatch(
        sendMessage({
          message_content: newBroadcastMessage,
          files: broadcastAttachments,
          broadcast: true,
          selectedBookingsForMessage: selectedBookingsForMessage,
        })
      );
      setNewBroadCastMessage("");
      setBroadCastAttachments([]);
      toggleBroadcastMessageModal();
    }
  };
  const handleRemoveBroadcastAttachment = (id: string) => {
    const updatedAttachments = broadcastAttachments.filter(
      (attachment) => attachment.id !== id
    );
    setBroadCastAttachments(updatedAttachments);
  };

  return (
    <Modal
      visible={newBroadCastMessageModalStatus}
      className="w-full"
      skipPreventDefualt={true}
    >
      <PageWrapper
        className="bg-white p-4 rounded-2xl w-full lg:w-2/3"
        style={{ maxHeight: "85vh"}}
      >
        <div className="flex flex-wrap items-center justify-between w-full sm:space-x-4">
            <PageTitle
              title={
                selectedBookingsForMessage.length > 1
                  ? `${t("messaging.broadcastModalTitle")} (${selectedBookingsForMessage.length})`
                  : t("messaging.newMessage")
              }
              className="w-full sm:w-auto"
            />
            <div className="w-full sm:w-auto flex justify-end mt-2 sm:mt-0">
              <Button
                onClick={toggleBroadcastMessageModal}
                className="text-white-600 bg-white hover:bg-cyan-400 focus:outline-none rounded-full p-2"
                icon="close"
              ></Button>
            </div>
          </div>


        {/* Display attachments */}
        <div className="flex flex-wrap gap-4 ml-2">
          {broadcastAttachments.map((attachment) => (
            <div
              key={attachment.id}
              className="relative w-20 h-20 border rounded overflow-hidden flex-shrink-0"
            >
              <img
                src={attachment.base_64_string}
                alt="Thumbnail"
                className="w-full h-full object-cover cursor-pointer"
                onClick={() => handleThumbnailClick(attachment.base_64_string)}
              />
              <button
                onClick={() => handleRemoveBroadcastAttachment(attachment.id)}
                className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1"
              >
                <Icon name="close" size="12px" />
              </button>
            </div>
          ))}
        </div>

        {/* Display image view modal if viewImage is set */}
        <ImageViewModal
          visible={!!viewImage}
          onClose={() => setViewImage(null)}
        >
          <img
            src={viewImage || ""}
            alt="Enlarged view"
            className="w-full h-full max-w-full max-h-full object-contain"
            style={{ objectFit: "contain", height: "85vh" }}
          />
        </ImageViewModal>

        {/* Message Input Section */}
        <div className="flex items-center pt-2 w-full max-h-full">
          <MessageInput
            value={newBroadcastMessage}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setNewBroadCastMessage(e.target.value)
            }
            onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSendBroadcastMessage();
              }
            }}
            placeholder={t("messaging.typeMessage")}
            className="flex-1 rounded-lg p-2 w-full"
            emoji_lang={profile?.lang}
            disabled={isSendingMessage}
            handleFileChange={handleBroadCastFileChange}
            file_input_id="broadcast-file-input"
            rows={10}
          />
          {!isSendingMessage ? (
            <Button
              className="bg-blue-500 text-white p-2 rounded-lg mr-2"
              label=""
              icon="send"
              disabled={
                !(
                  newBroadcastMessage.length > 0 ||
                  broadcastAttachments.length > 0
                )
              }
              onClick={handleSendBroadcastMessage}
            />
          ) : (
            <div className="p-2 mr-2">
              <CircleSpinner color="primary" />
            </div>
          )}
        </div>
      </PageWrapper>
    </Modal>
  );
};

export default BroadcastMessageModal;
