import dayjs from "dayjs";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePopper } from "react-popper";
import { TableFilterColumn } from "../types";
import Button from "./Button";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import DateRangePicker from "./DateRangePicker";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";

type TableFiltersProps = {
  columns: TableFilterColumn[];
  filtersValue?: TableFilterColumn[] | null;
  onChange?: (cols: TableFilterColumn[]) => void;
};

export default function TableFilters({
  columns,
  filtersValue,
  onChange,
}: TableFiltersProps) {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const [cols, setCols] = useState<TableFilterColumn[]>(columns);

  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const [filterCount, setFilterCount] = useState<number>(0);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom",
  });

  useEffect(() => {
    if(cols && cols?.length > 0){
      setFilterCount(cols.filter((c) => c.active === true).length);
    }
  }, [cols]);

  useEffect(() => {
    if (filtersValue) {
      setCols(filtersValue);
    }
  }, [filtersValue]);

  return (
    <div>
      <Button
        refs={setReferenceElement}
        icon="filter"
        color="info"
        size="small"
        padding="xs"
        className="flex items-center text-sm font-medium rounded-2xl"
        onClick={() => setVisible(!visible)}
      >
        <div className={"flex items-center py-1 ml-2"}>
          {t("table.filters")}
          {filterCount > 0 && (
            <div
              className={"border-l text-sm font-medium ml-2 pl-2 no-underline"}
            >
              <div
                style={{
                  height: "18px",
                  width: "18px",
                }}
                className={
                  "bg-cyan-600 text-xs flex text-white items-center justify-center rounded-full no-underline"
                }
              >
                {filterCount}
              </div>
            </div>
          )}
        </div>
      </Button>
      {visible && (
        <div
          className={"z-50"}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <motion.div
            transition={{
              duration: 0.1,
            }}
            initial={{
              scale: 0.8,
            }}
            animate={{
              scale: 1,
            }}
          >
            <div
              className={
                "m-1 w-52 bg-white border rounded-2xl shadow-lg overflow-hidden"
              }
            >
              <div
                className={
                  "flex p-2 bg-gray-50 border-b justify-between items-center"
                }
              >
                <div className={"font-medium text-sm"}>
                  {t("table.filters")}
                </div>
                <div className="flex">
                  {/* <button
                      
                      onClick={() => {
                        setVisible(false)
                        if (onChange) onChange(cols)
                      }}
                      className={'bg-red-200 text-sm flex text-red-600 items-center border focus:outline-none px-3 text-sm font-medium py-1 rounded-2xl mr-1'}>
                      {t('general.delete')}
                    </button> */}
                  <button
                    onClick={() => {
                      setVisible(false);
                      if (onChange && cols) onChange(cols);
                    }}
                    className={
                      "bg-white text-sm flex text-cyan-600 items-center border focus:outline-none px-3 text-sm font-medium py-1 rounded-2xl"
                    }
                  >
                    {t("general.done")}
                  </button>
                </div>
              </div>
              <div className="overflow-y-auto h-96">
                {cols && cols?.map((column, index) => (
                  <div key={index}>
                    <div className={"p-2 py-1 flex items-center border-b"}>
                      <Checkbox
                        size={"sm"}
                        label={column.label}
                        checked={column.active}
                        onChange={() => {
                          setCols(
                            cols.map((c: TableFilterColumn, i: number) => {
                              if (i === index) {
                                c.active = !c.active;
                                return c;
                              }
                              return c;
                            })
                          );
                        }}
                      ></Checkbox>
                    </div>
                    {column.active && column.type && (
                      <motion.div
                        initial={{
                          height: 0,
                        }}
                        animate={{
                          height: "auto",
                        }}
                        className={"bg-gray-100 p-2"}
                      >
                        {column.type === "text" && (
                          <TextInput
                            size={"sm"}
                            placeholder={t("general.filter")}
                            value={column.value ? column.value : ""}
                            onChange={(value) => {
                              setCols(
                                cols.map((c: TableFilterColumn, i: number) => {
                                  if (i === index) {
                                    c.value = value;
                                    return c;
                                  }
                                  return c;
                                })
                              );
                            }}
                          ></TextInput>
                        )}

                        {column.type === "date" && (
                          <DatePicker
                            inputFormat={"DD-MM-YYYY"}
                            displayFormat={"DD-MM-YYYY"}
                            outputFormat={"DD-MM-YYYY"}
                            size={"sm"}
                            value={column.value ? column.value : ""}
                            onChange={(value) => {
                              setCols(
                                cols.map((c: TableFilterColumn, i: number) => {
                                  if (i === index) {
                                    c.value = value;
                                    return c;
                                  }
                                  return c;
                                })
                              );
                            }}
                          ></DatePicker>
                        )}
                        
                        {column.type === "dateRange" && (
                          <DateRangePicker
                            inputFormat={"DD-MM-YYYY"}
                            displayFormat={"DD-MM-YYYY"}
                            outputFormat={"DD-MM-YYYY"}
                            size={"sm"}
                            value={column.value ? column.value : ""}
                            onChange={(value) => {
                              const { from, to } = value as any;
                                setCols(
                                  cols.map((c: TableFilterColumn, i: number) => {
                                    if (i === index) {
                                      c.value =
                                        dayjs(from).format('YYYY MM DD') +
                                        ' >>> ' +
                                        dayjs(to).format('YYYY MM DD');
                                      return c;
                                    }
                                    return c;
                                  })
                                );
                              }
                            }
                          />
                        )}

                        {column.type === "select" && (
                          <SelectInput
                            styles={{
                              container: (base) => ({ ...base, zIndex: 999 }),
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            menuShouldScrollIntoView={false}
                            menuPortalTarget={document.body}
                            options={column.options}
                            value={column.options?.find(
                              (opt) => opt.value === column.value
                            )}
                            onChange={(item: any) => {
                              if (item) {
                                setCols(
                                  cols.map(
                                    (c: TableFilterColumn, i: number) => {
                                      if (i === index) {
                                        c.value = item.value;
                                        return c;
                                      }
                                      return c;
                                    }
                                  )
                                );
                              }
                            }}
                          />
                        )}
                      </motion.div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
}
