import React, { useEffect, useState } from "react";
import FormSectionTitle from "../FormSectionTitle";
import { useTranslation } from "react-i18next";
import Checkbox from "../Checkbox";
import Button from "../Button";
import { useMutation, useQuery } from "react-query";
import {
  fetchRateSingleLocal,
  pickRatePlanBkEngine,
} from "../../shared/queries";
import { toast } from "react-toastify";
import BkService from "./components/BkService";

type Props = {
  id: string;
};

const BkRate = (props: Props) => {
  const { id } = props;
  const { t } = useTranslation();
  const [rateId, setRateId] = useState("");

  const {
    data: rateList,
    refetch: refetchRateList,
    isLoading: isLoadingRateList,
  } = useQuery<any>(
    ["channelmanagerrate", id],
    () => fetchRateSingleLocal(id as string),
    { enabled: !!id }
  );

  const pickRatePlanBkEngineMutation = useMutation(
    (body: { local_key: string; rate_plan_id: string }) =>
      pickRatePlanBkEngine(body),
    {
      onSuccess: () => {
        toast.success(t("general.fileUploaded"));
        refetchRateList();
      },
      onError: (error: Error) => {
        toast.error(t(error?.message ? error.message : "general.requestError"));
        refetchRateList();
      },
    }
  );

  useEffect(() => {
    if (rateList) {
      rateList?.results?.forEach((rate: any) => {
        if (rate.flg_bkengine) {
          setRateId(rate.id);
        }
      });
    }
  }, [rateList]);

  return (
    <>
      <FormSectionTitle
        title={t("apartmentContent.ratePlanBkEngine")}
        onEdit={() => {}}
      ></FormSectionTitle>
      <div className="flex-row">
        {rateList?.results?.map((rate: any) => (
          <Checkbox
            disabled={rateId === rate.id}
            checked={rateId === rate.id}
            onChange={() => {
              setRateId(rate.id);
            }}
            label={rate.attributes.title}
            size="md"
          />
        ))}
      </div>
      <div className="my-3">
        <Button
          label={t("general.save")}
          disabled={isLoadingRateList || pickRatePlanBkEngineMutation.isLoading}
          loading={isLoadingRateList || pickRatePlanBkEngineMutation.isLoading}
          onClick={() => {
            pickRatePlanBkEngineMutation.mutate({
              local_key: id as string,
              rate_plan_id: rateId as string,
            });
          }}
        />
      </div>
      <BkService local_key={id} />
    </>
  );
};

export default BkRate;
