import ApartmentContentCustomText from "../apartment/ApartmentContentCustomText";

type Props = {
  id: string;
  apartmentDetail: any;
};

const BkContents = (props: Props) => {
  const { id, apartmentDetail } = props;

  return (
    <div className="space-y-5 mt-7">
      {/* Description */}
      {id && (
        <ApartmentContentCustomText
          data={{
            local_key: id as string,
            user_key: apartmentDetail?.results?.user_key,
          }}
          customText={"custom_text_bkengine"}
        />
      )}
    </div>
  );
};

export default BkContents;
