import React from "react";
import { useTranslation } from "react-i18next";

interface RevenueProps {
  revenueModal: {
    y: number;
    x: number;
    rate: number;
    holidayOrEvent: number;
    dayofweek: number;
    demandScore: number;
    leadTime: number;
    orphan: number;
    seasonality: number;
    rateChannel: number;
  };
}

function RevenueTooltip({ revenueModal }: RevenueProps) {
  const { t } = useTranslation();
  if (!revenueModal.rate) {
    return <></>;
  }
  return (
    <div
      style={{
        position: "absolute",
        top: revenueModal.y,
        left: revenueModal.x - 390,
        zIndex: 10000,
      }}
    >
      <div className="bg-black text-white p-4 rounded-lg grid grid-cols-2 justify-between text-sm w-96">
        <div className="text-left">{t("channelManager.rate_plan.rate")}</div>
        <div className="text-right">
          {revenueModal.rateChannel.toFixed(2)}
        </div>
        <div className="text-left">{t("revenueManager.holidayOrEvent")}</div>
        <div
          className={`${
            revenueModal.holidayOrEvent > 0
              ? "text-green-300"
              : revenueModal.holidayOrEvent === 0
              ? "text-white"
              : "text-red-500"
          } text-right`}
        >
          {revenueModal.holidayOrEvent?.toFixed(2)}
        </div>
        <div className="text-left">{t("revenueManager.dayofweek")}</div>
        <div
          className={`${
            revenueModal.dayofweek > 0
              ? "text-green-300"
              : revenueModal.dayofweek === 0
              ? "text-white"
              : "text-red-500"
          } text-right`}
        >
          {revenueModal.dayofweek?.toFixed(2)}
        </div>
        <div className="text-left">{t("revenueManager.demandScore")}</div>
        <div
          className={`${
            revenueModal.demandScore > 0
              ? "text-green-300"
              : revenueModal.demandScore === 0
              ? "text-white"
              : "text-red-500"
          } text-right`}
        >
          {revenueModal.demandScore?.toFixed(2)}
        </div>
        <div className="text-left">Lead time</div>
        <div
          className={`${
            revenueModal.leadTime > 0
              ? "text-green-300"
              : revenueModal.leadTime === 0
              ? "text-white"
              : "text-red-500"
          } text-right`}
        >
          {revenueModal.leadTime?.toFixed(2)}
        </div>
        <div className="text-left">{t("revenueManager.orphan")}</div>
        <div
          className={`${
            revenueModal.orphan > 0
              ? "text-green-300"
              : revenueModal.orphan === 0
              ? "text-white"
              : "text-red-500"
          } text-right`}
        >
          {revenueModal.orphan?.toFixed(2)}
        </div>
        <div className="text-left">{t("revenueManager.seasonality")}</div>
        <div
          className={`${
            revenueModal.seasonality > 0
              ? "text-green-300"
              : revenueModal.seasonality === 0
              ? "text-white"
              : "text-red-500"
          } text-right`}
        >
          {revenueModal.seasonality?.toFixed(2)}
        </div>
        <div className="text-left">{t("revenueManager.minPriceCalc")} ~</div>
        <div className="text-right">{revenueModal.rate?.toFixed(2)}</div>
      </div>
    </div>
  );
}

export default RevenueTooltip;
