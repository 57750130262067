import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import CircleSpinner from "../CircleSpinner";
import Pagination from "../Pagination";
import PageWrapper from "../PageWrapper";
import ModalSection from "../ModalSection";
import ModalCard from "../ModalCard";
import Modal from "../Modal";
import MessageThread from "./MessageThread";
import ProgressBar from "../ProgressBar";
import { fetchMessageThreadDrafts } from "../../shared/queries";
import { useQuery } from "react-query";
import { BroadcastSelectedBooking } from "../../types";

// Define the types for the props
interface NewChatModalProps {
  newChatModal: boolean;
  toggleNewChatModal: () => void;
  toggleBroadcastMessageModal: () => void;
  selectedBookingsForMessage: Array<{ resv_key: string; channex_ref: string }>;
  setSelectedBookingsForMessage: React.Dispatch<
    React.SetStateAction<BroadcastSelectedBooking[]>
  >;

}

const NewChatModal: React.FC<NewChatModalProps> = ({
  newChatModal,
  toggleNewChatModal,
  toggleBroadcastMessageModal,
  selectedBookingsForMessage,
  setSelectedBookingsForMessage,

}) => {
  const { t } = useTranslation();
  const [draftSearchValue, setDraftSearchValue] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    

  const {
      data: draftMessageThreads,
      isFetching: isFetchingDraftThreads,
      refetch: refetchAllDraftThreads,
    } = useQuery(["draftThreads", page, pageSize, draftSearchValue], () =>
      fetchMessageThreadDrafts({
        page: page,
        limit: pageSize,
        query: draftSearchValue,
      })
    );
    

  return (
    <Modal visible={newChatModal} className="w-full">
      <ModalCard
        title={t("messaging.selectBooking")}
        className="w-full lg:w-2/3"
        onClose={toggleNewChatModal}
        onExtra={toggleBroadcastMessageModal}
        extraButtonLabel={
          selectedBookingsForMessage.length > 1
            ? `${t("messaging.broadcastAction")}(${selectedBookingsForMessage.length})`
            : t("messaging.createMessage")
        }
        extraButtonColor="primary"
        extraDisabled={selectedBookingsForMessage.length === 0}
      >
        <ModalSection>
          <PageWrapper>
            <div className="relative mb-2">
              <input
                type="text"
                value={draftSearchValue}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value.length > 50 || !/^[a-zA-Z0-9 ]*$/.test(value)) {
                    return;
                  }
                  setDraftSearchValue(value);
                }}
                className="w-full border rounded-lg p-2 focus:border-cyan-600 focus:outline-none"
                placeholder={t("messaging.filter.search")}
              />
              {draftSearchValue.length > 0 && (
                <button
                  type="button"
                  onClick={() => setDraftSearchValue("")}
                  className="absolute text-gray-400 right-2 top-1/2 transform -translate-y-1/2 bg-transparent border-none cursor-pointer"
                >
                  {isFetchingDraftThreads ? (
                    <CircleSpinner color={"primary"} />
                  ) : (
                    <div>X</div>
                  )}
                </button>
              )}
            </div>
            <div className="w-full">
              {isFetchingDraftThreads && <ProgressBar className="mt-2" />}
              {!isFetchingDraftThreads &&
                draftMessageThreads?.results &&
                draftMessageThreads.results.map((draftThread, index) => (
                  <MessageThread
                    key={index}
                    name={draftThread.name}
                    resv_key={draftThread.resv_key}
                    source={draftThread.source}
                    skip_has_been_read={true}
                    onSelect={() => {
                      setSelectedBookingsForMessage((prevBookings) => {
                        const exists = prevBookings.some(
                          (booking) => booking.resv_key === draftThread.resv_key
                        );
                        if (exists) {
                          return prevBookings.filter(
                            (booking) => booking.resv_key !== draftThread.resv_key
                          );
                        } else {
                          return [
                            // ...prevBookings, // TO SUPPORRT BROADCASTING UNCOMMENT THIS LINE
                            {
                              resv_key: draftThread.resv_key,
                              channex_ref: draftThread.channex_ref,
                            },
                          ];
                        }
                      });
                    }}
                    skip_dropdown={true}
                    isSelected={selectedBookingsForMessage.some(
                      (booking) => booking.resv_key === draftThread.resv_key
                    )}
                  />
                ))}
            </div>
            <div className="flex justify-end mt-4">
              <Pagination
                page={page}
                pageSize={pageSize}
                totalItems={draftMessageThreads?.tot_locals || 0}
                onPageUpdate={(value: string | number) => {
                  if (value === "+") {
                    setPage((prev) => prev + 1);
                    return;
                  }
                  if (value === "-") {
                    setPage((prev) => (prev > 1 ? prev - 1 : 1));
                    return;
                  }
                  setPage(+value);
                }}
              />
            </div>
          </PageWrapper>
        </ModalSection>
      </ModalCard>
    </Modal>
  );
};

export default NewChatModal;
